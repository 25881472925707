import { TableRow, TableCell } from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { Link } from "react-router-dom";
import { IReceivedOrder } from "../../../../models/Branch/IReceivedOrder";
// ----------------------------------------------------------------------
interface IProps {
  data: IReceivedOrder;
  index: number;
}

// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const dateTime = new Date(data.processDate);
  const datePart = dateTime.toISOString().split("T")[0];
  const timePart = dateTime.toISOString().split("T")[1].split(".")[0];
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">#{index + 1}</TableCell>
        <TableCell align="left">
          {data.arrivals?.[0].driver.nameOfDriver}
        </TableCell>
        <TableCell align="center">{datePart}</TableCell>

        <TableCell align="center">{timePart}</TableCell>
        <TableCell align="right">
          <Link
            to={`/branch-manager/receive-order/${data.arrivals?.[0].driver._id}`}
          >
            <Iconify icon="solar:alt-arrow-right-linear" />
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
