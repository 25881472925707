import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { TLoader } from "../../../../core/Loader";
import THeader from "./THeader";
import TRow from "./TRow";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import MTable from "../../../../core/MTable";
import THeaderView from "../../../../core/THeaderView";
import { AllList, MobileList } from "../data";
import { BranchService } from "../../../../services/Branch";
import { IOrderReport } from "../../../../models/Branch/IOrderReport";

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [date, setDate] = useState<string>(getTodayDate());

  // Event handler for date change
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    console.log("Date Handler", event.target.value);
  };

  const [list, setList] = useState<IOrderReport[] | undefined>(
    [] as IOrderReport[]
  );

  const getOrderList = async () => {
    setList([]);
    try {
      setState({ ...state, loader: true });
      const response = await BranchService.getDifferranceBranchManager();
      if (response.status === 200 && response.data && response.data.data) {
        setList(response.data.data);
        setState({ ...state, loader: false });
      } else {
        setList([]);
        setState({ ...state, loader: false });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    // getOrderList();
  }, [rowsPerPage, page, category, date]);

  useEffect(() => {
    // Debounce implementation
    const handler = setTimeout(() => {
      // getOrderList();
    }, 500); // 2-second delay

    return () => clearTimeout(handler); // Clear timeout on component unmount or if searchInput changes again within the delay
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
            date={date}
            onDateChange={onDateChange}
          />

          <MTable>
            <THeaderView AllList={AllList} MobileList={MobileList} />

            <TableHead>
              <TableRow>
                <TableCell size={"large" as any} padding={"normal"}>
                  <Stack direction={"row"}>{/* <b>Quantity</b> */}</Stack>
                </TableCell>
                <TableCell size={"large" as any} padding={"normal"}>
                  Qty
                </TableCell>
                {[1, 2, 3].map((data, index) => {
                  return (
                    <TableCell
                      size={"large" as any}
                      padding={"normal"}
                      sx={{ borderLeft: "1px solid #ccc" }}
                    >
                      <Stack direction={"row"} justifyContent={"space-around"}>
                        <span>Qty</span>
                        <span>Diff</span>
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} />;
              })}
            </TableBody>
          </MTable>

          {loader && <TLoader />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
