import { Avatar, Button, Paper, Stack, Typography } from "@mui/material";
import useUserStore from "../../../store/userData";
import { Link } from "react-router-dom";

const Profile = () => {
  const { data } = useUserStore((state) => ({
    data: state.data,
  }));

  return (
    <>
      <Paper elevation={2}>
        <Stack
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"100%"}
          direction={"row"}
        >
          <Stack
            display={"flex"}
            alignItems={"center"}
            width={"20%"}
            textAlign={"center"}
          >
            <Avatar sx={{ height: "100%", width: "100%" }} />
          </Stack>
          <Stack
            sx={{ width: "50%", height: "100%" }}
            direction={"row"}
            alignItems={"center"}
          >
            <div style={{ width: "50%" }}>
              <Stack
                alignItems={"center"}
                pb={1}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <Typography textAlign={"end"} fontWeight={500}>
                  Name :
                </Typography>
                <Typography sx={{ width: "50%" }} fontWeight={600}>
                  {data?.name}
                </Typography>
              </Stack>
              <Stack
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
                pb={1}
              >
                <Typography textAlign={"end"} fontWeight={500}>
                  Role :
                </Typography>
                <Typography sx={{ width: "50%" }} fontWeight={600}>
                  {data?.role}
                </Typography>
              </Stack>

              {data?.shop?.name && (
                <>
                  <Stack
                    justifyContent={"space-between"}
                    direction={"row"}
                    alignItems={"center"}
                    pb={1}
                  >
                    <Typography textAlign={"end"} fontWeight={500}>
                      Branch :
                    </Typography>
                    <Typography sx={{ width: "50%" }} fontWeight={600}>
                      {data?.shop?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    justifyContent={"space-between"}
                    direction={"row"}
                    alignItems={"center"}
                    pb={1}
                  >
                    <Typography textAlign={"end"} fontWeight={500}>
                      Area :
                    </Typography>
                    <Typography sx={{ width: "50%" }} fontWeight={600}>
                      {data?.shop?.area}
                    </Typography>
                  </Stack>
                </>
              )}
              <br />
              <Link to={"/change-password"}>
                <Button variant="outlined">Change Password</Button>
              </Link>
            </div>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
export default Profile;
