import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
// import { SellerDetailEntity } from "../../../../../models/IItemList";
import { SellerDetailEntity } from "../../../../models/IItemList";
// import OrderList from "./Orders/OrderList";
import { IState } from "../../../../models/IState";
import OrderList from "./Orders/OrderList";

interface IProps {
  data: SellerDetailEntity[];
  state: IState;
  setState: (state: IState) => void;
  productId: string;
}

const ShopTable = ({ data, state, setState, productId }: IProps) => {
  // Starting with 2 items as the initial visible count
  const [visibleItems, setVisibleItems] = useState(2);

  const flatData = useMemo(() => {
    return data.flatMap((shop) =>
      shop.weightDetails.map((detail) => ({
        sellerShopNumber: shop.sellerShopNumber,
        quantityPurchased: detail.quantityPurchased,
        PCSPurchased: detail.PCSPurchased,
      }))
    );
  }, [data]);

  const handleViewMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2);
  };

  const handleViewLess = () => {
    setVisibleItems((prevVisibleItems) => Math.max(prevVisibleItems - 2, 2));
  };

  console.log("Line  No 42", data);

  return (
    <>
      <Typography fontWeight={600} gutterBottom color={"primary"}>
        Shop Order
      </Typography>
      <TableContainer sx={{ py: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shop No</TableCell>
              <TableCell align="center">PCS</TableCell>
              <TableCell align="center">QTY</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data, index) => {
              return (
                <OrderList
                  data={data}
                  key={index}
                  state={state}
                  setState={setState}
                  productId={productId}
                />
              );
            })}
          </TableBody>
        </Table>
        {/* <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {visibleItems > 2 && (
            <Button onClick={handleViewLess}>View Less</Button>
          )}
          {visibleItems < flatData.length && (
            <Button onClick={handleViewMore}>View More</Button>
          )}
        </div> */}
      </TableContainer>
    </>
  );
};

export default ShopTable;
