import { Add, List } from "./core";
import { MainHead } from "../../../core/bodyHead";
import { useState } from "react";
import { Stack } from "@mui/material";
import H1 from "../../../core/H1";

function MajDuri() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <Stack pt={2} pb={3}>
        <H1 title="Majduri Payments History" />
      </Stack>

      <List />
      {/* <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add New Driver"
        subTitle="Add An New Driver"
      /> */}
    </>
  );
}

export default MajDuri;
