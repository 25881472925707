import {
  Container,
  Typography,
  Paper,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import ModelOpen from "../../../../../core/Model copy";
import { useState } from "react";
import { IPType } from "../../../../../models/IPType";
import { PurchaseServie } from "../../../../../services/PurchaseServices";
import { IState } from "../../../../../models/IState";

interface IData {
  orderId: string;
  shopNumber: string;
}

interface IPaymentModel {
  open: boolean;
  activeHandler(data: boolean): void;
  onModelHandler(): void;
  handleClose: () => void;
  children?: React.ReactNode;
  width?: string;
  title?: string;
  subTitle?: string;
  data: IData;
}

const PaymentModel = ({
  open,
  handleClose,
  onModelHandler,
  title,
  subTitle,
  data,
}: IPaymentModel) => {
  const [pType, setPType] = useState<IPType>("NEFT");
  const list: IPType[] = ["NEFT", "Cash"];
  const [valid, setValid] = useState({
    isValid: false,
    message: "",
  });
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const handleChange = () => {};

  const updatePayment = (event: SelectChangeEvent) => {
    if (event.target.value == null) {
      setValid({
        isValid: true,
        message: "Select an Payment Type",
      });
    } else {
      setPType(event.target.value === "NEFT" ? "NEFT" : "Cash");
      setValid({
        isValid: false,
        message: "select an Payment Type",
      });
    }
  };

  const createPaymentHandler = async () => {
    try {
      const response = await PurchaseServie.createPaymentRecord(
        data.orderId,
        data.shopNumber,
        pType
      );
      if (response.status === 200 && response.data && response.data.data) {
        setState({ ...state, loader: false });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={onModelHandler}
      title={title}
      subTitle={subTitle}
    >
      <Container maxWidth="xs" component={Paper} elevation={2}>
        <Typography variant="h4" textAlign={"start"} gutterBottom>
          {title}
        </Typography>
        <Typography textAlign={"start"} gutterBottom>
          {subTitle}
        </Typography>
        <Box py={2}>
          <FormControl fullWidth>
            <Select value={pType} onChange={handleChange}>
              {list.map((data, index) => {
                return (
                  <MenuItem value={data} key={index}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize={"12px"}
                p={0}
                m={0}
                color="error"
              >
                {valid.message}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Box>
        <Button variant="contained" onClick={createPaymentHandler} fullWidth>
          Payment Now
        </Button>
      </Container>
    </ModelOpen>
  );
};

export default PaymentModel;
