// store/supplierStore.ts
import create from "zustand";
import { SupplierServices } from "../services/SupplierServices";
import { ISupplier } from "../models/Suppliers/ISupplier";

type SupplierState = {
  list: ISupplier[];

  loader: boolean;
  getAllProductList: () => Promise<void>;
};

export const useSupplierStore = create<SupplierState>((set) => ({
  list: [],

  loader: false,
  getAllProductList: async () => {
    try {
      set({ loader: true });
      const response = await SupplierServices.getAllSupplierSmallApi();
      if (response.status === 200) {
        // Correctly set the list with the response data
        set({ list: response.data.data });
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      set({ loader: false });
    }
  },
}));
