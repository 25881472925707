import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import Tosted from "../../../../../core/Tosted";

import Iconify from "../../../../../core/Iconify";
import { IAddOrder, WeightDetailEntity } from "../../../../../models/IAddOrder";
import { PurchaseServie } from "../../../../../services/PurchaseServices";
import { IItemList, SellerDetailEntity } from "../../../../../models/IItemList";
import { SupplierServices } from "../../../../../services/SupplierServices";
import {
  IAddSupplier,
  TelephoneNumbersEntityOrMobileNumbersEntity,
} from "../../../../../models/Suppliers/IAddSupplier";
import useIsSuppleirAddedStore from "../../../../../store/isDriverAdd copy";
import { useSupplierStore } from "../../../../../store/useSupplierStore";

interface IProps {
  handleClose: () => void;
  states: IState;
  setStates: (states: IState) => void;
}

type IShopField = "shopName" | "shopNo";

interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
}

interface IMobDetail {
  name: string;
  number: number;
  nameValid: {
    message: string;
    isValid: boolean;
  };
  numberValid: {
    message: string;
    isValid: boolean;
  };
}

const Form = ({ handleClose, states, setStates }: IProps) => {
  const [shopNo, setShopNo] = useState<string>("");
  const [shopName, setShopName] = useState<string>("");

  const { list, getAllProductList } = useSupplierStore();

  const { setActive } = useIsSuppleirAddedStore((state) => ({
    setActive: state.setActive,
  }));

  const [address, setAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "Udaipur",
    state: "Rajasthan",
    pinCode: "313001",
    country: "India",
    addressLine1Valid: {
      message: "",
      isValid: false,
    },
    cityValid: {
      message: "",
      isValid: false,
    },
    stateValid: {
      message: "",
      isValid: false,
    },
    pinCodeValid: {
      message: "",
      isValid: false,
    },
    countryValid: {
      message: "",
      isValid: false,
    },
  });

  const [shopNoValid, setShopNoValid] = useState({
    isValid: false,
    message: "Fill Shop No",
  });

  const [shopNameValid, setShopNameValid] = useState({
    isValid: false,
    message: "Fill Shop No",
  });

  const [mobilePairs, setMobilePairs] = useState<IMobDetail[]>([
    {
      name: "",
      number: 0,
      nameValid: {
        message: "",
        isValid: false,
      },
      numberValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const [telPairs, setTelPairs] = useState<IMobDetail[]>([
    {
      name: "",
      number: 0,
      nameValid: {
        message: "",
        isValid: false,
      },
      numberValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const inputMobTelHandler = (
    index: number,
    key: "mob" | "tel",
    field: keyof IMobDetail,
    value: string
  ) => {
    const pairs = key === "mob" ? mobilePairs : telPairs; // Determine which pair array to update based on key
    const updatedPairs = [...pairs]; // Clone the current pair array to avoid direct state mutation

    const updatedPair = { ...updatedPairs[index] }; // Clone the specific pair to update

    if (field === "name") {
      updatedPair[field] = value; // Update the name directly
      updatedPair.nameValid = {
        // Update the validation status for the name
        ...updatedPair.nameValid,
        isValid: value !== "", // Name is valid if it's not an empty string
        message: value !== "" ? "" : "Field is required", // Set the appropriate message
      };
    } else if (field === "number") {
      updatedPair[field] = +value; // Keep it as string to validate length
      const isValidNumber = /^\d{10}$/.test(value); // Regex to check if the value is exactly 10 digits
      updatedPair.numberValid = {
        // Update the validation status for the number
        ...updatedPair.numberValid,
        isValid: !isValidNumber, // Number is valid if it matches the regex
        message: !isValidNumber ? "" : "Number must be 10 digits", // Set the appropriate message
      };
    }

    updatedPairs[index] = updatedPair; // Update the pair in the cloned array

    // Update the state with the modified pairs array
    if (key === "mob") {
      setMobilePairs(updatedPairs);
    } else {
      setTelPairs(updatedPairs);
    }
  };

  const addressInputHandler = (field: keyof IAddress, value: string) => {
    if (field === "addressLine1") {
      setAddress({
        ...address,
        addressLine1: value,
        addressLine1Valid:
          value.length > 0
            ? { isValid: false, message: "Field is Required" }
            : { isValid: true, message: "Field is Required" },
      });
    } else if (field === "addressLine2") {
      setAddress({
        ...address,
        addressLine2: value,
      });
    } else if (field === "city") {
      setAddress({
        ...address,
        city: value,
        cityValid:
          value.length > 0
            ? { isValid: false, message: "Field is Required" }
            : { isValid: true, message: "Field is Required" },
      });
    } else if (field === "country") {
      setAddress({
        ...address,
        country: value,
        countryValid:
          value.length > 0
            ? { isValid: false, message: "Field is Required" }
            : { isValid: true, message: "Field is Required" },
      });
    } else if (field === "pinCode") {
      setAddress({
        ...address,
        pinCode: value,
        pinCodeValid:
          value.length > 0
            ? { isValid: false, message: "Field is Required" }
            : { isValid: true, message: "Field is Required" },
      });
    } else if (field === "state") {
      setAddress({
        ...address,
        state: value,
        stateValid:
          value.length > 0
            ? { isValid: false, message: "Field is Required" }
            : { isValid: true, message: "Field is Required" },
      });
    }
  };

  const addNewPair = (isMobile: boolean) => {
    if (isMobile) {
      setMobilePairs([
        ...mobilePairs,
        {
          name: "",
          number: 0,
          nameValid: {
            message: "",
            isValid: false,
          },
          numberValid: {
            message: "",
            isValid: false,
          },
        },
      ]);
    } else {
      setTelPairs([
        ...telPairs,
        {
          name: "",
          number: 0,
          nameValid: {
            message: "",
            isValid: false,
          },
          numberValid: {
            message: "",
            isValid: false,
          },
        },
      ]);
    }
  };

  const removePair = (index: number, isMobile: boolean) => {
    if (isMobile) {
      const newPairs = [...mobilePairs];
      newPairs.splice(index, 1);
      setMobilePairs(newPairs);
    } else {
      const newPairs = [...telPairs];
      newPairs.splice(index, 1);
      setTelPairs(newPairs);
    }
  };

  const shopInputHandler = (field: IShopField, value: string) => {
    if (field == "shopName") {
      setShopName(value);
      setShopNameValid({
        isValid: value.length > 0 ? false : true,
        message: "Please Fill An Shop Name",
      });
    } else {
      setShopNo(value);
      setShopNoValid({
        isValid: value.length > 0 ? false : true,
        message: "Please Fill an Shop No",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isShopNameValid = shopName.trim() !== "";

    if (!isShopNameValid) {
      setShopNameValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    const isShopNoValid = shopNo.trim() !== "";
    if (!isShopNoValid) {
      setShopNoValid({
        isValid: true,
        message: "Field is Required.",
      });
    }

    const isAddressLine1Valid = address.addressLine1.trim() !== "";
    if (!isAddressLine1Valid) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        addressLine1Valid: {
          isValid: true,
          message: "This Field Is Required.",
        },
      }));
    }

    const isCityValid = address.city.trim() !== "";
    if (!isCityValid) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        cityValid: {
          isValid: true,
          message: "This Field Is Required.",
        },
      }));
    }

    const isStateValid = address.state.trim() !== "";
    if (!isStateValid) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        stateValid: {
          isValid: true,
          message: "This Field Is Required.",
        },
      }));
    }

    const isPinCodeValid = address.pinCode.trim() !== "";
    if (!isPinCodeValid) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        pinCodeValid: {
          isValid: true,
          message: "This Field Is Required.",
        },
      }));
    }

    const isCountryValid = address.country.trim() !== "";
    if (!isCountryValid) {
      setAddress((prevAddress) => ({
        ...prevAddress,
        countryValid: {
          isValid: true,
          message: "This Field Is Required.",
        },
      }));
    }

    let allMbobilePairsValid = true;
    const updateMobilePairs = mobilePairs.map((pair) => {
      const Name = pair.name.length > 0;
      const Number = pair.number > 0;
      if (!Name || !Number) {
        allMbobilePairsValid = false;
      }
      return {
        ...pair,
        nameValid: {
          ...pair.nameValid,
          isValid: !Name,
          message: Name ? "" : "Field is Required",
        },
        numberValid: {
          ...pair.numberValid,
          isValid: !Number,
          message: Number ? "" : "Field is Required",
        },
      };
    });

    // let allTelephonePairsValid = true;
    // const updateTelephonePairs = telPairs.map((pair) => {
    //   const Name = pair.name.length > 0;
    //   const Number = pair.number > 0;
    //   if (!Name || !Number) {
    //     allMbobilePairsValid = false;
    //   }
    //   return {
    //     ...pair,
    //     nameValid: {
    //       ...pair.nameValid,
    //       isValid: !Name,
    //       message: Name ? "" : "Field is Required",
    //     },
    //     numberValid: {
    //       ...pair.numberValid,
    //       isValid: !Number,
    //       message: Number ? "" : "Field is Required",
    //     },
    //   };
    // });

    setMobilePairs(updateMobilePairs);
    // setTelPairs(updateTelephonePairs);

    if (!allMbobilePairsValid) {
      return; // Prevent form submission if any pair is invalid
    }
    addNewSupplierHandler();
  };

  const addNewSupplierHandler = async () => {
    setStates({ ...states, loader: true });

    const mobileNumbers: TelephoneNumbersEntityOrMobileNumbersEntity[] =
      mobilePairs.map((item) => ({
        name: item.name,
        number: item.number.toString(),
      }));

    const telephoneNumbers: TelephoneNumbersEntityOrMobileNumbersEntity[] =
      telPairs.map((item) => ({
        name: item.name,
        number: item.number.toString(),
      }));

    const data: IAddSupplier = {
      shopName: shopName,
      shopNumber: shopNo,
      telephoneNumbers: telephoneNumbers,
      mobileNumbers: mobileNumbers,
      shopAddress: {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        pinCode: address.pinCode,
        country: address.country,
      },
    };

    try {
      const response = await SupplierServices.addNewSupplierApi(data);
      if (response.status === 201 && response.data && response.data.data) {
        setStates({
          loader: false,
          tosted: true,
          message: "Sucessfully Added",
          severity: "success",
        });
        getAllProductList();
        setActive(true);
      } else {
        setStates({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setStates({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const { loader, tosted, message, severity } = states;

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction={"row"} alignItems={"center"} pb={2} spacing={1.5}>
          <OutlineInput
            value={shopNo}
            type="text"
            label="Shop No"
            handleInputChange={(e) =>
              shopInputHandler("shopNo", e.target.value)
            }
            error={shopNoValid.isValid ? shopNoValid.message : undefined}
            disabled={states.loader}
          />
          <OutlineInput
            value={shopName}
            type="text"
            label="Shop Name"
            handleInputChange={(e) =>
              shopInputHandler("shopName", e.target.value)
            }
            error={shopNameValid.isValid ? shopNameValid.message : undefined}
            disabled={states.loader}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={"500"} gutterBottom fontSize={"14px"}>
            Address Fill
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
          gap={2}
        >
          <OutlineInput
            value={address.addressLine1}
            type="text"
            label="addressLine1"
            handleInputChange={(e) =>
              addressInputHandler("addressLine1", e.target.value)
            }
            error={
              address.addressLine1Valid.isValid
                ? address.addressLine1Valid.message
                : undefined
            }
            disabled={states.loader}
          />

          <OutlineInput
            value={address.addressLine2}
            type="text"
            label="addressLine2"
            handleInputChange={(e) =>
              addressInputHandler("addressLine2", e.target.value)
            }
            error={undefined}
            disabled={states.loader}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
          gap={2}
        >
          <OutlineInput
            value={address.city}
            type="text"
            label="City"
            handleInputChange={(e) =>
              addressInputHandler("city", e.target.value)
            }
            error={
              address.cityValid.isValid ? address.cityValid.message : undefined
            }
            disabled={states.loader}
          />

          <OutlineInput
            value={address.state}
            type="text"
            label="state"
            handleInputChange={(e) =>
              addressInputHandler("state", e.target.value)
            }
            error={
              address.stateValid.isValid
                ? address.stateValid.message
                : undefined
            }
            disabled={states.loader}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
          gap={2}
        >
          <OutlineInput
            value={address.pinCode}
            type="text"
            label="Pin Code "
            handleInputChange={(e) =>
              addressInputHandler("pinCode", e.target.value)
            }
            error={
              address.pinCodeValid.isValid
                ? address.pinCodeValid.message
                : undefined
            }
            disabled={states.loader}
          />

          <OutlineInput
            value={address.country}
            type="text"
            label="country"
            handleInputChange={(e) =>
              addressInputHandler("country", e.target.value)
            }
            error={
              address.countryValid.isValid
                ? address.countryValid.message
                : undefined
            }
            disabled={states.loader}
          />
        </Stack>

        {/* Mobile Detail */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={"500"} gutterBottom fontSize={"14px"}>
            Mobile Detail
          </Typography>
          <IconButton
            color="primary"
            size="large"
            onClick={() => addNewPair(true)}
          >
            <Iconify icon="fluent:add-12-filled" />
          </IconButton>
        </Stack>
        {mobilePairs.map((pair, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing={1.5}
            pb={2}
          >
            <OutlineInput
              value={pair.name}
              type="text"
              label="Name"
              handleInputChange={(e) =>
                inputMobTelHandler(index, "mob", "name", e.target.value)
              }
              error={
                pair.nameValid.isValid ? pair.nameValid.message : undefined
              }
              disabled={false} // Or use your logic to disable input
            />
            <OutlineInput
              value={pair.number}
              type="number"
              label="Mobile"
              handleInputChange={(e) =>
                inputMobTelHandler(index, "mob", "number", e.target.value)
              }
              error={
                pair.numberValid.isValid ? pair.numberValid.message : undefined
              }
              disabled={false} // Or use your logic to disable input
            />

            <IconButton
              onClick={() => removePair(index, true)}
              size="large"
              color="error"
            >
              <Iconify icon="fluent:delete-12-regular" />
            </IconButton>
          </Stack>
        ))}
        {/* End of Code  */}

        {/* Telephone Detail */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={"500"} gutterBottom fontSize={"14px"}>
            Telephone Detail
          </Typography>
          <IconButton
            color="primary"
            size="large"
            onClick={() => addNewPair(false)}
          >
            <Iconify icon="fluent:add-12-filled" />
          </IconButton>
        </Stack>
        {telPairs.map((pair, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing={1.5}
            pb={2}
          >
            <OutlineInput
              value={pair.name}
              type="text"
              label="Name"
              handleInputChange={(e) =>
                inputMobTelHandler(index, "tel", "name", e.target.value)
              }
              error={
                pair.nameValid.isValid ? pair.nameValid.message : undefined
              }
              disabled={false} // Or use your logic to disable input
            />
            <OutlineInput
              value={pair.number}
              type="number"
              label="Telephone No"
              handleInputChange={(e) =>
                inputMobTelHandler(index, "tel", "number", e.target.value)
              }
              error={
                pair.numberValid.isValid ? pair.numberValid.message : undefined
              }
              disabled={false} // Or use your logic to disable input
            />

            <IconButton
              onClick={() => removePair(index, false)}
              size="large"
              color="error"
            >
              <Iconify icon="fluent:delete-12-regular" />
            </IconButton>
          </Stack>
        ))}
        {/* End of Code  */}

        <Box textAlign={"center"} pt={1}>
          <Contained
            type="submit"
            variant="text"
            disabled={loader}
            loader={loader}
          >
            Add Now
          </Contained>
        </Box>
      </form>
      {/* <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      /> */}
    </>
  );
};
export default Form;
