import { Avatar, Box } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";

import { ContainerService } from "../../../../../services/ContainerServices";
import { IAddContainer } from "../../../../../models/IContainer";
import { useContainerStore } from "../../../../../store/useContainerStore";
import useIsContainerAddedStore from "../../../../../store/isContainerAdded";

interface IProps {
  handleClose: () => void;
  states: IState;
  setStates: (states: IState) => void;
}

type IShopField = "shopName" | "shopNo";

interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
}

interface IMobDetail {
  name: string;
  number: number;
  nameValid: {
    message: string;
    isValid: boolean;
  };
  numberValid: {
    message: string;
    isValid: boolean;
  };
}

const Form = ({ handleClose, states, setStates }: IProps) => {
  const [shopNo, setShopNo] = useState<string>("");
  const [shopName, setShopName] = useState<string>("");

  const { list, getAllContainerList } = useContainerStore();

  const { setActive } = useIsContainerAddedStore((state) => ({
    setActive: state.setActive,
  }));

  const [shopNoValid, setShopNoValid] = useState({
    isValid: false,
    message: "Fill Shop No",
  });

  const [shopNameValid, setShopNameValid] = useState({
    isValid: false,
    message: "Fill Shop No",
  });

  const shopInputHandler = (field: IShopField, value: string) => {
    if (field == "shopName") {
      setShopName(value);
      setShopNameValid({
        isValid: value.length > 0 ? false : true,
        message: "Please Fill An Shop Name",
      });
    } else {
      setShopNo(value);
      setShopNoValid({
        isValid: value.length > 0 ? false : true,
        message: "Please Fill an Shop No",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isShopNameValid = shopName.trim() !== "";

    if (!isShopNameValid) {
      setShopNameValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    const isShopNoValid = shopNo.trim() !== "";
    if (!isShopNoValid) {
      setShopNoValid({
        isValid: true,
        message: "Field is Required.",
      });
    }

    if (!isShopNoValid && !isShopNameValid) {
      return; // Prevent form submission if any pair is invalid
    }
    addNewSupplierHandler();
  };

  const addNewSupplierHandler = async () => {
    setStates({ ...states, loader: true });

    const data: IAddContainer = {
      containerName: shopNo,
      containerWeight: +shopName,
    };

    try {
      const response = await ContainerService.addNewContainer(data);
      if (response.status === 201 && response.data) {
        setStates({
          loader: false,
          tosted: true,
          message: "Sucessfully Added",
          severity: "success",
        });
        getAllContainerList();
        setActive(true);
      } else {
        setStates({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setStates({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const { loader, tosted, message, severity } = states;

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <OutlineInput
          value={shopNo}
          type="text"
          label="container Name"
          handleInputChange={(e) => shopInputHandler("shopNo", e.target.value)}
          error={shopNoValid.isValid ? shopNoValid.message : undefined}
          disabled={states.loader}
        />
        <OutlineInput
          value={shopName}
          type="number"
          label="Container Weight"
          handleInputChange={(e) =>
            shopInputHandler("shopName", e.target.value)
          }
          error={shopNameValid.isValid ? shopNameValid.message : undefined}
          disabled={states.loader}
        />

        <Box textAlign={"center"} pt={1}>
          <Contained
            type="submit"
            variant="text"
            disabled={loader}
            loader={loader}
          >
            Add Now
          </Contained>
        </Box>
      </form>
      {/* <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      /> */}
    </>
  );
};
export default Form;
