import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { ITHead } from "../../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Payment Date",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Bill",
    isMove: false,
    align: "center",
  },

  {
    id: 2,
    name: "Payment ",
    isMove: false,
    align: "center",
  },
  {
    id: 2,
    name: "Outstanding ",
    isMove: false,
    align: "center",
  },
  {
    id: 2,
    name: "Advance ",
    isMove: false,
    align: "center",
  },
  {
    id: 2,
    name: "Status ",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const TList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "PCS",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "UOM",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Rate",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Type",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "(%)",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "Total",
    isMove: true,
    align: "right",
  },
  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Payment Date",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Bill",
    isMove: false,
    align: "center",
  },

  {
    id: 2,
    name: "Payment ",
    isMove: false,
    align: "center",
  },
  {
    id: 2,
    name: "(Advance/Outstanding) ",
    isMove: false,
    align: "center",
  },
  {
    id: 2,
    name: "Status ",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const users = [...Array(24)].map((_, index) => ({
  id: faker.string.uuid(),
  avatarUrl: ``,
  name: faker.person.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(["active", "banned"]),
  role: sample([
    "Leader",
    "Hr Manager",
    "UI Designer",
    "UX Designer",
    "UI/UX Designer",
    "Project Manager",
    "Backend Developer",
    "Full Stack Designer",
    "Front End Developer",
    "Full Stack Developer",
  ]),
}));

export const shoplist = [
  {
    shopname: 1,
    quantity: "10kg",
  },
  {
    shopname: 5,
    quantity: "30kg",
  },
  {
    shopname: 16,
    quantity: "30kg",
  },
];
