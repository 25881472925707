import { useEffect, useState } from "react";

import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TablePagination,
} from "@mui/material";
import THeader from "../../../core/THeader";
import MTable from "../../../core/MTable";
import { IState } from "../../../models/IState";
import Tosted from "../../../core/Tosted";

import NoData from "../../../core/NoData";
import { itemList } from "../data";
import TItem from "./TItem";
import THItem from "./THItem";
import { IShopOrder } from "../../../models/IShopOrder";
import { useParams } from "react-router-dom";
import { PurchaseServie } from "../../../services/PurchaseServices";
import { getTodayDate } from "../../../hooks/getTodayDate";
import { IBuyList } from "../../../models/IOrderList";
import { IPROrder } from "../../../models/IPROrder";
import { ProcurementServices } from "../../../services/ProcurementServies";

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const { shopNo, date } = useParams();
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IPROrder[] | undefined>(undefined);

  const getAllList = async () => {
    try {
      const response = await ProcurementServices.getDetailsByShopNumber(
        shopNo ? shopNo : "1",
        date ? date : getTodayDate()
      );
      if (
        response.data.status === 200 &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setList(response.data.data);
        // setActive(false);
        // setSellerTotalAmount(response.data.data.sellerTotalAmount);
        setState({ ...state, loader: false });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
        setList([]);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
      setList([]);
    }
  };

  useEffect(() => {
    getAllList();
  }, [shopNo, date]);

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    // getAllProductList();
  }, [rowsPerPage, page, category]);

  useEffect(() => {
    // Debounce implementation
    const handler = setTimeout(() => {
      // getAllProductList();
    }, 500); // 2-second delay

    return () => clearTimeout(handler); // Clear timeout on component unmount or if searchInput changes again within the delay
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
          />
          <MTable>
            <THItem />

            <TableBody>
              {/* {procurementList.map((data, index) => {
                return <TRow data={data} index={index} key={index} />;
              })} */}
              {list &&
                list.map((data, index) => {
                  return <TItem data={data} index={index} key={index} />;
                })}
            </TableBody>
          </MTable>

          {!loader && list?.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
