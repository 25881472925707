import { useEffect, useState } from "react";
import { Paper, TableBody } from "@mui/material";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { TLoader } from "../../../../core/Loader";
import THeader from "./THeader";
import TRow from "./TRow";
import { DriverServices } from "../../../../services/Driver";
import { IDriver } from "../../../../models/Driver/IDriver";
import THeaderView from "../../../../core/THeaderView";
import { AllList, MobileList } from "../data";
import MTable from "../../../../core/MTable";

const List = () => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IDriver[] | undefined>([] as IDriver[]);

  const getSupplierList = async () => {
    setList([]);
    try {
      setState({ ...state, loader: true });
      const response = await DriverServices.getDriverDetails();
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        console.log("Line 32", response.data.data);
        setList(response.data.data);
        setState({ ...state, loader: false });
      } else {
        setState({ ...state, loader: false });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };

  useEffect(() => {
    getSupplierList();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {}, 500);
    return () => clearTimeout(handler);
  }, [searchInput]);

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
          />

          <MTable>
            <THeaderView AllList={AllList} MobileList={MobileList} />

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} />;
              })}
            </TableBody>
          </MTable>

          {loader && <TLoader />}
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
