import { TableRow, TableCell, IconButton, Button } from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { ICtrackView } from "../../../../models/ICtrackView";
import { useEffect, useState } from "react";
import QuantityEnter from "../../../../core/QuantityEnter";
import CircularLoader from "../../../../core/CircularLoader";
import { IState } from "../../../../models/IState";
import { ContainerService } from "../../../../services/ContainerServices";
import { IReturnCreate } from "../../../../models/IReturnCreate";
import Tosted from "../../../../core/Tosted";

// ----------------------------------------------------------------------
interface IProps {
  data: ICtrackView;
  index: number;
}
// ----------------------------------------------------------------------

const TRow = ({ data, index }: IProps) => {
  const [quantity, setQuantity] = useState(0);
  const [quan, setQuan] = useState(data.totalReturned);
  // const [return,setReturn]=useState(data.totalReturned)
  // const [return , setReturn ]=useState(data.totalReturned);
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };

  const InputHandler = (value: string) => {
    setQuantity(+value);
  };

  const updateRate = async () => {
    setState({ ...state, loader: true });

    const jsonData: IReturnCreate = {
      supplierShopNumberId: data.supplierShopNumberId,
      containerId: data.containerId._id,
      quantity: quantity,
    };

    try {
      const response = await ContainerService.returnCrateApi(jsonData);
      if (response.status === 200) {
        // activeHandler(true);
        // setDisable(true);
        setState({
          loader: false,
          message: response.data.message,
          severity: "success",
          tosted: true,
        });
        // setReturn((prevQuantity) => prevQuantity + quantity);
        setQuan((pre) => pre + quantity);
        setQuantity(0);
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({ ...state, tosted: false });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [state.tosted]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="center" sx={{ pl: 2 }}>
          {data.containerId.containerName}
        </TableCell>
        <TableCell align="center" sx={{ pl: 2 }}>
          {data.currentBalance}
        </TableCell>

        <TableCell align="center">{data.totalReceived}</TableCell>
        <TableCell align="center">{quan}</TableCell>

        <TableCell align="center">
          <QuantityEnter
            quantity={quantity}
            increaseClickHandler={increaseClickHandler}
            decreaseClickHandler={decreaseClickHandler}
            InputHandler={InputHandler}
          />
        </TableCell>

        <TableCell align="right">
          <Button
            fullWidth
            sx={{ height: "40px" }}
            variant="contained"
            onClick={updateRate}
            disabled={0 == quantity && !state.loader}
          >
            {state.loader ? <CircularLoader /> : "Update"}
          </Button>
        </TableCell>
      </TableRow>

      <Tosted
        label={state.message}
        open={state.tosted}
        severity={state.severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default TRow;
