import { sample } from "lodash";
import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Edit Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Price (₹)",
    isMove: false,
    align: "right",
  },
  {
    id: 4,
    name: "UOM",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Update Price (₹)",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },

  {
    id: 1,
    name: "Edit Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "Price (₹)",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "UOM",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const orderHistory = [...Array(24)].map((_, index) => ({
  id: index + 1,
  name: sample([
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Watermelon",
    "Strawberry",
    "Kiwi",
    "Peach",
  ]),
  type: sample(["kg", "PCS"]),
  price: sample(["100", "150", "80", "70", "110"]),
}));
