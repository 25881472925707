import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { Link } from "react-router-dom";
import { IShopList } from "../../../../models/IShop";

// ----------------------------------------------------------------------
interface IProps {
  data: IShopList;
  index: number;
  selectDate: string;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index, selectDate }: IProps) => {
  return (
    <>
      <TableRow hover role="checkbox" key={index} sx={{ cursor: "pointer" }}>
        <TableCell component="th" scope="row" padding="none">
          <Link
            to={`/supplier/${data.sellerShopNumberId}?selectdate=${selectDate}`}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar alt={"#"} src={``} />
              <Typography variant="subtitle2" noWrap>
                {data.sellerShopNumber}
              </Typography>
            </Stack>
          </Link>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          <Link
            to={`/supplier/${data.sellerShopNumberId}?selectdate=${selectDate}`}
          >
            {data.totalProductsPurchased}
          </Link>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="right">
          <Link
            to={`/supplier/${data.sellerShopNumberId}?selectdate=${selectDate}`}
          >
            <IconButton>
              <Iconify icon="oui:arrow-right" />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
