import {
  TableRow,
  TableCell,
  Stack,
  IconButton,
  Box,
  Button,
  Typography,
} from "@mui/material";
import Iconify from "../../../core/Iconify";
import { IAllocate } from "../../../models/Allocate/IAllocate";
import { IBranch } from "../../../models/Branch/IBranch";
import { useEffect, useState } from "react";

import BasicInput from "../../../core/InputField/BasicInput";
import { IState } from "../../../models/IState";
import { AllocateService } from "../../../services/Allocate";
import {
  IAddAllocate,
  BranchwiseEntity,
} from "../../../models/Allocate/IAddAllocate";
import Tosted from "../../../core/Tosted";
import CircularLoader from "../../../core/CircularLoader";
import { useDriverStore } from "../../../store/useDriverListStore";
// ----------------------------------------------------------------------
interface IProps {
  data: IAllocate;
  index: number;
  branchList: IBranch[];
  driverId: IDriver[];
  setActive: (active: boolean) => void;
}

interface IDriver {
  branchId: string;
  driverId: string;
  branchIdValid: {
    message: string;
    isValid: boolean;
  };
  driverIdValid: {
    message: string;
    isValid: boolean;
  };
}

export interface IbranchWeightPairs {
  branchId: string;
  weightDetail: WeightDetailEntity[];
}
export interface WeightDetailEntity {
  quantity: number;
  PCS: number;
  quantityValid: {
    message: string;
    isValid: boolean;
  };
  pcsValid: {
    message: string;
    isValid: boolean;
  };
}

// ----------------------------------------------------------------------
const TRow = ({ data, index, branchList, driverId, setActive }: IProps) => {
  const [branchPair, setBranchPair] = useState<IbranchWeightPairs[]>(
    [] as IbranchWeightPairs[]
  );

  const { setDrivers, updateDriverByBranchId, drivers } = useDriverStore();

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const inputHandler = (
    branchId: string, // Added branchId to identify the correct branch
    weightIndex: number, // Renamed to weightIndex for clarity
    field: keyof WeightDetailEntity, // This remains unchanged
    value: number // Changed to any to handle different field types
  ) => {
    setBranchPair((currentPairs) =>
      currentPairs.map((pair) => {
        // Find the correct branch by branchId
        if (pair.branchId === branchId) {
          // Map over weightDetails to find the correct weightDetail to update
          const updatedWeightDetails = pair.weightDetail.map((detail, idx) => {
            if (idx === weightIndex) {
              // Update the correct weightDetail with the new value for the specified field
              // Special handling for fields that are objects
              if (field === "quantityValid" || field === "pcsValid") {
                return {
                  ...detail,
                  [field]: {
                    ...detail[field],
                    // ...value?value:, // Assuming value is an object with { message, isValid }
                  },
                };
              } else {
                return {
                  ...detail,
                  [field]: value, // Directly update the value for simple fields
                };
              }
            } else {
              return detail; // Return unchanged details for other indices
            }
          });
          return {
            ...pair,
            weightDetail: updatedWeightDetails,
          };
        } else {
          return pair; // Return unchanged pairs for other branches
        }
      })
    );
  };

  const initializeBranchPair = () => {
    const branchWeightPairs: IbranchWeightPairs[] = branchList.map(
      (branch) => ({
        branchId: branch._id,
        weightDetail: [
          {
            quantity: 0,
            PCS: 0,
            quantityValid: {
              message: "",
              isValid: false,
            },
            pcsValid: {
              message: "",
              isValid: false,
            },
          },
        ],
      })
    );

    setBranchPair(branchWeightPairs);
  };

  useEffect(() => {
    initializeBranchPair();
  }, [data]);

  const addWeightPair = (id: string) => {
    setBranchPair((currentPairs) =>
      currentPairs.map((pair) => {
        if (pair.branchId === id) {
          const newWeightDetail = {
            quantity: 0,
            PCS: 0,
            quantityValid: {
              message: "",
              isValid: false,
            },
            pcsValid: {
              message: "",
              isValid: false,
            },
          };
          console.log("Before Adding:", pair.weightDetail); // Debugging line
          const updatedPair = {
            ...pair,
            weightDetail: [...(pair.weightDetail || []), newWeightDetail],
          };
          console.log("After Adding:", updatedPair.weightDetail); // Debugging line
          return updatedPair;
        }
        return pair;
      })
    );
  };

  const removeWeightPair = (branchId: string, index: number) => {
    setBranchPair((currentPairs) =>
      currentPairs.map((pair) => {
        if (pair.branchId === branchId) {
          // Remove the weightDetail at the given index
          const updatedWeightDetails = pair.weightDetail?.filter(
            (_, idx) => idx !== index
          );
          return {
            ...pair,
            weightDetail: updatedWeightDetails,
          };
        }
        return pair;
      })
    );
  };

  const updatePCSOrderHandler = async () => {
    const merged = branchPair.map((branchPair) => {
      const driverInfo = drivers.find(
        (driver) => driver.branchId === branchPair.branchId
      ) || {
        driverId: "",
        branchIdValid: { isValid: true, message: "" },
        driverIdValid: { isValid: false, message: "Driver is required." },
      };

      return { ...driverInfo, ...branchPair };
    });

    console.log("merged", merged);

    const filteredBranches = merged
      .map((branch) => ({
        ...branch,
        weightDetail: branch.weightDetail.filter(
          (detail) => detail.quantity > 0 || detail.PCS > 0
        ),
      }))
      .filter((branch) => branch.weightDetail.length > 0);

    const filteredBranches2 = merged
      .map((branch) => ({
        ...branch,
        weightDetail: branch.weightDetail.filter(
          (detail) => detail.quantity > 0 || detail.PCS > 0
        ),
      }))
      .filter((branch) => branch.weightDetail.length > 0);

    console.log("filteredBranches", filteredBranches);
    console.log("filteredBranches2", filteredBranches2);

    if (filteredBranches.length > 0 && filteredBranches2.length > 0) {
      if (filteredBranches.length === filteredBranches2.length) {
        let missingDriverIds = filteredBranches.filter(
          (branch) => !branch.driverId
        );

        if (missingDriverIds.length > 0) {
          setState({
            loader: false,
            tosted: true,
            severity: "error",
            message: "Select a Driver",
          });
          return; // Prevent further execution if there are missing driver IDs
        }

        const convertToBranchwiseEntity: BranchwiseEntity[] =
          filteredBranches.map((item) => ({
            branchId: item.branchId,
            driverId: item.driverId,
            weightDetail: item.weightDetail.map((detail) => ({
              quantity: detail.quantity,
              PCS: detail.PCS,
            })),
          }));

        callAllocateHandler(
          data._id,
          data.supplierId,
          convertToBranchwiseEntity
        );
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Enter PCS & Quantity",
        });
      }
    } else {
      setState({
        loader: false,
        tosted: true,
        severity: "error",
        message: "Enter PCS & Quantity",
      });
    }
  };

  const callAllocateHandler = async (
    _id: string,
    supplierId: string,
    data: BranchwiseEntity[]
  ) => {
    setState({
      ...state,
      loader: true,
    });
    const allocatedata: IAddAllocate = {
      productId: _id,
      supplierId: supplierId,
      branchwise: data,
    };

    try {
      const response = await AllocateService.addorderallocation(allocatedata);
      if (response.status === 200 && response.data) {
        setState({
          loader: false,
          tosted: true,
          severity: "success",
          message: response.data.message,
        });
        initializeBranchPair();
        setActive(true);
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          {`${data.name.slice(0, 20)}${data.name.length > 20 ? "..." : ""}`}
        </TableCell>
        <TableCell align="left">{data.supplierShopNumber}</TableCell>

        {branchPair.map((list, index) => {
          const distributeMatch = data.distributions?.find(
            (d) => d.branchId === list.branchId
          );

          return (
            <TableCell
              size={"large" as any}
              key={list.branchId}
              align={"center"}
              padding={"none"}
              sx={{
                borderLeft: "1px solid #ccc",
                pr: 0.5,
              }}
            >
              <Stack direction={"column"} pb={0.5} height={"100%"}>
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  gap={"0px"}
                  justifyContent={"space-between"}
                >
                  <Box
                    width={"66px"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight={"600"}
                      fontSize={"13px"}
                      textAlign={"center"}
                    >
                      {distributeMatch?.allocated.PCS}/
                      {distributeMatch?.Distribute?.PCS}
                    </Typography>
                  </Box>
                  <Box
                    width={"66px"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Typography
                      fontWeight={"600"}
                      fontSize={"13px"}
                      textAlign={"center"}
                    >
                      {distributeMatch?.allocated.quantity} /
                      {distributeMatch?.Distribute?.quantity}
                    </Typography>
                  </Box>
                  <Box width={"15%"}>
                    <Typography textAlign={"center"}> </Typography>
                  </Box>
                </Stack>
                {list.weightDetail.map((pair, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    gap={"0px"}
                    justifyContent={"space-between"}
                  >
                    <BasicInput
                      value={pair.PCS}
                      type="number"
                      label="PCS"
                      handleInputChange={(e) =>
                        inputHandler(
                          list.branchId,
                          index,
                          "PCS",
                          +e.target.value
                        )
                      }
                      error={
                        pair.pcsValid.isValid
                          ? pair.pcsValid.message
                          : undefined
                      }
                      // placeholder={
                      //   distributeMatch?.Distribute?.PCS
                      //     ? parseInt(
                      //         distributeMatch?.Distribute?.PCS.toString(),
                      //         10
                      //       ).toString()
                      //     : ""
                      // }
                      disabled={false}
                    />

                    <BasicInput
                      value={pair.quantity}
                      type="number"
                      label="UOM"
                      handleInputChange={(e) =>
                        inputHandler(
                          list.branchId,
                          index,
                          "quantity",
                          +e.target.value
                        )
                      }
                      error={
                        pair.quantityValid.isValid
                          ? pair.quantityValid.message
                          : undefined
                      }
                      disabled={false} // Or use your logic to disable input
                      // placeholder={
                      //   distributeMatch?.Distribute?.quantity
                      //     ? parseInt(
                      //         distributeMatch?.Distribute?.quantity.toString(),
                      //         10
                      //       ).toString()
                      //     : ""
                      // }
                    />

                    {index === 0 ? (
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={() => addWeightPair(list.branchId)}
                      >
                        <Iconify icon="fluent:add-12-filled" />
                      </IconButton>
                    ) : (
                      list.weightDetail.length > 1 && (
                        <IconButton
                          onClick={() => removeWeightPair(list.branchId, index)}
                          size="large"
                          color="error"
                        >
                          <Iconify icon="fluent:delete-12-regular" />
                        </IconButton>
                      )
                    )}
                  </Stack>
                ))}
              </Stack>
            </TableCell>
          );
        })}

        <TableCell align="right">
          <Button
            variant="contained"
            sx={{ height: "40px" }}
            disabled={state.loader}
            onClick={updatePCSOrderHandler}
          >
            {!state.loader ? `Update ` : <CircularLoader />}
          </Button>
        </TableCell>
      </TableRow>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default TRow;
