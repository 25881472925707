import { Box, IconButton, Stack } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import Tosted from "../../../../../core/Tosted";
import { IAddPCSOrder } from "../../../../../models/IAddOrder";
import { PurchaseServie } from "../../../../../services/PurchaseServices";
import AllSupplier from "../../../../../core/AllSupplier";
import { IBranch } from "../../../../../models/Branch/IBranch";
import { BranchService } from "../../../../../services/Branch";
import useIsBuyOrderStore from "../../../../../store/useBuyOrder";
import AutoInput from "../../../../shopmanger/Check-order/core/AutoInput";
import { IProduct } from "../../../../../models/IProduct";
import { ISupplier } from "../../../../../models/Suppliers/ISupplier";
import { IContainer } from "../../../../../models/IContainer";

interface IProps {
  handleClose: () => void;
  // data: IBuyList;
  isPCS: boolean;
}

interface IDistribution {
  branchId: string;
  quantity: number;
  ratio: number | string;
  PCS: number;
}

interface IWeight {
  quantity: number;
  PCS: number;
  quantityValid: {
    message: string;
    isValid: boolean;
  };
  pcsValid: {
    message: string;
    isValid: boolean;
  };
}

interface IQty {
  branchId: string;
  quantity: number;
  ratio: number | string;
}

interface IProductData {
  id: string;
  name: string;
}

const AddOrderPCSForm = ({ handleClose, isPCS }: IProps) => {
  const [containerInput, setContainerInput] = useState("");
  const [container, setContainer] = useState<IContainer | null>(null);
  const { setActive } = useIsBuyOrderStore((state) => ({
    setActive: state.setActive,
  }));

  const [distribution, setDistribution] = useState<IDistribution[]>([]);
  const [receivedQuantities, setReceivedQuantities] = useState<IQty[]>(
    [] as IQty[]
  );

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPCS, setTotalPCS] = useState(0);
  const [remainQty, setRemainOty] = useState(0);
  const [remainPCS, setRemainPCS] = useState(0);

  const [pcs, setPcs] = useState(0);
  const [pcsValid, setPcsValid] = useState<{
    isValid: boolean;
    message: string;
  }>({
    message: "",
    isValid: false,
  });

  const containerChangeHandler = (event: any, newValue: IContainer | null) => {
    setContainer(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };
  const containerInputChange = (event: any, newInputValue: string) => {
    setContainerInput(newInputValue);
  };

  const [branchlist, setBranchList] = useState<IBranch[]>([] as IBranch[]);
  const [pValid, setPValid] = useState(false);
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [nameValid, setNameValid] = useState<{
    isValid: boolean;
    message: string;
  }>({
    isValid: false,
    message: "Select An Shop",
  });

  // const { list } = useSupplierStore();

  const [shopData, setShopData] = useState<ISupplier | null>(null);
  const [shopInput, setShopInput] = useState("");

  const shopChangeHandler = (event: any, newValue: ISupplier | null) => {
    setShopData(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };

  const shopInputChange = (event: any, newInputValue: string) => {
    setShopInput(newInputValue);
  };

  const getSymbolForArea = (area: IBranch["area"]): string => {
    switch (area) {
      case "MALI COLONY":
        return "M";
      case "SYPHON":
        return "SY";
      case "UNIVERSITY":
        return "UN";
      default:
        return "";
    }
  };

  const [searchProduct, setSearchProduct] = useState<IProduct | null>(null);
  const [productData, setProductData] = useState<IProductData[]>(
    [] as IProductData[]
  );

  const [weightPairs, setWeightPairs] = useState<IWeight[]>([
    {
      quantity: 0,
      PCS: 0,
      quantityValid: {
        message: "",
        isValid: false,
      },
      pcsValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const inputHandler = (value: string) => {
    // setPassword(event.target.value);
    setPcs(+value);

    const isEmpty = value.trim() === "";
    setPcsValid({
      isValid: isEmpty ? true : false,
      message: isEmpty ? "Fill PCS" : "2",
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateOrderHandler();
  };

  useEffect(() => {
    const total = weightPairs.reduce((acc, item) => {
      // Check if item.quantity is not an empty string and is a valid number string
      // If not, default to 0
      const quantity =
        item.quantity && !isNaN(item.quantity) ? item.quantity.toString() : 0;
      return acc + +quantity;
    }, 0);

    const totalPCS = weightPairs.reduce((acc, item) => {
      const quantity = item.PCS && !isNaN(item.PCS) ? item.PCS.toString() : 0;
      return acc + +quantity;
    }, 0);
    setTotalQuantity(total);
    setTotalPCS(totalPCS);
  }, [weightPairs]);

  useEffect(() => {
    getBranchListServices();
  }, []);

  const getBranchListServices = async () => {
    try {
      const response = await BranchService.getAllBranchApi();
      if (response.status === 200 && response.data && response.data.data) {
        console.log("Line No 224", response.data.data);
        const updatedBranches = response.data.data.map((shop: IBranch) => ({
          ...shop,
          symbol: getSymbolForArea(shop.area),
        }));

        // Update the state with the modified branches array
        setBranchList(updatedBranches);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const productInputHandler = (data: IProduct | null) => {
    if (data?._id && data.name) {
      setSearchProduct(data);
      setProductData([
        {
          id: data._id,
          name: data.name,
        },
      ]);
    }
  };

  // const updateOrderHandler = async () => {
  //   const weightDetail: WeightDetailEntity[] = weightPairs.map((item) => ({
  //     quantityPurchased: +item.quantity,
  //     PCSPurchased: +item.PCS,
  //   }));

  //   const convertedArray: DistributionDetailEntity[] = distribution.map(
  //     (item) => ({
  //       branchId: item.branchId,
  //       distributionWeightDetail: {
  //         quantity: item.quantity,
  //         PCS: 0,
  //       },
  //     })
  //   );

  //   const order: IAddNewOrder = {
  //     productId: searchProduct?._id ? searchProduct._id : "",
  //     supplierShopNumberId: shopData ? shopData?._id : "",
  //     containerId: container?._id,
  //     weightDetail: weightDetail,
  //     distributionDetail: convertedArray,
  //   };

  //   setState({ ...state, loader: true });
  //   try {
  //     const response = await PurchaseServie.addOrder(order);
  //     if (
  //       response.status === 200 &&
  //       response.data &&
  //       response.data.data &&
  //       response.data.data.sellerDetails.length > 0
  //     ) {
  //       setState({
  //         loader: false,
  //         tosted: true,
  //         severity: "success",
  //         message: response.data.message,
  //       });

  //       setActive(true);
  //       // getProductSellerDetail(data.productDetails._id);
  //     } else {
  //       setState({
  //         loader: false,
  //         tosted: true,
  //         severity: "error",
  //         message: "Something went wrong",
  //       });
  //     }
  //   } catch (error: any) {
  //     setState({
  //       loader: false,
  //       tosted: true,
  //       severity: "error",
  //       message: error.response.data.message,
  //     });
  //   }
  // };

  const validateOrder = () => {
    const isSupplierValid = shopData && shopData?.shopName.trim() !== "";

    const isQuantityValid = pcs > 0;
    if (isQuantityValid && isSupplierValid) {
      updateOrderHandler();
    } else {
      setState({
        loader: false,
        tosted: true,
        message: "All Filed is required ",
        severity: "error",
      });
    }
  };

  const updateOrderHandler = async () => {
    const apiData: IAddPCSOrder = {
      supplierShopNumberId: shopData?._id ? shopData?._id : "",
      productId: productData ? productData[0].id : "",
      totalPSCToPurchase: pcs,
    };

    setState({
      ...state,
      loader: true,
    });

    try {
      const response = await PurchaseServie.addOrder(apiData);
      if (response.status === 200) {
        setActive(true);
        setState({
          loader: false,
          message: response.data.message,
          severity: "success",
          tosted: true,
        });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction={"row"} gap={"20px"} width={"100%"}>
          <div style={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" spacing={1} pb={2}>
              <AutoInput
                inputHandler={productInputHandler}
                isFullWidth={true}
                isValid={pValid}
              />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1} pb={2}>
              <AllSupplier
                isFullWidth={true}
                isValid={nameValid.isValid}
                isLarge={true}
                value={shopData}
                inputValue={shopInput}
                handleChange={shopChangeHandler}
                handleInputChange={shopInputChange}
              />

              <OutlineInput
                value={pcs}
                type="number"
                label="PCS"
                handleInputChange={(e) => inputHandler(e.target.value)}
                error={pcsValid.isValid ? pcsValid.message : undefined}
                disabled={false} // Or use your logic to disable input
              />
            </Stack>

            <Box textAlign={"center"} pt={1}>
              <Contained
                type="submit"
                variant="text"
                disabled={remainQty != 0 || remainPCS != 0 || loader}
                loader={loader}
              >
                Buy Now
              </Contained>
            </Box>
          </div>
        </Stack>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};
export default AddOrderPCSForm;
