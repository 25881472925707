import { Avatar, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import CircularLoader from "../../../../core/CircularLoader";
import useIsProductStore from "../../../../store/isProduct";
import { ProductServices } from "../../../../services/ProductServices";
import { IProduct } from "../../../../models/IProduct";

type SubmitData = {
  name: string;
  description: string;
};

interface IProps {
  handleClose: () => void;
  data: IProduct;
}

const DeleteForm = ({ handleClose, data }: IProps) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const { setActive } = useIsProductStore((state) => ({
    setActive: state.setActive,
  }));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit();
  };

  const onSubmit = async () => {
    setState({
      ...state,
      loader: true,
    });

    try {
      const response = await ProductServices.deleteProductApi(data._id);
      if (response.status === 200) {
        setState({
          tosted: true,
          loader: false,
          severity: "success",
          message: response.data.message,
        });
        setActive(true);
      } else {
        setState({
          tosted: true,
          loader: false,
          severity: "error",
          message: response.data.message,
        });
      }
    } catch (error: any) {
      setState({
        tosted: true,
        loader: false,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const { loader, tosted, message, severity } = state;

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction="row" alignItems="center" spacing={1} pb={2}>
          <Avatar
            alt={data.name}
            src={`/assets/images/avatars/avatar_${1 + 1}.jpg`}
            sx={{ height: "40px", width: "40px" }}
          />
          <Typography variant="subtitle2" noWrap>
            {data.name} <br />
            {data.description}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={2}>
          <Button
            variant="outlined"
            fullWidth
            disabled={loader}
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            color="error"
            fullWidth
            disabled={loader}
          >
            {loader ? <CircularLoader /> : "Delete"}
          </Button>
        </Stack>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default DeleteForm;
