import React from "react"; // Ensure React is imported
import { Container, Paper, InputBase, Typography, Button } from "@mui/material";
import { IModel } from "../models/IModel";
import ModelOpen from "./Model copy";
interface IData {
  date: string;
  onDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Use intersection type for the props
type SelectDateProps = IModel & IData;

const SelectDate: React.FC<SelectDateProps> = ({
  open,
  handleClose,
  onModelHandler,
  subTitle,
  date,
  onDateChange,
}) => {
  return (
    <ModelOpen
      open={open}
      handleClose={() => handleClose()}
      onModelHandler={onModelHandler}
      title={"Select Date"}
      subTitle={subTitle}
    >
      <Container
        maxWidth="sm"
        component={Paper}
        elevation={2}
        sx={{
          mt: 2, // Adjusted for spacing
          display: "flex", // Added for centering the input
          justifyContent: "center", // Center horizontally
          p: 2, // Padding inside the Paper
          height: "250px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <Typography variant="h4" gutterBottom>
            Select Date :
          </Typography>
          <InputBase
            type="date"
            value={date}
            onChange={onDateChange}
            sx={{
              width: "100%", // Ensure input occupies the container width
              backgroundColor: "background.paper", // Use theme's paper color
              border: "1px solid #ccc",
              p: 1,
              borderRadius: 3,
            }}
          />
          <br />
          <Button
            sx={{ mt: 1.5 }}
            variant="contained"
            onClick={handleClose}
            fullWidth
          >
            Update Date
          </Button>
        </div>
      </Container>
    </ModelOpen>
  );
};

export default SelectDate;
