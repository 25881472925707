import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { ISupplierList } from "../../../../models/Suppliers/ISupplierList";

// ----------------------------------------------------------------------
interface IProps {
  data: ISupplierList;
  index: number;
}
// ----------------------------------------------------------------------

const TRow = ({ data, index }: IProps) => {
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left" sx={{ pl: 2 }}>
          {data.shopNumber}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2" noWrap>
              {data.shopName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{data.shopAddress.addressLine1}</TableCell>
        <TableCell align="center">
          {" "}
          {data.telephoneNumbers &&
            data.telephoneNumbers?.length > 0 &&
            data.telephoneNumbers[0].number}
        </TableCell>
        <TableCell align="center">
          {data.mobileNumbers &&
            data.mobileNumbers?.length > 0 &&
            data.mobileNumbers[0].number}
        </TableCell>
        <TableCell align="right">
          <Button variant="outlined">Update</Button>
          <IconButton color="error">
            <Iconify icon="fluent:delete-12-regular" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
