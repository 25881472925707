import { Container, Typography, Paper, Box } from "@mui/material";
import ModelOpen from "../../../../core/Model copy";
import { IModel } from "../../../../models/IModel";
import Form from "./Form";
import { IMajdur } from "../../../../models/IMajdur";

interface IProps {
  model: IModel;
  data: IMajdur;
}

const Add = ({ model, data }: IProps) => {
  return (
    <ModelOpen
      open={model.open}
      handleClose={model.handleClose}
      onModelHandler={model.onModelHandler}
      title={model.title}
      subTitle={model.subTitle}
      width="400px"
    >
      <Container
        maxWidth="xs"
        component={Paper}
        elevation={2}
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" textAlign={"start"} gutterBottom>
          {model.title}
        </Typography>
        <Typography textAlign={"start"} gutterBottom>
          {model.subTitle}
        </Typography>
        <Box py={2}>
          <Form handleClose={model.handleClose} data={data} />
        </Box>
      </Container>
    </ModelOpen>
  );
};

export default Add;
