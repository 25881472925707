import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import Iconify from "../../../../core/Iconify";

// import BasicInput from "../../../../../core/InputField/BasicInput";
import BasicInput from "../../../../core/InputField/BasicInput";
import { IBranch } from "../../../../models/Branch/IBranch";
// import { IBranch } from "../../../../../models/Branch/IBranch";
// import { IBrackDownItem } from "../../../../../models/IBrackdown";

import { IBrackDownItem } from "../../../../models/IBrackdown";

interface IDistribution {
  branchId: string;
  quantity: number;
  ratio: number | string;
  PCS: number;
}

interface IProps {
  branchList: IBranch[];
  BrackdownList: IBrackDownItem;
  totalQuantity: number;
  distribution: IDistribution[];
  setDistribution: (data: IDistribution[]) => void;
  remainQty: number;
  setRemainOty: (qty: number) => void;
  totalPCS: number;
  remainPCS: number;
  setRemainPCS: (PCS: number) => void;
}

const BranchOrder: React.FC<IProps> = ({
  branchList,
  BrackdownList,
  totalQuantity,
  distribution,
  setDistribution,
  remainQty,
  setRemainOty,
  totalPCS,
  remainPCS,
  setRemainPCS,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const [distributionBasic, setDistributionBasic] = useState<IDistribution[]>(
    [] as IDistribution[]
  );

  console.table([totalPCS, totalQuantity, "/", remainPCS, remainQty]);

  const isLoading = branchList.length === 0;

  const initialHandler = () => {
    setIsEdit(true);
    const newQuantities = branchList.map((branch) => {
      // Safely find the branch in the BrackdownList
      const branchData = BrackdownList?.branches?.find(
        (b) => b.branchId === branch._id
      );

      let quantity = 0;
      let ratio = 0;

      let PCS = 0;

      if (branchData) {
        // Ensure quantity is processed correctly
        if (typeof branchData.quantity === "number") {
          quantity = parseFloat(branchData.quantity.toFixed(2));
        }

        if (typeof branchData.totalPCS === "number") {
          PCS = parseFloat(branchData.totalPCS.toFixed(2));
        }

        // Convert ratio from string to float and format to two decimal places
        if (+branchData.quantityRatio > 0) {
          if (
            branchData.quantityRatio &&
            !isNaN(parseFloat(branchData.quantityRatio))
          ) {
            ratio = parseFloat(parseFloat(branchData.quantityRatio).toFixed(2));
          }
        } else if (+branchData.pcsRatio > 0) {
          if (branchData.pcsRatio && !isNaN(parseFloat(branchData.pcsRatio))) {
            ratio = parseFloat(parseFloat(branchData.pcsRatio).toFixed(2));
          }
        }
      }

      return {
        branchId: branch._id,
        quantity,
        ratio,
        PCS,
      };
    });

    setDistribution(newQuantities);
    setDistributionBasic(newQuantities);
  };

  useEffect(() => {
    if (!isLoading) {
      if (BrackdownList) {
        initialHandler();
      }
    }
  }, [branchList, BrackdownList, isLoading]);

  useEffect(() => {
    if (totalQuantity !== 0 && distribution.length > 0) {
      // Calculate new quantities with rounding
      let updatedQuantities = distribution.map((item) => {
        const ratio =
          typeof item.ratio === "string" ? parseFloat(item.ratio) : item.ratio;
        const calculatedQuantity = (ratio / 100) * totalQuantity;
        const roundedQuantity = Math.round(calculatedQuantity);
        return {
          ...item,
          quantity: roundedQuantity,
        };
      });

      // Adjust the sum to match totalQuantity if necessary
      const currentTotal = updatedQuantities.reduce(
        (acc, cur) => acc + cur.quantity,
        0
      );
      const difference = totalQuantity - currentTotal;

      if (difference !== 0) {
        // Find the index of the last non-zero ratio item to adjust
        const lastNonZeroRatioIndex = updatedQuantities
          .map((item, index) => ({
            index,
            ratio: parseFloat(item.ratio.toString()),
          }))
          .filter((item) => item.ratio > 0)
          .pop()?.index;

        if (lastNonZeroRatioIndex !== undefined) {
          updatedQuantities[lastNonZeroRatioIndex] = {
            ...updatedQuantities[lastNonZeroRatioIndex],
            quantity:
              updatedQuantities[lastNonZeroRatioIndex].quantity + difference,
          };
        }
      }

      setDistribution(updatedQuantities);
      setDistributionBasic(updatedQuantities);
    } else {
      initialHandler();
    }
  }, [totalQuantity]);

  useEffect(() => {
    if (totalPCS !== 0 && distribution.length > 0) {
      // Calculate new quantities with rounding
      let updatedQuantities = distribution.map((item) => {
        const ratio =
          typeof item.ratio === "string" ? parseFloat(item.ratio) : item.ratio;
        const calculatedQuantity = (ratio / 100) * totalPCS;
        const roundedQuantity = Math.round(calculatedQuantity);
        return {
          ...item,
          PCS: roundedQuantity,
        };
      });

      // Adjust the sum to match totalQuantity if necessary
      const currentTotal = updatedQuantities.reduce(
        (acc, cur) => acc + cur.PCS,
        0
      );
      const difference = totalPCS - currentTotal;

      if (difference !== 0) {
        // Find the index of the last non-zero ratio item to adjust
        const lastNonZeroRatioIndex = updatedQuantities
          .map((item, index) => ({
            index,
            ratio: parseFloat(item.ratio.toString()),
          }))
          .filter((item) => item.ratio > 0)
          .pop()?.index;

        if (lastNonZeroRatioIndex !== undefined) {
          updatedQuantities[lastNonZeroRatioIndex] = {
            ...updatedQuantities[lastNonZeroRatioIndex],
            PCS: updatedQuantities[lastNonZeroRatioIndex].PCS + difference,
          };
        }
      }

      setDistribution(updatedQuantities);
      setDistributionBasic(updatedQuantities);
    } else {
      initialHandler();
    }
  }, [totalPCS]);

  const qtyHandler = (branchId: string, newQuantity: number, key: boolean) => {
    if (key) {
      // Convert input to a number, in case it's a string, and restrict it to two decimal places
      newQuantity = parseFloat(newQuantity.toFixed(2));

      // Calculate new total quantity to check if it exceeds the overall totalQuantity
      const newTotal = distribution.reduce((acc, cur) => {
        return cur.branchId !== branchId ? acc + cur.quantity : acc;
      }, newQuantity);

      if (newTotal > totalQuantity) {
        console.error(
          "Total quantity exceeded. Please adjust quantities accordingly."
        );
        return;
      }

      // Update the quantity for the specified branch
      const updatedQuantities = distribution.map((q) => {
        if (q.branchId === branchId) {
          return { ...q, quantity: newQuantity };
        }
        return q;
      });

      setDistribution(updatedQuantities);
    } else {
      // Convert input to a number, in case it's a string, and restrict it to two decimal places
      newQuantity = parseFloat(newQuantity.toFixed(2));

      // Calculate new total quantity to check if it exceeds the overall totalQuantity
      const newTotal = distribution.reduce((acc, cur) => {
        return cur.branchId !== branchId ? acc + cur.PCS : acc;
      }, newQuantity);

      if (newTotal > totalPCS) {
        console.error(
          "Total quantity exceeded. Please adjust quantities accordingly."
        );
        return;
      }

      // Update the quantity for the specified branch
      const updatedQuantities = distribution.map((q) => {
        if (q.branchId === branchId) {
          return { ...q, PCS: newQuantity };
        }
        return q;
      });

      setDistribution(updatedQuantities);
    }
  };

  const editHandler = () => {
    setIsEdit(false);
    const newQuantities = branchList.map((branch) => {
      // Safely find the branch in the BrackdownList
      const branchData = BrackdownList?.branches?.find(
        (b) => b.branchId === branch._id
      );

      let quantity = 0;
      let ratio = 0;

      if (branchData) {
        // Ensure quantity is processed correctly
        if (typeof branchData.quantity === "number") {
          quantity = 0;
        }

        // Convert ratio from string to float and format to two decimal places
        // if (branchData.ratio && !isNaN(parseFloat(branchData.ratio))) {
        //   ratio = parseFloat(parseFloat(branchData.ratio).toFixed(2));
        // }

        if (+branchData.quantityRatio > 0) {
          if (
            branchData.quantityRatio &&
            !isNaN(parseFloat(branchData.quantityRatio))
          ) {
            ratio = parseFloat(parseFloat(branchData.quantityRatio).toFixed(2));
          }
        } else if (+branchData.pcsRatio > 0) {
          if (branchData.pcsRatio && !isNaN(parseFloat(branchData.pcsRatio))) {
            ratio = parseFloat(parseFloat(branchData.pcsRatio).toFixed(2));
          }
        }
      }

      return {
        branchId: branch._id,
        quantity,
        ratio,
        PCS: 0,
      };
    });

    setDistribution(newQuantities);
  };

  useEffect(() => {
    // This updates the remaining quantity every time quantities change
    const totalUsedQuantity = distribution.reduce(
      (acc, item) => acc + item.quantity,
      0
    );

    const remaining = totalQuantity - totalUsedQuantity;

    // Fix the remaining quantity to two decimal places without rounding
    const fixedRemaining = parseFloat(remaining.toFixed(2));
    setRemainOty(fixedRemaining);
  }, [distribution, totalQuantity]);

  useEffect(() => {
    // This updates the remaining quantity every time quantities change
    const totalUsedPCS = distribution.reduce((acc, item) => acc + item.PCS, 0);
    const remaining = totalPCS - totalUsedPCS;
    // debugger;

    // Fix the remaining quantity to two decimal places without rounding
    const fixedRemaining = parseFloat(remaining.toFixed(2));
    setRemainPCS(fixedRemaining);
  }, [distribution, totalPCS]);

  if (isLoading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Typography fontWeight={600} gutterBottom color={"primary"}>
        Branch Order
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableCell align="center">Total (Kg)</TableCell>
            <TableCell align="center">Remaining</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{totalQuantity}</TableCell>
              <TableCell align="center" color="error">
                {" "}
                <Typography
                  fontWeight={"600"}
                  fontSize={"14px"}
                  color={totalQuantity === remainQty ? "sucess" : "error"}
                >
                  {remainQty}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableHead>
            <TableCell align="center">Total PCS</TableCell>
            <TableCell align="center">Remaining</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">{totalPCS}</TableCell>
              <TableCell align="center" color="error">
                {" "}
                <Typography
                  fontWeight={"600"}
                  fontSize={"14px"}
                  color={totalPCS === remainPCS ? "sucess" : "error"}
                >
                  {remainPCS}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {branchList.map((data) => (
                <TableCell key={data._id} align="center">
                  ({data.symbol})
                </TableCell>
              ))}
              <TableCell>
                <IconButton
                  onClick={editHandler}
                  color={isEdit ? "primary" : "error"}
                >
                  <Iconify icon={"mage:edit"} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>(KG)</TableCell>
              {distributionBasic.map((q, index) => (
                <TableCell key={q.branchId} align="center">
                  {/* {q.quantity.toFixed(2)} */}
                  {Math.floor(q.quantity * 100) / 100}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>(PCS)</TableCell>
              {distributionBasic.map((q, index) => (
                <TableCell key={q.branchId} align="center">
                  {Math.floor(q.PCS * 100) / 100}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kg</TableCell>
              {distribution.map((q, index) => (
                <TableCell key={q.branchId} align="center">
                  <BasicInput
                    value={q.quantity.toString()}
                    type="number"
                    label="Quantity"
                    handleInputChange={(e) =>
                      qtyHandler(q.branchId, +e.target.value, true)
                    }
                    error={undefined}
                    disabled={isEdit}
                    // placeholder={q.ratio.toString()}
                  />
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>PCS</b>
              </TableCell>
              {distribution.map((q, index) => (
                <TableCell key={q.branchId} align="center">
                  <BasicInput
                    value={q.PCS.toString()}
                    type="number"
                    label="PCS"
                    handleInputChange={(e) =>
                      qtyHandler(q.branchId, +e.target.value, false)
                    }
                    error={undefined}
                    disabled={isEdit}
                  />
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BranchOrder;
