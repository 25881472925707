import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  TextField,
  Divider,
  Grid,
  InputLabel,
} from "@mui/material";

import ModelOpen from "../../../../../core/Model"; // Adjust import based on your project structure
import DateFormatter from "../../../../../core/DateFormatter"; // Adjust import based on your project structure
import Tosted from "../../../../../core/Tosted"; // Adjust import based on your project structure
import { LedgerService } from "../../../../../services/supplierLedger/Ledger";
import { Label } from "../../../../../core/Label";
import { IAddPayment, DataEntity } from "../../../../../models/IAddPayment";
import { IAmountList } from "../../../../../models/IAmountList";
import { IState } from "../../../../../models/IState";
import { NumberDisplay } from "../../../../../hooks/numberDisplay";

interface IPaymentModel {
  open: boolean;
  handleClose: () => void;
  onModelHandler: () => void;
  title?: string;
  subTitle?: string;
  id: string;
}

interface ISelectedPayment {
  processDate: string;
  paymentAmount: number;
  outstandingAmount?: number; // Add this field to avoid the error
}

const PaymentModel: React.FC<IPaymentModel> = ({
  open,
  handleClose,
  onModelHandler,
  title,
  subTitle,
  id,
}) => {
  const [oList, setOList] = useState<IAmountList[]>([]);
  const [selected, setSelected] = useState<ISelectedPayment[]>([]);
  const [payment, setPayment] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0); // New state for total outstanding amount
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [netPending, setNetPending] = useState(0);
  const [netAdvance, setNetAdvance] = useState(0);
  const [pType, setPType] = useState("Bank Transfer");
  const list = ["Bank Transfer", "Cash"];
  const [valid, setValid] = useState({
    isValid: false,
    message: "",
  });

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setPType(newValue);
    if (!newValue) {
      setValid({
        isValid: false,
        message: "Select a payment type",
      });
    } else {
      setValid({
        isValid: true,
        message: "",
      });
    }
  };

  const getOutStandingApi = async () => {
    setIsLoading(true);
    try {
      const response = await LedgerService.getLedgerReportByDate(id, true);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data.ledgerEntries
      ) {
        setOList(response.data.data.ledgerEntries);
        setNetAdvance(response.data.data.netAdvance);
        setNetPending(response.data.data.netOutstanding);
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getOutStandingApi();
    }
  }, [id]);

  const handleSelect = (rowData: ISelectedPayment) => {
    const selectedIndex = selected.findIndex(
      (item) => item.processDate === rowData.processDate
    );
    let newSelected: ISelectedPayment[] = [];
    if (selectedIndex === -1) {
      newSelected = [...selected, rowData];
    } else {
      newSelected = selected.filter(
        (item) => item.processDate !== rowData.processDate
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = () => {
    if (selected.length === oList.length) {
      setSelected([]);
    } else {
      setSelected(
        oList.map((item) => ({
          processDate: item.processDate,
          paymentAmount: item.billAmount,
          outstandingAmount: item.outstandingAmount, // Add outstandingAmount here
        }))
      );
    }
  };

  // Calculate the total outstanding amount when the selected items change
  useEffect(() => {
    const totalOutstandingAmount = selected.reduce(
      (acc, payment) => acc + (payment.outstandingAmount || 0), // Provide a default value of 0
      0
    );
    setTotalOutstanding(totalOutstandingAmount);
  }, [selected]);

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalOutstanding(Number(event.target.value)); // Update payment amount when user types
  };

  const handleMakePayment = async () => {
    setState({ ...state, loader: true });

    const paymentList: DataEntity[] = selected.map((selectedPayment) => ({
      ...selectedPayment,
      paymentChannel: pType,
      paymentAmount: totalOutstanding, // Use `payment` state from the text input
    }));

    const data: IAddPayment = {
      supplierId: id,
      data: paymentList,
    };

    try {
      const response = await LedgerService.createpaymentApi(data);
      if (response.status === 200 && response.data) {
        setState({
          message: "Payment Update",
          severity: "success",
          loader: false,
          tosted: true,
        });
      } else {
        setState({
          message: "Something went wrong",
          severity: "error",
          loader: false,
          tosted: true,
        });
      }
    } catch (error) {
      setState({
        message: "Error fetching data",
        severity: "error",
        loader: false,
        tosted: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { loader, message, severity, tosted } = state;

  const TClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  };

  useEffect(() => {
    TClose();
  }, [tosted]);

  return (
    <>
      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title={title}
        subTitle={subTitle}
        width="600px"
      >
        <Container maxWidth="lg" component={Paper} elevation={2}>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h4" textAlign="start" gutterBottom>
                {title}
              </Typography>
              <Typography textAlign="start" gutterBottom>
                {subTitle}
              </Typography>
              <TableContainer sx={{ py: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < oList.length
                          }
                          checked={
                            oList.length > 0 && selected.length === oList.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Total (₹)</TableCell>
                      <TableCell>Outstanding (₹)</TableCell>
                      <TableCell>Advance (₹)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {oList.map((data, index) => (
                      <TableRow key={index} hover>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selected.some(
                              (item) => item.processDate === data.processDate
                            )}
                            onChange={() =>
                              handleSelect({
                                processDate: data.processDate,
                                paymentAmount: data.billAmount,
                                outstandingAmount: data.outstandingAmount, // Add outstandingAmount here
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <DateFormatter dateString={data.processDate} />
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.billAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.outstandingAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.advanceAmount)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow hover>
                      <TableCell align="center" colSpan={3}>
                        Amount
                      </TableCell>
                      <TableCell align="center">
                        <Label color={"error"}>
                          {NumberDisplay(netPending)} (Pending)
                        </Label>
                      </TableCell>
                      <TableCell align="center">
                        <Label color={"success"}>
                          {NumberDisplay(netAdvance)} (Advance)
                        </Label>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <Box>
                  {/* <Typography gutterBottom>
                    Total Outstanding Amount:{" "}
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    value={totalOutstanding} // Display total outstanding amount
                    inputProps={{ readOnly: true }} // Make it read-only
                    sx={{ marginBottom: 2 }}
                  /> */}
                  <Typography gutterBottom>Payment Amount: </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    value={totalOutstanding}
                    onChange={handlePaymentChange}
                  />
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Grid container justifyContent="center" alignItems="center">
                <FormControl sx={{ marginRight: 1, minWidth: 250 }}>
                  <InputLabel id="payment-type-label">Payment Type</InputLabel>
                  <Select
                    labelId="payment-type-label"
                    value={pType}
                    onChange={handleChange}
                  >
                    {list.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {!valid.isValid && (
                    <Typography
                      color="error"
                      sx={{ fontSize: "0.75rem", paddingTop: "5px" }}
                    >
                      {valid.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Box sx={{ py: 2, textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleMakePayment}
                >
                  Make Payment
                </Button>
              </Box>
            </>
          )}
        </Container>
      </ModelOpen>
    </>
  );
};

export default PaymentModel;
