import { Box, Divider, TableCell } from "@mui/material";
import SpaceBetween from "../../../core/SpaceBetween";

const NWeight = () => {
  return (
    <>
      <TableCell>
        <Box
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            px: 2,
          }}
        >
          <span>Nug</span>
          <span>(Kg)</span>
          <span></span>
        </Box>
      </TableCell>
    </>
  );
};
export default NWeight;
