import { TableRow, TableCell, Stack, Avatar, Typography } from "@mui/material";
import { IPurchaseReport } from "../../../../models/Branch/IPurchaseReport";
import { useSupplierStore } from "../../../../store/useSupplierStore";
import { IOrderReport } from "../../../../models/Branch/IOrderReport";
// ----------------------------------------------------------------------
interface IProps {
  data: IOrderReport;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.productName} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.productName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{data.required.actualQuantity}</TableCell>
        <TableCell align="center" sx={{ borderLeft: "1px solid #ccc" }}>
          <Stack direction={"row"} justifyContent={"space-around"}>
            <span>{data.ordered.actualQuantity}</span>
            <span>{data.ordered.difference}</span>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ borderLeft: "1px solid #ccc" }}>
          <Stack direction={"row"} justifyContent={"space-around"}>
            <span>{data.allocated.actualQuantity}</span>
            <span>{data.allocated.difference}</span>
          </Stack>
        </TableCell>
        <TableCell align="center" sx={{ borderLeft: "1px solid #ccc" }}>
          <Stack direction={"row"} justifyContent={"space-around"}>
            <span>{data.received.actualQuantity}</span>
            <span>{data.received.difference}</span>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
