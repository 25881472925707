import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Button,
  SelectChangeEvent,
  Divider,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import Update from "./Update";
import { IBuyList } from "../../../../models/IOrderList";
import useMobile from "../../../../hooks/useMobile";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import QuantityEnter from "../../../../core/QuantityEnter";
import WType from "../../../../components/WType";
import { IUmoType } from "../../../../models/IUmoType";
import AllSupplier from "../../../../core/AllSupplier";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import { IAddPCSOrder } from "../../../../models/IAddOrder";
import CircularLoader from "../../../../core/CircularLoader";
import { ISupplier } from "../../../../models/Suppliers/ISupplier";
import { useSupplierStore } from "../../../../store/useSupplierStore";
// ----------------------------------------------------------------------
interface IProps {
  data: IBuyList;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const isMobile = useMobile();
  const [wType, setWType] = useState<IUmoType>("PCS");
  const [name, setName] = useState<string>("");
  const [nameValid, setNameValid] = useState<{
    isValid: boolean;
    message: string;
  }>({
    isValid: false,
    message: "Select An Shop",
  });

  const [shopData, setShopData] = useState<ISupplier | null>(null);
  const [shopInput, setShopInput] = useState("");

  const shopChangeHandler = (event: any, newValue: ISupplier | null) => {
    setShopData(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };

  const shopInputChange = (event: any, newInputValue: string) => {
    setShopInput(newInputValue);
  };

  const [active, setActive] = useState(false);
  const [quantity, setQuantity] = useState(data.totalPSCToPurchase);
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [oModel, setOModel] = useState(false);

  const onModelHandler = () => {
    setOModel(!oModel);
  };
  const hCloseModel = () => {
    setOModel(false);
  };
  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  const validateOrder = () => {
    const isSupplierValid = shopData && shopData?.shopName.trim() !== "";

    const isQuantityValid = quantity > 0;
    if (isQuantityValid && isSupplierValid) {
      updateOrderHandler();
    } else {
      setState({
        loader: false,
        tosted: true,
        message: "All Filed is required ",
        severity: "error",
      });
    }
  };

  const updateOrderHandler = async () => {
    const apiData: IAddPCSOrder = {
      supplierShopNumberId: shopData?._id ? shopData?._id : "",
      productId: data._id,
      totalPSCToPurchase: quantity,
    };

    setState({
      ...state,
      loader: true,
    });

    try {
      const response = await PurchaseServie.addOrder(apiData);
      if (response.status === 200) {
        activeHandler(true);
        setState({
          loader: false,
          message: response.data.message,
          severity: "success",
          tosted: true,
        });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const weightChange = (event: SelectChangeEvent) => {
    if (event.target.value === "KG" || event.target.value === "PCS") {
      setWType(event.target.value);
    }
  };

  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 1000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell component="th" scope="row" padding="none">
          {isMobile ? (
            data.productDetails.name
          ) : (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={data.productDetails.name} src={``} />
              <Typography variant="subtitle2" noWrap>
                {data.productDetails.name}
              </Typography>
            </Stack>
          )}
        </TableCell>
        <TableCell align="left">
          <b>
            {data.totalOrderedQuantity === 0 ? 0 : data.totalOrderedQuantity}{" "}
            {data.UOM}/ {data.totalRequiredQuantity} {data.UOM}
          </b>
        </TableCell>

        <TableCell colSpan={2} align="center">
          <AllSupplier
            isFullWidth={true}
            isValid={nameValid.isValid}
            isLarge={false}
            value={shopData}
            inputValue={shopInput}
            handleChange={shopChangeHandler}
            handleInputChange={shopInputChange}
          />
        </TableCell>

        <TableCell align="center">
          <QuantityEnter
            quantity={quantity}
            increaseClickHandler={increaseClickHandler}
            decreaseClickHandler={decreaseClickHandler}
            InputHandler={InputHandler}
          />
        </TableCell>
        <TableCell align="center">
          {/* <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography>
              Shop : <br /> PCS :
            </Typography>
            <Typography>
              6 <br /> 10
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>
              6 <br /> 10
            </Typography>
          </Box> */}
          {data.totalPSCToPurchase}
        </TableCell>

        <TableCell align="right">
          <Stack direction={"row"} gap={"5px"} justifyContent={"end"}>
            <Stack direction={"row"} gap={"10px"}>
              <Button
                variant="contained"
                sx={{ height: "40px" }}
                disabled={state.loader}
                onClick={validateOrder}
              >
                {state.loader ? <CircularLoader /> : `Add PCS`}
              </Button>
              <Button
                variant="outlined"
                sx={{ height: "40px" }}
                onClick={onModelHandler}
              >
                {`Add Qty`}
              </Button>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>

      <Update
        open={oModel}
        handleClose={hCloseModel}
        activeHandler={activeHandler}
        onModelHandler={onModelHandler}
        title="Update An Order"
        subTitle="Please fill the fields "
        data={data}
      />
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};
export default TRow;
