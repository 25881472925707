import React, { useEffect, useState, useRef } from "react";
import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Button,
  SelectChangeEvent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { IShopOrder } from "../../../../models/IShopOrder";
import OptionList from "../../../../components/OptionList";
import QuantityEnter from "../../../../core/QuantityEnter";
import PaidType from "../../../../components/PaidType";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import CircularLoader from "../../../../core/CircularLoader";
import Tosted from "../../../../core/Tosted";
import Iconify from "../../../../core/Iconify";
import MultiPayment from "./MultiPayment";
import { IState } from "../../../../models/IState";
import { IUmoType } from "../../../../models/IUmoType";
import WType from "../../../../components/WType";

interface IProps {
  data: IShopOrder;
  index: number;
  activeHandler: (active: boolean) => void;
  date: string;
}

const TItemRow = ({ data, index, activeHandler, date }: IProps) => {
  const [percentage, setPercentage] = useState(
    data.percentage ? data.percentage.toString() : "7"
  );
  const debounceToken = useRef<number | null>(null);
  const [disable, setDisable] = useState<boolean>(false);
  const [wType, setWType] = useState<IUmoType>(data.rateOn);
  const [wSellNoType, setWSellNoType] = useState<IUmoType>(data.sellOn);
  const [netTotal, setNetTotal] = useState(
    data.sellerItemwiseAmount?.toFixed(2)
  );
  const [sRate, setSRate] = useState(data.sellingPrice || 0);
  const [wholesaleSellingPrice, setWholesaleSellingPrice] = useState(
    data.wholesaleSellingPrice ?? 0
  );

  // const [wholesaleSellingPrice, setWholesaleSellingPrice] = useState(0);
  const [quantity, setQuantity] = useState(data.rate ? data.rate : 0);
  const [sellingPercentage, setSellingPercentage] = useState(
    data.sellingPercentage ? data.sellingPercentage : 0
  );
  const [paid, setPaid] = useState(data.percentage ? "p+" : "p");
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [oMulti, setOMulti] = useState(false);

  const { id } = useParams();

  const weightChange = (event: SelectChangeEvent) => {
    if (event.target.value === "KG" || event.target.value === "PCS") {
      setWType(event.target.value);
    }
  };

  const weightSellNoChange = (event: SelectChangeEvent) => {
    if (event.target.value === "KG" || event.target.value === "PCS") {
      setWSellNoType(event.target.value);
    }
  };

  useEffect(() => {
    if (
      quantity &&
      sellingPercentage &&
      wSellNoType &&
      data.actualWeight &&
      data.totalPCSPurchased
    ) {
      const rateAmount = calculateTotal();

      let amt = 0;
      if (wSellNoType == "KG") {
        amt = rateAmount / data.actualWeight;
      } else {
        amt = rateAmount / data.totalPCSPurchased;
      }

      const value = (amt * sellingPercentage) / 100;
      const total = (amt + value).toFixed(2);

      setSRate(+total);

      console.log("Total", total);
      // debugger;
    }
  }, [quantity, sellingPercentage, wSellNoType, wType, percentage, paid]);

  const updateRate = async () => {
    setState({ ...state, loader: true });

    try {
      console.log("Send");

      const response = await PurchaseServie.updateRate(
        quantity,
        data.product._id,
        id ? id : "1",
        paid === "p+" ? (percentage ? percentage : "0") : "0",
        date,
        wholesaleSellingPrice, // Correctly placed first
        sellingPercentage, // Correctly placed second
        sRate,
        wType,
        wSellNoType
      );

      if (response.status === 200) {
        activeHandler(true);
        setDisable(true);
        setState({
          loader: false,
          message: response.data.message,
          severity: "success",
          tosted: true,
        });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const onChangeRateHandler = (event: SelectChangeEvent) => {
    setPercentage(event.target.value as string);
  };

  const onChangePaidHandler = (event: SelectChangeEvent) => {
    setPaid(event.target.value as string);
  };

  const increaseClickHandler = (isRate: boolean) => {
    if (isRate) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    } else {
      setSRate((prevQuantity) => prevQuantity + 1);
      const percentageIncrease = ((sRate + 1 - quantity) / quantity) * 100;
      setSellingPercentage(percentageIncrease);
    }
  };

  const decreaseClickHandler = (isRate: boolean) => {
    if (isRate) {
      setQuantity((prevQuantity) =>
        quantity === 0 ? quantity : prevQuantity - 1
      );
    } else {
      setSRate((prevQuantity) => (sRate === 0 ? sRate : prevQuantity - 1));
      const percentageIncrease = ((sRate - 1 - quantity) / quantity) * 100;
      setSellingPercentage(percentageIncrease);
    }
  };

  // Adjusting InputHandler and other handlers to explicitly target wholesaleSellingPrice, sRate, and sellingPercentage.

  const InputHandler = (isRate: boolean, value: string) => {
    if (value === "") {
      // Allow empty input temporarily, indicating user is still editing
      if (isRate) {
        setQuantity(0); // Or set to "" if necessary
      } else {
        setSRate(0); // Or set to "" if necessary
      }
    } else {
      const numericValue = +value;
      if (numericValue > -1) {
        if (isRate) {
          setQuantity(numericValue); // For the Quantity
          console.log("Quantity set to:", numericValue);
        } else {
          setSRate(numericValue); // For the selling rate (sRate)
          console.log("Selling Price (sRate) set to:", numericValue);

          // Debounced calculation for sellingPercentage
          if (debounceToken.current) {
            clearTimeout(debounceToken.current);
          }

          debounceToken.current = window.setTimeout(() => {
            const percentageIncrease = calculatePercentageIncrease(
              numericValue,
              quantity,
              data,
              wSellNoType
            );
            setSellingPercentage(percentageIncrease); // Correctly set sellingPercentage
          }, 1000);
        }
      }
    }
  };

  const InputWholesalePriceHandler = (value: string) => {
    if (value === "") {
      setWholesaleSellingPrice(0); // Allow clearing the field
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setWholesaleSellingPrice(parsedValue); // Update wholesale price
        console.log("Wholesale Price set to:", parsedValue);
      } else {
        console.error("Invalid wholesale price input:", value);
      }
    }
  };

  const InputSPercentageHandler = (value: string) => {
    if (value === "") {
      // Handle empty input case, allowing the user to clear the input
      setSellingPercentage(0); // Or set to "" if needed for temporary empty state
    } else {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setSellingPercentage(parsedValue); // Ensure this updates sellingPercentage correctly
        console.log("Selling Percentage set to:", parsedValue);
      } else {
        console.error("Invalid percentage input:", value);
      }
    }
  };

  const calculatePercentageIncrease = (
    value: number,
    quantity: number,
    data: IShopOrder,
    wSellNoType: string
  ) => {
    let sellingPercentage = 0;
    const totalAmount = calculateTotal();
    let costRate = 0;
    if (wSellNoType == "KG") {
      costRate = totalAmount / data.actualWeight;
    } else {
      costRate = totalAmount / data.totalPCSPurchased;
    }

    sellingPercentage = ((value - costRate) / costRate) * 100;
    return sellingPercentage;
  };

  const increaseSPercentageHandler = () => {
    setSellingPercentage((prevQuantity) => prevQuantity + 1);
  };

  const decreaseSPercentageHandler = () => {
    setSellingPercentage((prevQuantity) =>
      sellingPercentage === 0 ? sellingPercentage : prevQuantity - 1
    );
  };

  // Handle selling percentage
  // const InputSPercentageHandler = (value: string) => {
  //   setSellingPercentage(parseFloat(value));
  //   console.log("Selling Percentage set to:", parseFloat(value)); // Debugging log
  // };
  // Handler for increasing wholesale selling price
  const increaseWholesalePriceHandler = () => {
    setWholesaleSellingPrice((prevPrice) => prevPrice + 1); // Increment by 1 or adjust step
  };

  // Handler for decreasing wholesale selling price
  const decreaseWholesalePriceHandler = () => {
    setWholesaleSellingPrice((prevPrice) =>
      prevPrice > 0 ? prevPrice - 1 : 0
    ); // Decrement by 1, ensure it doesn't go below 0
  };

  // const InputWholesalePriceHandler = (value: string) => {
  //   const parsedValue = parseFloat(value);
  //   if (isNaN(parsedValue)) {
  //     console.error("Invalid wholesale price input:", value);
  //   } else {
  //     setWholesaleSellingPrice(parsedValue);
  //     console.log("Wholesale Price set to:", parsedValue); // Check the value here
  //   }
  // };

  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({ ...state, tosted: false });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [state.tosted]);

  const calculateTotal = () => {
    console.log("wType", wType);
    console.log("sellNo", wSellNoType);
    if (wType === "KG") {
      const baseValue = data.actualWeight * quantity;
      let percentageValue = 0;
      if (paid === "p+") {
        percentageValue = (baseValue * +percentage) / 100;
      }
      return baseValue + percentageValue;
    } else {
      const baseValue = data.totalPCSPurchased * quantity;
      let percentageValue = 0;
      if (paid === "p+") {
        percentageValue = (baseValue * +percentage) / 100;
      }

      return baseValue + percentageValue;
    }
  };

  return (
    <>
      <TableRow hover role="checkbox" key={index} sx={{ cursor: "pointer" }}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt="#" src="" />
            <Typography variant="subtitle2" noWrap>
              {data.product.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{data.totalPCSPurchased}</TableCell>
        <TableCell align="center">{data.totalQuantityPurchased}</TableCell>
        <TableCell align="center">{data.actualWeight}</TableCell>
        <TableCell align="center">
          <WType uRole={wType} handleChange={weightChange} />
        </TableCell>
        <TableCell align="center">
          <QuantityEnter
            quantity={quantity}
            increaseClickHandler={() => increaseClickHandler(true)}
            decreaseClickHandler={() => decreaseClickHandler(true)}
            InputHandler={(value) => InputHandler(true, value)}
          />
        </TableCell>
        <TableCell align="center">
          <PaidType value={paid} onChangeHandler={onChangePaidHandler} />
        </TableCell>
        <TableCell align="center">
          <OptionList
            value={paid === "p+" ? (percentage ? percentage : "") : ""}
            onChangeHandler={onChangeRateHandler}
            disabled={paid !== "p+"}
          />
        </TableCell>
        <TableCell align="center">
          {paid === "p+"
            ? (quantity + (quantity * +percentage) / 100).toFixed(2)
            : quantity.toFixed(2)}
        </TableCell>

        <TableCell align="center">
          <WType uRole={wSellNoType} handleChange={weightSellNoChange} />
        </TableCell>
        {/* WholesalePrice bulk */}
        {/* <TableCell align="center">
          <QuantityEnter
            quantity={
              wholesaleSellingPrice ? +wholesaleSellingPrice.toFixed(1) : 0
            }
            increaseClickHandler={increaseWholesalePriceHandler}
            decreaseClickHandler={decreaseWholesalePriceHandler}
            InputHandler={(value) => InputWholesalePriceHandler(value)} // Correct handler for wholesale price
          />
        </TableCell> */}

        {/* Percentage */}
        <TableCell align="center">
          <QuantityEnter
            quantity={sellingPercentage ? +sellingPercentage.toFixed(1) : 0}
            increaseClickHandler={increaseSPercentageHandler}
            decreaseClickHandler={decreaseSPercentageHandler}
            InputHandler={(value) => InputSPercentageHandler(value)} // Correct handler for percentage
          />
        </TableCell>

        {/* Selling price */}
        <TableCell align="center">
          <QuantityEnter
            quantity={sRate}
            increaseClickHandler={() => increaseClickHandler(false)}
            decreaseClickHandler={() => decreaseClickHandler(false)}
            InputHandler={(value) => InputHandler(false, value)} // Correct handler for sRate
          />
        </TableCell>

        <TableCell align="center">{data.previousSellingPrice}</TableCell>

        <TableCell align="center">{calculateTotal()}</TableCell>
        <TableCell align="right">
          <Stack direction="row" gap="10px">
            <Button
              fullWidth
              sx={{ height: "40px" }}
              variant="contained"
              onClick={updateRate}
              disabled={
                data.sellingPrice == sRate &&
                data.rate == quantity &&
                data.sellingPercentage == sellingPercentage &&
                data.rateOn == wType &&
                data.sellOn == wSellNoType
              }
            >
              {state.loader ? <CircularLoader /> : "Update"}
            </Button>

            <Tooltip title="Add Multiple Rate">
              <IconButton color="primary" onClick={() => setOMulti(!oMulti)}>
                <Iconify icon="mingcute:more-2-line" />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <Tosted
        label={state.message}
        open={state.tosted}
        severity={state.severity}
        handleClose={handleClose}
      />
      {oMulti && (
        <MultiPayment
          open={oMulti}
          handleClose={() => setOMulti(false)}
          onModelHandler={() => setOMulti(!oMulti)}
          title="Update Multi Amount"
          subTitle="Here You can add An Multi Amount"
          data={data}
          activeHandler={activeHandler}
        />
      )}
    </>
  );
};

export default TItemRow;
