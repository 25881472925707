import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { Link, useNavigate } from "react-router-dom";
import { IShopList } from "../../../../models/IShop";
import { ISupplierLeger } from "../../../../models/Suppliers/ledger/ISupplierLedger";

// ----------------------------------------------------------------------
interface IProps {
  data: ISupplierLeger;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const navigate = useNavigate();
  const redirct = () => {
    navigate(`/supplier-ledger/${data.supplierId}`);
  };
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{ cursor: "pointer" }}
        onClick={redirct}
      >
        <TableCell component="th" scope="row" padding="none">
          <Link to={`/supplier-ledger/${data.supplierId}`}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar alt={"#"} src={``} />
              <Typography variant="subtitle2" noWrap>
                {data?.shopNumber}
              </Typography>
            </Stack>
          </Link>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          <Link to={`/supplier-ledger/${data.supplierId}`}>
            {data.totalOutstanding}
          </Link>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          <Link to={`/supplier-ledger/${data.supplierId}`}>
            {data.totalAdvance}
          </Link>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="right">
          <Link to={`/supplier-ledger/${data.supplierId}`}>
            <IconButton>
              <Iconify icon="oui:arrow-right" />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
