import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TablePagination,
} from "@mui/material";
import THeader from "../../../core/THeader";
import MTable from "../../../core/MTable";
import THead from "./THead";
import TRow from "./TRow";
import { IState } from "../../../models/IState";
import Tosted from "../../../core/Tosted";
import NoData from "../../../core/NoData";
import { IBranch } from "../../../models/Branch/IBranch";
import { BranchService } from "../../../services/Branch";
import { AllocateService } from "../../../services/Allocate";
import { getTodayDate } from "../../../hooks/getTodayDate";
import { IAllocate } from "../../../models/Allocate/IAllocate";
import { useDriverStore } from "../../../store/useDriverListStore";
import { useBranchListStore } from "../../../store/useBranchListStore";

interface IDriver {
  branchId: string;
  driverId: string;
  branchIdValid: {
    message: string;
    isValid: boolean;
  };
  driverIdValid: {
    message: string;
    isValid: boolean;
  };
}

interface IProps {
  selectBranch: IBranch[];
}

const List = ({ selectBranch }: IProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);

  const [date, setDate] = useState<string>(getTodayDate());

  const [active, setActive] = useState(false);

  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IAllocate[] | undefined>(undefined);
  const [filterList, setFilterList] = useState<IAllocate[] | undefined>(
    undefined
  );

  const { setDrivers, updateDriverByBranchId, drivers } = useDriverStore();

  const getOrderList = async () => {
    console.log("selectBranch", selectBranch);
    const ids = selectBranch.map((mandi) => mandi._id);

    const branches = ids.join(",");

    try {
      const response = await AllocateService.getOrdersByDateAndProductName(
        date,
        branches
      );
      if (
        response.data.status === 200 &&
        response.data &&
        response.data.data.products &&
        response.data.data.products.length > 0
      ) {
        console.log("Line No 92", response.data.data);
        setList(response.data.data.products);
        setState({ ...state, loader: false });
        setActive(false);
      } else {
        console.log("Line No 95", response.data);
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (selectBranch.length > 0) {
      getOrderList();
    }
  }, [selectBranch]);

  const initializePair = () => {
    const driverPairs: IDriver[] = selectBranch?.map((branch) => ({
      branchId: branch._id,
      driverId: "",
      branchIdValid: {
        isValid: true,
        message: "",
      },
      driverIdValid: {
        isValid: false,
        message: "",
      },
    }));

    // setDriverId(driverPairs);
    setDrivers(driverPairs);
  };

  useEffect(() => {
    initializePair();
  }, [selectBranch]);

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const driverChangeHandler = (branchId: string, event: SelectChangeEvent) => {
    const newDriverId = event.target.value;
    updateDriverByBranchId(branchId, newDriverId);
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (active) {
      getOrderList();
    }
  }, [active]);

  useEffect(() => {
    const filteredItems = list?.filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilterList(filteredItems);
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  console.log("Allocation list", list);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
          />
          <MTable>
            {selectBranch && selectBranch.length > 0 && (
              <THead
                branchList={selectBranch}
                driverId={drivers}
                driverChangeHandler={driverChangeHandler}
              />
            )}

            <TableBody>
              {searchInput.length > 0
                ? filterList &&
                  filterList.map((data, index) => {
                    return (
                      <TRow
                        data={data}
                        index={index}
                        key={index}
                        branchList={selectBranch}
                        driverId={drivers}
                        setActive={setActive}
                      />
                    );
                  })
                : list &&
                  list.map((data, index) => {
                    return (
                      <TRow
                        data={data}
                        index={index}
                        key={index}
                        branchList={selectBranch}
                        driverId={drivers}
                        setActive={setActive}
                      />
                    );
                  })}
            </TableBody>
          </MTable>

          {!loader && list?.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
