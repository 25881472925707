// store/driverStore.ts
import create from "zustand";
import { IDriver } from "../models/IDriver";

interface DriverStoreState {
  drivers: IDriver[];
  setDrivers: (drivers: IDriver[]) => void;
  updateDriverByBranchId: (branchId: string, driverId: string) => void;
}

export const useDriverStore = create<DriverStoreState>((set) => ({
  drivers: [],
  setDrivers: (drivers) => set({ drivers }),
  updateDriverByBranchId: (branchId, driverId) =>
    set((state) => ({
      drivers: state.drivers.map((driver) =>
        driver.branchId === branchId
          ? {
              ...driver,
              driverId,
              driverIdValid: {
                ...driver.driverIdValid,
                isValid: true, // Assuming new driverId is always valid; adjust logic as needed
                message: "", // Update with any validation message as needed
              },
            }
          : driver
      ),
    })),
}));
