import { Add, List } from "./core";
import H1 from "../../../core/H1";
import { Stack } from "@mui/material";
import { MainHead } from "../../../core/bodyHead";
import { useState } from "react";

function Supplier() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);


  

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <MainHead
        title="Supplier List"
        iconTitle="Add New "
        onClickHandler={onModelHandler}
      />

      <List />

      <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add New Supplier"
        subTitle="Add An New Supplier"
      />
    </>
  );
}

export default Supplier;
