import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import Iconify from "../../../../../core/Iconify";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { IShopList } from "../../../../models/IShop";
import { ILegerEntries } from "../../../../../models/Suppliers/ledger/ISupplierLedger";
import DateFormatter from "../../../../../core/DateFormatter";
import { Label } from "../../../../../core/Label";

// ----------------------------------------------------------------------
interface IProps {
  data: ILegerEntries;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const { id } = useParams();
  const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    // Get month and day with padding because getMonth returns 0-11 and getDate returns 1-31
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <TableRow hover role="checkbox" key={index} sx={{ cursor: "pointer" }}>
        <TableCell component="th" scope="row" padding="none">
          <DateFormatter dateString={data.processDate} />
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          {data.billAmount}
        </TableCell>

        <TableCell component="th" scope="row" padding="none" align="center">
          {data.paymentAmount}
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          {data.outstandingAmount}
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          {data.advanceAmount}
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="center">
          <Label color={"success"}>{data.paymentChannel}</Label>
        </TableCell>
        <TableCell component="th" scope="row" padding="none" align="right">
          <Link
            to={`/supplier/${id}?selectdate=${formatDate(data.processDate)}`}
          >
            <IconButton>
              <Iconify icon="oui:arrow-right" />
            </IconButton>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
