import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface IProps {
  value: string;
  onChangeHandler: (event: SelectChangeEvent) => void;
  disabled?: boolean;
}

export default function OptionList({
  value,
  onChangeHandler,
  disabled,
}: IProps) {
  return (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{ width: "80px" }}
        disabled={disabled}
      >
        <Select value={value} onChange={onChangeHandler}>
          {["1", "2", "3", " 4", "5", "6", "7", "8", "9", "10"].map(
            (data, index) => {
              return (
                <MenuItem value={data} key={index}>
                  {data}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </>
  );
}
