import { TableRow, TableCell, Stack, Avatar, Typography } from "@mui/material";
import { useSupplierStore } from "../../../../store/useSupplierStore";
import { IPMTotalPurchase } from "../../../../models/IPMTotalPurchase";
import { Label } from "../../../../core/Label";
// ----------------------------------------------------------------------
interface IProps {
  data: IPMTotalPurchase;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const { list } = useSupplierStore();

  const getShopDetail = () => {
    return list?.find((shop) => shop._id === data.supplierId);
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.productName} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.productName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{getShopDetail()?.shopNumber}</TableCell>
        <TableCell align="center">{data.totalPCSPurchased}</TableCell>
        <TableCell align="center">{data.totalQuantityPurchased}</TableCell>
        <TableCell align="center">
          {data.rate ? data.rate : "Not Update"}
        </TableCell>
        <TableCell align="center">
          {data.percentage ? data.percentage : 0}%
        </TableCell>
        <TableCell align="center">
          {data.totalPrice ? (
            <Label color={"success"}>{data.totalPrice}</Label>
          ) : (
            <Label color={"error"}>Not update</Label>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
