import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { TableCellProps } from "@mui/material";
import { ITHead } from "../../../models/Common/ITHead";

interface ITableHeading {
  id: number;
  name: string;
  isMove: boolean;
  align: TableCellProps["align"];
}

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Required",
    isMove: false,
    align: "left",
  },
  {
    id: 4,
    name: "Ordered",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Allocated",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Received",
    isMove: false,
    align: "center",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Required",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Ordered",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Allocated",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Received",
    isMove: false,
    align: "center",
  },
];

export const THeading: ITableHeading[] = [
  {
    id: 1,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Karat",
    isMove: false,
    align: "center",
  },

  {
    id: 4,
    name: "Return",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Balance",
    isMove: false,
    align: "center",
  },
  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const isMobileHeading: ITableHeading[] = [
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "Quantity",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "UOM",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const sales = [...Array(4)].map((_, index) => ({
  id: index + 1,
  name: ["Apple", "Banana", "Orange", "Grapes", "Mango"],
  category: "Fruit",
  type: ["kg"],
}));

export const karatList = [...Array(4)].map((_, index) => ({
  id: index + 1,
  shops: sample(["1", "5", "6", "8", "3", "15"]),
  Total: sample([5, 8, 4, 12, 15]),
  return: sample([3, 1, 2]),
}));
