import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "id",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Date",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Time",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "Quantity",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "UOM",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const users = [...Array(24)].map((_, index) => ({
  id: faker.string.uuid(),
  avatarUrl: ``,
  name: faker.person.fullName(),
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(["active", "banned"]),
  role: sample([
    "Leader",
    "Hr Manager",
    "UI Designer",
    "UX Designer",
    "UI/UX Designer",
    "Project Manager",
    "Backend Developer",
    "Full Stack Designer",
    "Front End Developer",
    "Full Stack Developer",
  ]),
}));

export const receiveOrder = [...Array(3)].map((_, index) => ({
  id: faker.string.uuid(),
  particular: `Order ${index + 1}`,
  date: faker.date.past().getDate(),
  time: sample(["05:30 am", "08:00 am", "07:00 am", "06:30 am", "07:10 am"]),
  driver: sample(["Ramesh", "Raju", "Jayesh"]),
}));
