import create from "zustand";
import { IContainer } from "../models/IContainer";
import { ContainerService } from "../services/ContainerServices";

type SupplierState = {
  list: IContainer[];
  loader: boolean;
  getAllContainerList: () => Promise<void>;
};

export const useContainerStore = create<SupplierState>((set) => ({
  list: [],

  loader: false,
  getAllContainerList: async () => {
    try {
      set({ loader: true });
      const response = await ContainerService.getContainerList();
      if (response.status === 200) {
        set({ list: response.data.containers });
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      set({ loader: false });
    }
  },
}));
