import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Button,
} from "@mui/material";

import Iconify from "../../../core/Iconify";
import { Link, useNavigate } from "react-router-dom";
import { IShopPCSList } from "../../../models/IShopPCSList";

// ----------------------------------------------------------------------
interface IAllocation {
  item: string | number;
  shopNo: string | number;
  nug: number;
  weight: number;
}

interface IProps {
  data: IShopPCSList;
  index: number;
  date: string;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index, date }: IProps) => {
  const navigate = useNavigate();
  const navigateHanlder = () => {
    navigate(`/procurement/supplier-list/${data.sellerShopNumberId}/${date}`);
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{ cursor: "pointer", zIndex: 100 }}
        onClick={navigateHanlder}
      >
        <TableCell component="th" scope="row" padding="none">
          <Typography variant="subtitle2" pl={1} noWrap>
            {data.sellerShopNumber}
          </Typography>
        </TableCell>
        <TableCell align="center">{data.totalProductsPurchased}</TableCell>
        <TableCell align="right">
          <IconButton>
            <Iconify icon="solar:alt-arrow-right-linear"></Iconify>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
