// type alertType =
//   | "connected"
//   | "disconnect"
//   | "requestRaised"
//   | "requestUpdated"
//   | "requestItemDeleted"
//   | "orderPlaced"
//   | "orderUpdated"
//   | "orderDeleted"
//   | "orderReceived";
// export const CONNECTED_EVENT: alertType = "connected";
// export const DISCONNECT_EVENT: alertType = "disconnect";
// export const REQUEST_RAISED: alertType = "requestRaised";
// export const REQUEST_UPDATED: alertType = "requestUpdated";
// export const REQUEST_ITEM_DELETED: alertType = "requestItemDeleted";
// export const ORDER_PLACED: alertType = "orderPlaced";
// export const ORDER_UPDATED: alertType = "orderUpdated";
// export const ORDER_DELETED: alertType = "orderDeleted";
// export const ORDER_RECEIVED: alertType = "orderReceived";

export enum AlertType {
  Connected = "connected",
  Disconnect = "disconnect",
  RequestRaised = "requestRaised",
  RequestUpdated = "requestUpdated",
  RequestItemDeleted = "requestItemDeleted",
  OrderPlaced = "orderPlaced",
  OrderUpdated = "orderUpdated",
  OrderDeleted = "orderDeleted",
  OrderReceived = "orderReceived",
  conn_Error = "connect_error",
}
