import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

interface IData {
  rate: number;
  type: string;
  ENName: string;
  HIName: string;
}

const PrintPrice: React.FC = () => {
  const location = useLocation();
  const data: IData[] = [
    { rate: 129, type: "kg", ENName: "Mix Dal", HIName: "मिक्स दाल" },
    { rate: 200, type: "kg", ENName: "Rice", HIName: "चावल" },
    { rate: 100, type: "kg", ENName: "Wheat", HIName: "गेहूं" },
    { rate: 150, type: "kg", ENName: "Sugar", HIName: "चीनी" },
  ];

  const itemsPerPage = 4;
  const emptyItems = Array(itemsPerPage - data.length).fill(null);

  useEffect(() => {
    console.log(location);
    window.print();
  }, []);

  return (
    <div className="price-container">
      <div className="price-card">
        <div className="our-price">Our Price</div>
        <div className="price-value">
          <span className="currency-symbol">₹</span>
          {location.state.price}
          <span className="currency-symbol-sup">/{location.state.type}</span>
        </div>

        <hr className="hr" />

        <div className="price-description">
          <div className="item-name">{location.state.name}</div>
          <div className="item-name-hindi">मिक्स दाल</div>
        </div>
      </div>

      <div className="wq-logo">
        <img src="/logo.png" height={"100%"} width={"100%"} />
      </div>
    </div>
  );
};

export default PrintPrice;
