import { List } from "./core";
import { MainHead } from "../../../core/bodyHead";
// import { Add } from "../Add";
import { useState } from "react";
import Add from "./core/Add";

function ReceiveOrder() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <MainHead
        title="Receive Order"
        iconTitle="New Driver "
        onClickHandler={onModelHandler}
      />

      <List />

      <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add Drivers"
        subTitle="New Driver is Come "
      />
    </>
  );
}

export default ReceiveOrder;
