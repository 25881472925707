import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TablePagination,
} from "@mui/material";
import THeader from "./THeader";
import MTable from "../../../../core/MTable";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import TItemHead from "./TItemHead";
import TItemRow from "./TItemRow";

import { PurchaseServie } from "../../../../services/PurchaseServices";
import { useParams } from "react-router-dom";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import NoData from "../../../../core/NoData";
import PaymentModel from "./PaymentModel";
import { IPayment } from "../../../../models/IPayment";

const ItemList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);

  const [date, setDate] = useState<string>(getTodayDate());
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleCloseModel = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IPayment[] | undefined>(undefined);

  const getAllList = async () => {
    const shopNumber = id ? id : "1";
    try {
      const response = await PurchaseServie.getPaymentsByDate(date);
      if (response.status === 200 && response.data && response.data.data) {
        setTotalCount(response.data.data.totalItems);
        setPage(response.data.data.currentPage);
        setList(response.data.data.payments);
        setState({ ...state, loader: false });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
        setList([]);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
      setList([]);
    }
  };

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getAllList();
  }, [date]);

  // useEffect(() => {
  //   if (searchInput.length > 0) {
  //     // Debounce implementation
  //     const handler = setTimeout(() => {
  //       getAllList();
  //     }, 500); // 2-second delay

  //     return () => clearTimeout(handler); // Clear timeout on component unmount or if searchInput changes again within the delay
  //   }
  // }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    console.log("Date Handler", event.target.value);
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  const createPaymentHandler = () => {
    alert("call");
  };

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
            date={date}
            onDateChange={onDateChange}
          />
          <MTable>
            <TItemHead />

            <TableBody>
              {list?.map((data, index) => {
                return <TItemRow data={data} index={index} key={index} />;
              })}
            </TableBody>
          </MTable>

          {!loader && list && list.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />

      <PaymentModel
        open={open}
        activeHandler={activeHandler}
        handleClose={handleCloseModel}
        onModelHandler={onModelHandler}
        title="Update Payment"
        subTitle="Here you can update an payment"
        data={{
          orderId: "65e08a71a0e90d300f9bd4e6",
          shopNumber: "SS99",
        }}
      />
    </>
  );
};
export default ItemList;
