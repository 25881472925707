import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";

import { useEffect, useState } from "react";
import { WeightDetailEntity } from "../../../../models/Branch/IProductAdd";
import Iconify from "../../../../core/Iconify";
import { OutlineInput } from "../../../../core/InputField";
import { IaddOrUpdateReceiveOrder } from "../../../../models/Branch/IAddOrUpdateReceiveOrder";
import { useParams } from "react-router-dom";
import { IState } from "../../../../models/IState";
import { BranchService } from "../../../../services/Branch";
import CircularLoader from "../../../../core/CircularLoader";
import Tosted from "../../../../core/Tosted";
// ----------------------------------------------------------------------

interface IProps {
  data: IProductData;
  index: number;
  checkUpdateHandler: () => void;
  states: IState;
  setStates: (states: IState) => void;
  setActive: (active: boolean) => void;
}
interface IProductData {
  id: string;
  name: string;
}

// ----------------------------------------------------------------------
const TNRow = ({
  data,
  index,
  checkUpdateHandler,
  states,
  setStates,
  setActive,
}: IProps) => {
  const { id } = useParams();

  const [weightPairs, setWeightPairs] = useState<WeightDetailEntity[]>([
    {
      pcsToRacks: 0,
      pcsToWarehouse: 0,
      weightToRacks: 0,
      weightToWarehouse: 0,
    },
  ]);

  const inputHandler = (
    index: number,
    field: keyof WeightDetailEntity,
    value: string
  ) => {
    const newPairs = [...weightPairs];
    newPairs[index] = {
      ...newPairs[index],
      [field]: value,
    };

    setWeightPairs(newPairs);
  };

  const addWeightPair = () => {
    setWeightPairs([
      ...weightPairs,
      {
        pcsToRacks: 0,
        pcsToWarehouse: 0,
        weightToRacks: 0,
        weightToWarehouse: 0,
      },
    ]);
  };

  const addOrUpdateReceiveOrder = async () => {
    setStates({ ...states, loader: true });
    console.log(weightPairs);
    console.log(new Date().toISOString());

    const isAllZero = (item: WeightDetailEntity) => {
      // Convert all object values to numbers, then check if every value is 0
      return Object.values(item).every((value) => Number(value) === 0);
    };

    // Use filter() to remove objects where all specified properties are zero
    const filteredArray = weightPairs.filter((item) => !isAllZero(item));

    const sendData: IaddOrUpdateReceiveOrder = {
      processDate: new Date().toISOString(),
      arrivals: {
        driver: id ? id : "",
        arrivalDateTime: new Date().toISOString(),
        products: [
          {
            productId: data.id,
            weightDetail: filteredArray,
          },
        ],
      },
    };

    setWeightPairs(filteredArray);

    try {
      setStates({ ...states, loader: true });
      const response = await BranchService.addOrUpdateReceiveOrder(sendData);
      if (response.data.status === 200 && response.data && response.data.data) {
        setStates({
          loader: false,
          tosted: true,
          message: response.data.message,
          severity: "success",
        });
        setActive(true);
        checkUpdateHandler();
      } else {
        setStates({
          loader: false,
          tosted: true,
          message: "Something Went Wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setStates({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const removeWeightPair = (index: number) => {
    if (weightPairs.length === 1) {
      return;
    } else {
      const newPairs = [...weightPairs];
      newPairs.splice(index, 1);
      setWeightPairs(newPairs);
    }
  };

  const handleClose = () => {
    if (states.tosted) {
      setTimeout(() => {
        setStates({
          ...states,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  const { loader, message, severity, tosted } = states;

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.name} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="center">
          <Stack direction={"column"} alignItems={"center"} py={0}>
            {weightPairs.map((data, index) => {
              return (
                <>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"right"}
                    width={"100%"}
                    pb={1}
                    gap={2}
                  >
                    <IconButton
                      color="primary"
                      disabled
                      size="small"
                    ></IconButton>

                    <Box sx={{ width: "100px" }}>
                      <OutlineInput
                        value={data.pcsToWarehouse}
                        type="number"
                        label="Nug"
                        handleInputChange={(e) =>
                          inputHandler(index, "pcsToWarehouse", e.target.value)
                        }
                        error={undefined}
                        disabled={false} // Or use your logic to disable input
                      />
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      <OutlineInput
                        value={data.weightToWarehouse}
                        type="number"
                        label="Kg"
                        handleInputChange={(e) =>
                          inputHandler(
                            index,
                            "weightToWarehouse",
                            e.target.value
                          )
                        }
                        error={undefined}
                        disabled={false} // Or use your logic to disable input
                      />
                    </Box>
                  </Stack>
                </>
              );
            })}
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"left"}
            py={0}
          >
            {weightPairs.map((data, index) => {
              return (
                <>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"left"}
                    width={"100%"}
                    pb={1}
                    gap={2}
                  >
                    <Box sx={{ width: "100px" }}>
                      <OutlineInput
                        value={data.pcsToRacks}
                        type="number"
                        label="Nug"
                        handleInputChange={(e) =>
                          inputHandler(index, "pcsToRacks", e.target.value)
                        }
                        error={undefined}
                        disabled={false} // Or use your logic to disable input
                      />
                    </Box>

                    <Box sx={{ width: "100px" }}>
                      <OutlineInput
                        value={data.weightToRacks}
                        type="number"
                        label="Kg"
                        handleInputChange={(e) =>
                          inputHandler(index, "weightToRacks", e.target.value)
                        }
                        error={undefined}
                        disabled={false} // Or use your logic to disable input
                      />
                    </Box>

                    {index === 0 && (
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={addWeightPair}
                      >
                        <Iconify icon="fluent:add-12-filled" />
                      </IconButton>
                    )}

                    {index > 0 && (
                      <IconButton
                        onClick={() => removeWeightPair(index)}
                        size="large"
                        color="error"
                      >
                        <Iconify icon="fluent:delete-12-regular" />
                      </IconButton>
                    )}
                  </Stack>
                </>
              );
            })}
          </Stack>
        </TableCell>

        <TableCell align="right">
          <Button
            sx={{ height: "40px" }}
            variant="contained"
            onClick={addOrUpdateReceiveOrder}
            disabled={states.loader}
          >
            {!states.loader ? `Update ` : <CircularLoader />}
          </Button>
        </TableCell>
      </TableRow>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default TNRow;
