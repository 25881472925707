import { Stack } from "@mui/material";
import H1 from "../../core/H1";
import { List } from "./core";

const Procrument = () => {
  return (
    <>
      <Stack pb={5}>
        <H1 title="Pruduct Take from this Supplier " />
      </Stack>
      <List />
    </>
  );
};
export default Procrument;
