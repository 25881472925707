import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { DriverServices } from "../../services/Driver";
import { IDriver } from "../../models/Driver/IDriver";
import { FormHelperText, Typography } from "@mui/material";

interface IProps {
  value: string;
  handleChange: (event: SelectChangeEvent) => void;
  size?: "small" | "medium";
  branchIdValid?: {
    isValid: boolean;
    message: string;
  };
}

export default function AllDriver({
  value,
  handleChange,
  size,
  branchIdValid,
}: IProps) {
  const [list, setList] = useState<IDriver[] | undefined>([] as IDriver[]);
  const [loader, setLoader] = useState(false);

  const getAllProductList = async () => {
    try {
      setLoader(true);
      const response = await DriverServices.getDriverDetails();
      if (response.status === 200) {
        setList(response.data.data);
        setLoader(false);
      }
    } catch (error: any) {
      console.log("Error", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllProductList();
  }, []);

  return (
    <FormControl fullWidth>
      <Select
        value={value}
        onChange={handleChange}
        size={size ? size : "medium"}
      >
        {loader ? (
          <MenuItem>Loading...</MenuItem>
        ) : (
          list?.map((data, index) => (
            <MenuItem value={data._id} key={index}>
              {data.nameOfDriver}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText color="error">
        <Typography color={"error"} fontSize={"13px"}>
          {branchIdValid?.isValid && branchIdValid.message}
        </Typography>
      </FormHelperText>
    </FormControl>
  );
}
