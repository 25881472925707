import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TablePagination,
} from "@mui/material";
import THeader from "./THeader";
import MTable from "../../../../core/MTable";
import TRow from "./TRow";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import NoData from "../../../../core/NoData";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import THeaderView from "../../../../core/THeaderView";
import { AllList, MobileList } from "../data";
import { ISupplierLeger } from "../../../../models/Suppliers/ledger/ISupplierLedger";
import { LedgerService } from "../../../../services/supplierLedger/Ledger";

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);

  const [date, setDate] = useState<string>(getTodayDate());
  const [searchInput, setSearchInput] = useState<string>("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<ISupplierLeger[] | undefined>(
    [] as ISupplierLeger[]
  );

  const getAllSupplierLedger = async () => {
    try {
      const response = await LedgerService.getFetchLedgerReport(
        page,
        rowsPerPage,
        searchInput
      );
      if (response.status === 200 && response.data && response.data.data) {
        setTotalCount(response.data.data.totalItems);
        setPage(response.data.data.currentPage);
        setList(response.data.data);
        setState({ ...state, loader: false });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
        setList([]);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
      setList([]);
    }
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getAllSupplierLedger();
  }, [rowsPerPage, page, date]);

  useEffect(() => {
    // Debounce implementation
    const handler = setTimeout(() => {
      // getShopList();
    }, 500); // 2-second delay

    return () => clearTimeout(handler); // Clear timeout on component unmount or if searchInput changes again within the delay
  }, [searchInput]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    console.log("Date Handler", event.target.value);
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            date={date}
            onDateChange={onDateChange}
          />
          <MTable>
            <THeaderView MobileList={MobileList} AllList={AllList} />

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} key={index} />;
              })}
            </TableBody>
          </MTable>

          {!loader && list?.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
