import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { SellerDetailEntity, WeightDetailEntity } from "../../models/IItemList";

interface ISellerDetail {
  shopNumber: number;
  weightDetail: WeightDetailEntity[];
}

interface IItemList {
  sellerDetail: SellerDetailEntity[];
}

// Updated IProps to use the correct type for data
interface IProps {
  data: SellerDetailEntity[];
}

const ShopTable = ({ data }: IProps) => {
  const [visibleItems, setVisibleItems] = useState(2); // Start with 2 items
  console.log("Data is", data);
  const handleViewMore = () => {
    // Adjusted to show all seller details
    setVisibleItems(data.length);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Shop Number</TableCell>
            <TableCell>Quantity Purchased</TableCell>
            <TableCell>PCS Purchased</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0, visibleItems).flatMap((shop, index) =>
            shop?.weightDetails?.map((detail, detailIndex) => (
              <TableRow key={`${index}-${detailIndex}`}>
                <TableCell component="th" scope="row">
                  {shop.sellerShopNumber}
                </TableCell>
                <TableCell>{detail.quantityPurchased}</TableCell>
                <TableCell>{detail.PCSPurchased}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {visibleItems < data.length && (
        <Button onClick={handleViewMore} style={{ marginTop: "10px" }}>
          View More
        </Button>
      )}
    </TableContainer>
  );
};

export default ShopTable;
