import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Stack,
  IconButton,
  SelectChangeEvent,
  Avatar,
  Button,
  Box,
} from "@mui/material";
import ModelOpen from "../../../../../core/Model copy"; // Adjust import based on your project structure
import Tosted from "../../../../../core/Tosted"; // Adjust import based on your project structure
import { IState } from "../../../../../models/IState";
import { IShopOrder } from "../../../../../models/IShopOrder";
import { OutlineInput } from "../../../../../core/InputField";
import Iconify from "../../../../../core/Iconify";
import AllSupplier from "../../../../../core/AllSupplier";
import Contained from "../../../../../core/Button/Contained";
import { useParams } from "react-router-dom";
import {
  IAddMuliplePayment,
  RatesEntity,
} from "../../../../../models/IAddMuliplePayment";
import { PurchaseServie } from "../../../../../services/PurchaseServices";
import { getTodayDate } from "../../../../../hooks/getTodayDate";

interface IPaymentModel {
  open: boolean;
  handleClose: () => void;
  onModelHandler: () => void;
  activeHandler: (active: boolean) => void;
  title?: string;
  subTitle?: string;
  data: IShopOrder;
}

interface IRates {
  quantity: number;
  rate: number;
  percentage: number;
  quantityValid: {
    message: string;
    isValid: boolean;
  };
  rateValid: {
    message: string;
    isValid: boolean;
  };
}

const MultiPayment: React.FC<IPaymentModel> = ({
  open,
  handleClose,
  onModelHandler,
  title,
  subTitle,
  data,
  activeHandler,
}) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [name, setName] = useState<string>("");
  let { id } = useParams();
  const [date, setDate] = useState<string>(getTodayDate());
  const [nameValid, setNameValid] = useState<{
    isValid: boolean;
    message: string;
  }>({
    isValid: false,
    message: "Select An Shop",
  });

  const [ratePair, setRatePair] = useState<IRates[]>([
    {
      quantity: 0,
      rate: 0,
      percentage: 0,
      quantityValid: {
        message: "",
        isValid: false,
      },
      rateValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const addRatePair = () => {
    setRatePair([
      ...ratePair,
      {
        quantity: 0,
        rate: 0,
        percentage: 0,
        quantityValid: {
          message: "",
          isValid: false,
        },
        rateValid: {
          message: "",
          isValid: false,
        },
      },
    ]);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // // Check if the name field is filled
    // const isNameValid = name.trim() !== "";
    // if (!isNameValid) {
    //   setNameValid({
    //     isValid: true,
    //     message: "Select An Shop",
    //   });
    // }

    // Validate each weight pair
    let allPairsValid = true;
    const updatedPairs = ratePair.map((pair) => {
      const quantityValid = pair.quantity > 0; // Assuming quantity should be greater than 0
      const rateValid = pair.rate > 0; // Assuming PCS should be greater than 0

      if (!quantityValid || !rateValid) {
        allPairsValid = false; // Mark as invalid if any field in the pair is invalid
      }

      return {
        ...pair,
        quantityValid: {
          ...pair.quantityValid,
          isValid: !quantityValid,
          message: quantityValid ? "" : "Required",
        },
        rateValid: {
          ...pair.rateValid,
          isValid: !rateValid,
          message: rateValid ? "" : "Required",
        },
      };
    });

    setRatePair(updatedPairs);

    if (!allPairsValid) {
      // Optionally, scroll to the first invalid pair or focus it
      return; // Prevent form submission if any pair is invalid
    }

    updateMultipleRateHandler();
  };

  const removeWeightPair = (index: number) => {
    const newPairs = [...ratePair];
    newPairs.splice(index, 1);
    setRatePair(newPairs);
  };

  const inputHandler = (index: number, field: keyof IRates, value: string) => {
    const newPairs = [...ratePair];
    const regex = /^\d*\.?\d?$/;
    // Convert string to number for numerical fields
    const numericalValue =
      field === "quantity" || field === "rate" ? Number(value) : value;
    newPairs[index] = {
      ...newPairs[index],
      [field]: numericalValue,
    };

    if (regex.test(value)) {
      const numericalValue =
        field === "quantity" || field === "rate" || field === "percentage"
          ? Number(value)
          : value;
      newPairs[index] = {
        ...newPairs[index],
        [field]: numericalValue,
      };
    } else {
      // If the input value has more than one digit after the decimal, format it
      const formattedValue = value.match(/^\d+\.\d{2,}/);
      if (formattedValue) {
        const numericalValue =
          field === "quantity" || field === "rate" || field === "percentage"
            ? Number(parseFloat(formattedValue[0]).toFixed(2))
            : parseFloat(formattedValue[0]).toFixed(2);
        newPairs[index] = {
          ...newPairs[index],
          [field]: numericalValue,
        };
      }
    }

    const isEmpty = value.trim() === "";
    const isZero = numericalValue === 0;
    // Update validation based on field and condition
    if (field === "quantity") {
      newPairs[index].quantityValid.isValid = !isEmpty && !isZero;
      newPairs[index].quantityValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid Quantity";
    } else if (field === "rate") {
      newPairs[index].rateValid.isValid = !isEmpty && !isZero;
      newPairs[index].rateValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid Rate";
    }

    setRatePair(newPairs);
  };

  const updateMultipleRateHandler = async () => {
    const rates: RatesEntity[] = ratePair.map((item) => ({
      quantity: item.quantity,
      rate: item.rate,
      percentage: item.percentage,
    }));

    const sendData: IAddMuliplePayment = {
      supplierId: id ? id : "",
      productId: data.product._id,
      rates: rates,
      processDate: date,
    };

    setState({ ...state, loader: true });

    try {
      const response = await PurchaseServie.addMultipleRatesToProductBySupplier(
        sendData
      );
      if (response.status === 200 && response.data) {
        setState({
          loader: false,
          tosted: true,
          severity: "success",
          message: response.data.message,
        });

        activeHandler(true);

        // setTimeout(() => {
        //   setActive(true);
        // }, 3000);
        // getProductSellerDetail(data.productDetails._id);
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const { loader, message, severity, tosted } = state;
  const TClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  };

  useEffect(() => {
    TClose();
  }, [tosted]);

  return (
    <>
      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title={title}
        subTitle={subTitle}
        width="400px"
      >
        <form className="column" onSubmit={handleSubmit}>
          <Container maxWidth="lg" component={Paper} elevation={2}>
            <Typography variant="h4" textAlign="start" gutterBottom>
              {title}
            </Typography>
            <Typography textAlign="start" gutterBottom>
              {subTitle}
            </Typography>

            <Stack direction={"row"} py={1} gap={"10px"}>
              <Avatar />
              <Typography>{data.product.name}</Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              pb={2}
              justifyContent={"space-between"}
              spacing={1.5}
            >
              {data.supplierDetails.shopNumber}

              <IconButton color="primary" size="large" onClick={addRatePair}>
                <Iconify icon="fluent:add-12-filled" />
              </IconButton>
            </Stack>

            {ratePair.map((pair, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={1.5}
                pb={2}
              >
                <OutlineInput
                  value={pair.quantity}
                  type="number"
                  label="Kg"
                  handleInputChange={(e) =>
                    inputHandler(index, "quantity", e.target.value)
                  }
                  error={
                    pair.quantityValid.isValid
                      ? pair.quantityValid.message
                      : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />
                <OutlineInput
                  value={pair.rate}
                  type="number"
                  label="Rate"
                  handleInputChange={(e) =>
                    inputHandler(index, "rate", e.target.value)
                  }
                  error={
                    pair.rateValid.isValid ? pair.rateValid.message : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />

                <OutlineInput
                  value={pair.percentage}
                  type="number"
                  label="(%)"
                  handleInputChange={(e) =>
                    inputHandler(index, "percentage", e.target.value)
                  }
                  error={undefined}
                  disabled={false} // Or use your logic to disable input
                />

                <IconButton
                  onClick={() => removeWeightPair(index)}
                  size="large"
                  color="error"
                >
                  <Iconify icon="fluent:delete-12-regular" />
                </IconButton>
              </Stack>
            ))}
            <Box textAlign={"center"} pt={1}>
              <Contained
                type="submit"
                variant="text"
                disabled={state.loader}
                loader={state.loader}
              >
                Update Rate
              </Contained>
            </Box>
          </Container>
        </form>
      </ModelOpen>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={TClose}
      />
    </>
  );
};

export default MultiPayment;
