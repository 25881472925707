import { InstanceAxios } from "../hooks/axiosConfig";

export class ProcurementServices {
  public static getAllShopDetailApi(date: string) {
    return InstanceAxios().get("/order/getShopnumbers", {
      params: {
        date: date,
      },
    });
  }
  public static getDetailsByShopNumber(
    shopNumber: string,
    date: string,
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/order/getDetailsByShopNumberForProM", {
      params: {
        supplierId: shopNumber,
        date: date,
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }
}
