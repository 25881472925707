import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import Iconify from "../../../../../../core/Iconify";
import { SellerDetailEntity } from "../../../../../../models/IItemList";
import { useEffect, useState } from "react";
import { OutlineInput } from "../../../../../../core/InputField";
import {
  IUpdateOrder,
  WeightDetailEntity,
} from "../../../../../../models/IAddOrder";
import { getTodayDate } from "../../../../../../hooks/getTodayDate";
import { IState } from "../../../../../../models/IState";
import { PurchaseServie } from "../../../../../../services/PurchaseServices";
import { useSupplierStore } from "../../../../../../store/useSupplierStore";
import { ISupplier } from "../../../../../../models/Suppliers/ISupplier";
import AllSupplier from "../../../../../../core/AllSupplier";
import useIsBuyOrderStore from "../../../../../../store/useBuyOrder";

interface IProps {
  data: SellerDetailEntity;
  state: IState;
  setState: (state: IState) => void;
  productId: string;
}

interface IWeight {
  quantity: number;
  PCS: number;

  quantityValid: {
    message: string;
    isValid: boolean;
  };
  pcsValid: {
    message: string;
    isValid: boolean;
  };
}

const OrderList = ({ data, state, setState, productId }: IProps) => {
  const [weightPairs, setWeightPairs] = useState<IWeight[]>([] as IWeight[]);
  const [isEdit, setIsEdit] = useState(false);
  const { list } = useSupplierStore();
  const [nameValid, setNameValid] = useState({
    isValid: false,
    message: "Select An Shop",
  });

  const { setActive } = useIsBuyOrderStore((state) => ({
    setActive: state.setActive,
  }));

  const [shopData, setShopData] = useState<ISupplier | null>(null);

  useEffect(() => {
    const item: ISupplier | undefined = list.find(
      (shop) => shop._id === data.supplierShopNumberId
    );
    if (item) {
      setShopData(item);
    }
  }, [list]);

  const [shopInput, setShopInput] = useState("");

  const shopChangeHandler = (event: any, newValue: ISupplier | null) => {
    setShopData(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };

  const shopInputChange = (event: any, newInputValue: string) => {
    setShopInput(newInputValue);
  };

  const initializePair = () => {
    const weightPair: IWeight[] = data.weightDetails.map((data) => ({
      quantity: data.quantityPurchased,
      PCS: data.PCSPurchased,
      quantityValid: {
        isValid: true,
        message: "",
      },
      pcsValid: {
        isValid: false,
        message: "",
      },
    }));

    setWeightPairs(weightPair);
  };
  useEffect(() => {
    initializePair();
  }, [data]);

  const inputHandler = (index: number, field: keyof IWeight, value: string) => {
    const newPairs = [...weightPairs];
    const regex = /^\d*\.?\d?$/;
    // Convert string to number for numerical fields
    const numericalValue =
      field === "quantity" || field === "PCS" ? Number(value) : value;
    newPairs[index] = {
      ...newPairs[index],
      [field]: numericalValue,
    };

    if (regex.test(value)) {
      const numericalValue =
        field === "quantity" || field === "PCS" ? Number(value) : value;
      newPairs[index] = {
        ...newPairs[index],
        [field]: numericalValue,
      };
    } else {
      // If the input value has more than one digit after the decimal, format it
      const formattedValue = value.match(/^\d+\.\d{2,}/);
      if (formattedValue) {
        const numericalValue =
          field === "quantity" || field === "PCS"
            ? Number(parseFloat(formattedValue[0]).toFixed(2))
            : parseFloat(formattedValue[0]).toFixed(2);
        newPairs[index] = {
          ...newPairs[index],
          [field]: numericalValue,
        };
      }
    }

    const isEmpty = value.trim() === "";
    const isZero = numericalValue === 0;
    // Update validation based on field and condition
    if (field === "quantity") {
      newPairs[index].quantityValid.isValid = !isEmpty && !isZero;
      newPairs[index].quantityValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid Quantity";
    } else if (field === "PCS") {
      newPairs[index].pcsValid.isValid = !isEmpty && !isZero;
      newPairs[index].pcsValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid PCS";
    }

    setWeightPairs(newPairs);
  };

  const editHandler = () => {
    setIsEdit(true);
  };

  const updateHandler = () => {
    let allPairsValid = true;
    const updatedPairs = weightPairs.map((pair) => {
      const quantityValid = pair.quantity > 0; // Assuming quantity should be greater than 0
      const pcsValid = pair.PCS > 0; // Assuming PCS should be greater than 0

      if (!quantityValid || !pcsValid) {
        allPairsValid = false; // Mark as invalid if any field in the pair is invalid
      }
      return {
        ...pair,
        quantityValid: {
          ...pair.quantityValid,
          isValid: quantityValid,
          message: quantityValid ? "" : "Invalid quantity",
        },
        pcsValid: {
          ...pair.pcsValid,
          isValid: pcsValid,
          message: pcsValid ? "" : "Invalid PCS",
        },
      };
    });

    setWeightPairs(updatedPairs);
    if (!allPairsValid) {
      return;
    }
    updateQtyPCSHandler();
  };

  const updateQtyPCSHandler = async () => {
    const weightDetail: WeightDetailEntity[] = weightPairs.map((item) => ({
      quantityPurchased: +item.quantity,
      PCSPurchased: +item.PCS,
    }));

    const order: IUpdateOrder = {
      processDate: getTodayDate(),
      supplierShopNumberId: data.supplierShopNumberId,
      newSupplierShopNumberId: shopData ? shopData?._id : "",
      productId: productId,
      weightDetail: weightDetail,
    };

    setState({ ...state, loader: true });
    try {
      const response = await PurchaseServie.updateOrder(order);
      if (response.status === 200 && response.data) {
        setState({
          loader: false,
          tosted: true,
          severity: "success",
          message: response.data.message,
        });
        setActive(true);
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  return (
    <>
      {weightPairs.map((item, index) => (
        <TableRow key={index}>
          {/* Only render the seller's shop number cell for the first weightDetail entry */}
          {index === 0 && (
            <TableCell
              rowSpan={data.weightDetails.length}
              align="justify"
              sx={{
                borderBottom: "1px solid #000",
              }}
            >
              <Stack height={"100%"} alignItems={"start"} direction={"row"}>
                <AllSupplier
                  isFullWidth={true}
                  isValid={nameValid.isValid}
                  isLarge={false}
                  disabled={!isEdit}
                  value={shopData}
                  inputValue={shopInput}
                  handleChange={shopChangeHandler}
                  handleInputChange={shopInputChange}
                />
              </Stack>
            </TableCell>
          )}
          <TableCell
            align="center"
            sx={{
              borderBottom:
                data.weightDetails.length === index + 1
                  ? "1px solid #000"
                  : "1px solid none",
            }}
          >
            <OutlineInput
              value={item.PCS}
              type="number"
              size="small"
              label=""
              handleInputChange={(e) =>
                inputHandler(index, "PCS", e.target.value)
              }
              error={item.pcsValid.isValid ? item.pcsValid.message : undefined}
              disabled={!isEdit} // Or use your logic to disable input
            />
          </TableCell>
          <TableCell
            align="center"
            sx={{
              borderBottom:
                data.weightDetails.length === index + 1
                  ? "1px solid #000"
                  : "1px solid none",
            }}
          >
            <OutlineInput
              value={item.quantity}
              type="number"
              label=""
              size="small"
              handleInputChange={(e) =>
                inputHandler(index, "quantity", e.target.value)
              }
              error={
                item.quantityValid.isValid
                  ? item.quantityValid.message
                  : undefined
              }
              disabled={!isEdit} // Or use your logic to disable input
            />
          </TableCell>
          {/* Only render the edit button cell for the first weightDetail entry */}
          {index === 0 && (
            <TableCell
              align="center"
              rowSpan={data.weightDetails.length}
              sx={{ borderBottom: "1px solid #000" }}
            >
              {isEdit ? (
                <IconButton onClick={updateHandler}>
                  <Iconify icon={"mynaui:upload"} />
                </IconButton>
              ) : (
                <IconButton onClick={editHandler}>
                  <Iconify icon={"eva:edit-fill"} />
                </IconButton>
              )}
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default OrderList;
