import { useState } from "react";
import { List, Add } from "./core";
import H1 from "../../../core/H1";
import { Stack } from "@mui/material";
import { MainHead } from "../../../core/bodyHead";

function Price() {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <Stack pt={5} pb={5}>
        <H1 title="Price List" />
      </Stack>

      <List />
    </>
  );
}

export default Price;
