import { TableHead, TableRow, TableCell } from "@mui/material";
import { TList } from "../data";
import useMobile from "../../../../hooks/useMobile";
// ----------------------------------------------------------------------
const TItemHead = () => {
  const isMobile = useMobile();
  return (
    <>
      <TableHead>
        <TableRow>
          {isMobile
            ? TList.map((data, index) => (
                <TableCell
                  size={"large" as any}
                  key={index}
                  align={data.align}
                  padding={"normal"}
                >
                  {data.name}
                </TableCell>
              ))
            : TList.map((data, index) => (
                <TableCell
                  size={"large" as any}
                  key={index}
                  align={data.align}
                  padding={"normal"}
                >
                  {data.name}
                </TableCell>
              ))}
        </TableRow>
      </TableHead>
    </>
  );
};
export default TItemHead;
