import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface IProps {
  value: string;
  onChangeHandler: (event: SelectChangeEvent) => void;
}

export default function PaidType({ value, onChangeHandler }: IProps) {
  return (
    <>
      <FormControl fullWidth size="small" sx={{ width: "80px" }}>
        <Select value={value} onChange={onChangeHandler}>
          {["p", "p+"].map((data, index) => {
            return (
              <MenuItem value={data} key={index}>
                {data}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
