import { Stack } from "@mui/material";
import H1 from "../../../core/H1";
import { List } from "./core";

const PMNormalSupplier = () => {
  return (
    <>
      <Stack pb={5}>
        <H1 title="All Supplier Order" />
      </Stack>
      <List />
    </>
  );
};
export default PMNormalSupplier;
