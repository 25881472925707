import { useState } from "react";
import { Add, List, AddPCS } from "./core";
import { MainHead } from "../../../core/bodyHead";
import { Stack } from "@mui/material";
import H1 from "../../../core/H1";
import { IconBtn } from "../../../core/Button";

function BuyOrder() {
  const [open, setOpen] = useState(false);

  const [openPCS, setOpenPCS] = useState(false);

  const [active, setActive] = useState(false);

  const [isPCS, setIsPCS] = useState(false);
  const [isEnterPCSModel, setIsEnterPCSModel] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };

  const onModelPCSHandler = () => {
    setOpenPCS(!openPCS);
  };

  const callModelHandler = (isData: boolean) => {
    setIsPCS(isData);
    onModelHandler();
  };

  const callModelQTYHandler = (isData: boolean) => {
    setIsEnterPCSModel(isData);
    onModelPCSHandler();
  };
  const handleClose = () => {
    setOpen(false);
    setOpenPCS(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"} py={2}>
        <H1 title="Buy Order" />
        <Stack gap={"10px"} direction={"row"}>
          <IconBtn
            title="Add Direct Purchase"
            onClickHandler={() => callModelHandler(false)}
          />

          <IconBtn
            title="Add Direct PCS"
            onClickHandler={() => callModelQTYHandler(false)}
          />
        </Stack>
      </Stack>

      <List />

      <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add Direct Order"
        subTitle="Every divide that product"
        isPCS={isPCS}
      />

      <AddPCS
        open={openPCS}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelPCSHandler}
        title="Add Direct PCS"
        subTitle="Every divide that product"
        isPCS={isEnterPCSModel}
      />
    </>
  );
}

export default BuyOrder;
