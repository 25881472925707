import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { FormControl } from "@mui/material";

const Input = styled("input")({
  //   display: "block",
});

interface FileUploadProps {
  onChange: (file: File) => void;
  accept?: string;
  multiple?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  accept,
  multiple,
}) => {
  const [fileName, setFileName] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onChange(file);
      setFileName(file.name);
    }
  };

  return (
    <FormControl>
      {/* <label htmlFor="file-upload"> */}
      <Input
        id="file-upload"
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
      />
      {/* <Button variant="contained" component="span">
          Choose File
        </Button> */}
      {/* </label> */}
      {/* {fileName && <TextField label="Selected File" value={fileName} />} */}
    </FormControl>
  );
};

export default FileUpload;
