// NotFoundPage.tsx
import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom"; // Make sure you have react-router-dom installed

const NotFoundPage: React.FC = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h1" gutterBottom>
        Sorry, page not found!
      </Typography>
      <p>
        Sorry, we couldn’t find the page you're looking for. Perhaps <br />{" "}
        you've mistyped the URL? Be sure to check your spelling.
      </p>
      <div style={{ fontSize: "128px", marginBottom: "24px" }}>
        <img
          src="/assets/illustrations/illustration_404.svg"
          height={"100%"}
          width={"350px"}
        />
      </div>
      <Link to="/">
        <Button variant="contained">Home</Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
