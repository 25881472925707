import { Add, List } from "./core";
import { MainHead } from "../../../core/bodyHead";
import { useState } from "react";

function Container() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <MainHead
        title="Container List"
        iconTitle="Add New "
        onClickHandler={onModelHandler}
      />

      <List />

      <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add New container"
        subTitle="Add An New Container"
      />
    </>
  );
}

export default Container;
