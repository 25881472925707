import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { TableCellProps } from "@mui/material";

interface ITableHeading {
  id: number;
  name: string;
  isMove: boolean;
  align: TableCellProps["align"];
}
export const THeading: ITableHeading[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Category",
    isMove: false,
    align: "center",
  },

  {
    id: 4,
    name: "Quantity",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Rate",
    isMove: false,
    align: "center",
  },
  {
    id: 5,
    name: "Total Amount",
    isMove: true,
    align: "center",
  },
];

export const isMobileHeading: ITableHeading[] = [
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "Quantity",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "UOM",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const sales = [...Array(4)].map((_, index) => ({
  id: index + 1,
  name: ["Apple", "Banana", "Orange", "Grapes", "Mango"],
  category: "Fruit",
  type: ["kg"],
}));
