// ParentComponent.tsx
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import H1 from "../../core/H1";
// import List from "./List";
import { List } from "./core";
import AddBranch from "./core/AddBranch";
import { IBranch } from "../../models/Branch/IBranch";

const ParentComponent = () => {
  const [open, setOpen] = useState(false);
  const [selectBranch, setSelectBranch] = useState<IBranch[]>([] as IBranch[]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Stack pb={5}>
        <H1 title="Allocation Manager" />
      </Stack>
      <List selectBranch={selectBranch} />

      <AddBranch
        open={open}
        title="Select An Branch"
        subTitle="Select a Branch With Detail"
        onModelHandler={() => setOpen(!open)}
        handleClose={() => setOpen(false)}
        selectBranch={selectBranch}
        setSelectBranch={setSelectBranch}
      />
    </>
  );
};

export default ParentComponent;
