import { Box, IconButton, Stack } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import Tosted from "../../../../../core/Tosted";
import Iconify from "../../../../../core/Iconify";
import {
  DistributionDetailEntity,
  IAddNewOrder,
  WeightDetailEntity,
} from "../../../../../models/IAddOrder";
import { PurchaseServie } from "../../../../../services/PurchaseServices";
import { SellerDetailEntity } from "../../../../../models/IItemList";
import AllSupplier from "../../../../../core/AllSupplier";
import { IBranch } from "../../../../../models/Branch/IBranch";
import { BranchService } from "../../../../../services/Branch";
import useIsBuyOrderStore from "../../../../../store/useBuyOrder";
import DirectDistriute from "./DirectDistriute";
import AutoInput from "../../../../shopmanger/Check-order/core/AutoInput";
import { IProduct } from "../../../../../models/IProduct";
import { QRating } from "../../../../../core/QRate";
import { ISupplier } from "../../../../../models/Suppliers/ISupplier";
import { useSupplierStore } from "../../../../../store/useSupplierStore";
import AllContainer from "../../../../../core/AllContainer";
import { IContainer } from "../../../../../models/IContainer";

interface IProps {
  handleClose: () => void;
  // data: IBuyList;
  isPCS: boolean;
}

interface IDistribution {
  branchId: string;
  quantity: number;
  ratio: number | string;
  PCS: number;
}

interface IWeight {
  quantity: number;
  PCS: number;
  quantityValid: {
    message: string;
    isValid: boolean;
  };
  pcsValid: {
    message: string;
    isValid: boolean;
  };
}

interface IQty {
  branchId: string;
  quantity: number;
  ratio: number | string;
}

interface IProductData {
  id: string;
  name: string;
}

const AddOrderForm = ({ handleClose, isPCS }: IProps) => {
  const [containerInput, setContainerInput] = useState("");
  const [container, setContainer] = useState<IContainer | null>(null);
  const { setActive } = useIsBuyOrderStore((state) => ({
    setActive: state.setActive,
  }));

  const [distribution, setDistribution] = useState<IDistribution[]>([]);
  const [receivedQuantities, setReceivedQuantities] = useState<IQty[]>(
    [] as IQty[]
  );

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPCS, setTotalPCS] = useState(0);
  const [remainQty, setRemainOty] = useState(0);
  const [remainPCS, setRemainPCS] = useState(0);

  const handleQuantitiesUpdate = (quantities: IQty[]) => {
    setReceivedQuantities(quantities);
  };

  const containerChangeHandler = (event: any, newValue: IContainer | null) => {
    setContainer(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };
  const containerInputChange = (event: any, newInputValue: string) => {
    setContainerInput(newInputValue);
  };

  const [branchlist, setBranchList] = useState<IBranch[]>([] as IBranch[]);
  const [pValid, setPValid] = useState(false);
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });
  const [nameValid, setNameValid] = useState<{
    isValid: boolean;
    message: string;
  }>({
    isValid: false,
    message: "Select An Shop",
  });

  // const { list } = useSupplierStore();

  const [shopData, setShopData] = useState<ISupplier | null>(null);
  const [shopInput, setShopInput] = useState("");

  const shopChangeHandler = (event: any, newValue: ISupplier | null) => {
    setShopData(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };

  const shopInputChange = (event: any, newInputValue: string) => {
    setShopInput(newInputValue);
  };

  const getSymbolForArea = (area: IBranch["area"]): string => {
    switch (area) {
      case "MALI COLONY":
        return "M";
      case "SYPHON":
        return "SY";
      case "UNIVERSITY":
        return "UN";
      default:
        return "";
    }
  };

  const [searchProduct, setSearchProduct] = useState<IProduct | null>(null);
  const [productData, setProductData] = useState<IProductData[]>(
    [] as IProductData[]
  );

  const [weightPairs, setWeightPairs] = useState<IWeight[]>([
    {
      quantity: 0,
      PCS: 0,
      quantityValid: {
        message: "",
        isValid: false,
      },
      pcsValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const inputHandler = (index: number, field: keyof IWeight, value: string) => {
    const newPairs = [...weightPairs];
    const regex = /^\d*\.?\d?$/;
    // Convert string to number for numerical fields
    const numericalValue =
      field === "quantity" || field === "PCS" ? Number(value) : value;
    newPairs[index] = {
      ...newPairs[index],
      [field]: numericalValue,
    };

    if (regex.test(value)) {
      const numericalValue =
        field === "quantity" || field === "PCS" ? Number(value) : value;
      newPairs[index] = {
        ...newPairs[index],
        [field]: numericalValue,
      };
    } else {
      // If the input value has more than one digit after the decimal, format it
      const formattedValue = value.match(/^\d+\.\d{2,}/);
      if (formattedValue) {
        const numericalValue =
          field === "quantity" || field === "PCS"
            ? Number(parseFloat(formattedValue[0]).toFixed(2))
            : parseFloat(formattedValue[0]).toFixed(2);
        newPairs[index] = {
          ...newPairs[index],
          [field]: numericalValue,
        };
      }
    }

    const isEmpty = value.trim() === "";
    const isZero = numericalValue === 0;
    // Update validation based on field and condition
    if (field === "quantity") {
      newPairs[index].quantityValid.isValid = !isEmpty && !isZero;
      newPairs[index].quantityValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid Quantity";
    } else if (field === "PCS") {
      newPairs[index].pcsValid.isValid = !isEmpty && !isZero;
      newPairs[index].pcsValid.message =
        !isEmpty && !isZero ? "" : "Fill a valid PCS";
    }

    setWeightPairs(newPairs);
  };

  const addWeightPair = () => {
    setWeightPairs([
      ...weightPairs,
      {
        quantity: 0,
        PCS: 0,
        quantityValid: {
          message: "",
          isValid: false,
        },
        pcsValid: {
          message: "",
          isValid: false,
        },
      },
    ]);
  };

  const removeWeightPair = (index: number) => {
    const newPairs = [...weightPairs];
    newPairs.splice(index, 1);
    setWeightPairs(newPairs);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if the name field is filled
    const isSupplierValid = shopData && shopData?.shopName.trim() !== "";

    // This condition should set the nameValid state to indicate an error if the supplier is NOT valid
    if (!isSupplierValid) {
      setNameValid({
        isValid: true,
        message: "Please select a shop",
      });
    }

    // Validate each weight pair
    // let allPairsValid = true;
    // const updatedPairs = weightPairs.map((pair) => {
    //   const quantityValid = pair.quantity > 0; // Assuming quantity should be greater than 0
    //   const pcsValid = pair.PCS > 0; // Assuming PCS should be greater than 0

    //   if (!quantityValid || !pcsValid) {
    //     allPairsValid = false; // Mark as invalid if any field in the pair is invalid
    //   }

    let allPairsValid = true;

    const updatedPairs = weightPairs.map((pair) => {
      // Assuming quantity should be greater than 0
      const quantityValid = pair.quantity > 0;
      // Assuming PCS should be greater than 0
      const pcsValid = pair.PCS > 0;

      // Validate that at least one of quantity or PCS is valid
      const atLeastOneValid = quantityValid || pcsValid;

      if (!atLeastOneValid) {
        allPairsValid = false; // Mark as invalid if neither field is valid
      }

      return {
        ...pair,
        quantityValid: {
          ...pair.quantityValid,
          isValid: !quantityValid,
          message: quantityValid ? "" : "Invalid quantity",
        },
        pcsValid: {
          ...pair.pcsValid,
          isValid: !pcsValid,
          message: pcsValid ? "" : "Invalid PCS",
        },
      };
    });

    setWeightPairs(updatedPairs);

    if (searchProduct?._id) {
      setPValid(false);
    } else {
      setPValid(true);
    }

    if (!allPairsValid && !isSupplierValid && pValid) {
      // Optionally, scroll to the first invalid pair or focus it
      return; // Prevent form submission if any pair is invalid
    }

    updateOrderHandler();
  };

  useEffect(() => {
    const total = weightPairs.reduce((acc, item) => {
      // Check if item.quantity is not an empty string and is a valid number string
      // If not, default to 0
      const quantity =
        item.quantity && !isNaN(item.quantity) ? item.quantity.toString() : 0;
      return acc + +quantity;
    }, 0);

    const totalPCS = weightPairs.reduce((acc, item) => {
      const quantity = item.PCS && !isNaN(item.PCS) ? item.PCS.toString() : 0;
      return acc + +quantity;
    }, 0);
    setTotalQuantity(total);
    setTotalPCS(totalPCS);
  }, [weightPairs]);

  useEffect(() => {
    getBranchListServices();
  }, []);

  const getBranchListServices = async () => {
    try {
      const response = await BranchService.getAllBranchApi();
      if (response.status === 200 && response.data && response.data.data) {
        console.log("Line No 224", response.data.data);
        const updatedBranches = response.data.data.map((shop: IBranch) => ({
          ...shop,
          symbol: getSymbolForArea(shop.area),
        }));

        // Update the state with the modified branches array
        setBranchList(updatedBranches);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const productInputHandler = (data: IProduct | null) => {
    if (data?._id && data.name) {
      setSearchProduct(data);
      setProductData([
        {
          id: data._id,
          name: data.name,
        },
      ]);
    }
  };

  const updateOrderHandler = async () => {
    const weightDetail: WeightDetailEntity[] = weightPairs.map((item) => ({
      quantityPurchased: +item.quantity,
      PCSPurchased: +item.PCS,
    }));

    const convertedArray: DistributionDetailEntity[] = distribution.map(
      (item) => ({
        branchId: item.branchId,
        distributionWeightDetail: {
          quantity: item.quantity,
          PCS: 0,
        },
      })
    );

    const order: IAddNewOrder = {
      productId: searchProduct?._id ? searchProduct._id : "",
      supplierShopNumberId: shopData ? shopData?._id : "",
      containerId: container?._id,
      weightDetail: weightDetail,
      distributionDetail: convertedArray,
    };

    setState({ ...state, loader: true });
    try {
      const response = await PurchaseServie.addOrder(order);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.sellerDetails.length > 0
      ) {
        setState({
          loader: false,
          tosted: true,
          severity: "success",
          message: response.data.message,
        });

        setActive(true);
        // getProductSellerDetail(data.productDetails._id);
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction={"row"} gap={"20px"} width={"100%"}>
          <div style={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" spacing={1} pb={2}>
              <AutoInput
                inputHandler={productInputHandler}
                isFullWidth={true}
                isValid={pValid}
              />
              <AllContainer
                isFullWidth={true}
                isValid={undefined}
                isLarge={true}
                value={container}
                inputValue={containerInput}
                handleChange={containerChangeHandler}
                handleInputChange={containerInputChange}
              />
            </Stack>

            <Stack direction={"row"} alignItems={"center"} pb={2} spacing={1.5}>
              <AllSupplier
                isFullWidth={true}
                isValid={nameValid.isValid}
                isLarge={true}
                value={shopData}
                inputValue={shopInput}
                handleChange={shopChangeHandler}
                handleInputChange={shopInputChange}
              />

              <IconButton color="primary" size="large" onClick={addWeightPair}>
                <Iconify icon="fluent:add-12-filled" />
              </IconButton>
            </Stack>
            {weightPairs.map((pair, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={1.5}
                pb={2}
              >
                <OutlineInput
                  value={pair.PCS}
                  type="number"
                  label="PCS"
                  handleInputChange={(e) =>
                    inputHandler(index, "PCS", e.target.value)
                  }
                  error={
                    pair.pcsValid.isValid ? pair.pcsValid.message : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />
                <OutlineInput
                  value={pair.quantity}
                  type="number"
                  label="Weight"
                  handleInputChange={(e) =>
                    inputHandler(index, "quantity", e.target.value)
                  }
                  error={
                    pair.quantityValid.isValid
                      ? pair.quantityValid.message
                      : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />

                <IconButton
                  onClick={() => removeWeightPair(index)}
                  size="large"
                  color="error"
                >
                  <Iconify icon="fluent:delete-12-regular" />
                </IconButton>
              </Stack>
            ))}

            <Box textAlign={"center"} pt={1}>
              <Contained
                type="submit"
                variant="text"
                disabled={remainQty != 0 || remainPCS != 0 || loader}
                loader={loader}
              >
                Buy Now
              </Contained>
            </Box>
          </div>
          <div style={{ width: "100%" }}>
            <DirectDistriute
              branchList={branchlist}
              onQuantitiesUpdate={handleQuantitiesUpdate}
              totalQuantity={totalQuantity}
              distribution={distribution}
              setDistribution={setDistribution}
              remainQty={remainQty}
              setRemainOty={setRemainOty}
              totalPCS={totalPCS}
              remainPCS={remainPCS}
              setRemainPCS={setRemainPCS}
            />
          </div>
        </Stack>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};
export default AddOrderForm;
