import { Autocomplete, TextField } from "@mui/material";
import { useSupplierStore } from "../../store/useSupplierStore";

interface ISupplier {
  _id: string;
  shopName: string;
  shopNumber: string;
}

interface IProps {
  isFullWidth?: boolean;
  isValid?: boolean; // This prop indicates if the selected product is valid (true means it is invalid in the corrected code)
  isLarge?: boolean;
  value: ISupplier | null;
  inputValue: string;
  disabled?: boolean;
  handleChange: (event: any, newValue: ISupplier | null) => void;
  handleInputChange: (event: any, newInputValue: string) => void;
}

export default function AllSupplier({
  isFullWidth = false,
  isValid = false,
  isLarge = false,
  disabled,
  value,
  inputValue,
  handleChange,
  handleInputChange,
}: IProps) {
  const { list } = useSupplierStore();

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled ? disabled : false}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      fullWidth={isFullWidth}
      size={isLarge ? "medium" : "small"}
      sx={{ width: isFullWidth ? "100%" : "120px" }}
      options={list}
      getOptionLabel={(option) => option.shopNumber}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {option.shopNumber}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Shop"
          error={isValid} // Assuming isValid is true when the selection is valid
          helperText={isValid ? "Select a shop" : ""} // Corrected to display the helper text when the selection is invalid
        />
      )}
    />
  );
}
