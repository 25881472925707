import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },
  {
    id: 4,
    name: "WareHouse",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Rack",
    isMove: false,
    align: "left",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const mobileList: ITHead[] = [
  {
    id: 1,
    name: "Item Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "WareHouse",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Rack",
    isMove: false,
    align: "center",
  },

  {
    id: 4,
    name: "Action",
    isMove: false,
    align: "left",
  },
  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const receiveOrder = [...Array(24)].map((_, index) => ({
  id: faker.string.uuid(),
  particular: `Order ${index + 1}`,
  date: faker.date.past().getDate(),
  time: sample(["05:30 am", "08:00 am", "07:00 am", "06:30 am", "07:10 am"]),
  driver: sample(["Ramesh", "Raju", "Jayesh"]),
}));

export const orderHistory = [...Array(24)].map((_, index) => ({
  id: index + 1,
  name: sample([
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Watermelon",
    "Strawberry",
    "Kiwi",
    "Peach",
  ]),
  nug: sample([1, 2, 3, 5, 6, 8, 10]),
  weight: sample([10, 30, 40, 20, 50, 5, 8, 12, 15]),
}));
