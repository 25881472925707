import { Container, Typography, Paper, Box } from "@mui/material";
import ModelOpen from "../../../../core/Model copy";
// import { UpdateForm } from "./Form";
import UpdateForm from "./Form/updateForm";
import { IBuyList } from "../../../../models/IOrderList";
import Tosted from "../../../../core/Tosted";
import { useEffect, useState } from "react";
import { IState } from "../../../../models/IState";

interface IModel {
  open: boolean;
  activeHandler(data: boolean): void;
  onModelHandler(): void;
  handleClose: () => void;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  data: IBuyList;
}

const Update = ({
  open,
  handleClose,
  activeHandler,
  onModelHandler,
  title,
  subTitle,
  data,
}: IModel) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };

  const { loader, tosted, message, severity } = state;

  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title={title}
        subTitle={subTitle}
        width="750px"
      >
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={2}
          sx={{ width: "100%" }}
        >
          <Typography variant="h4" textAlign={"start"} gutterBottom>
            {title}
          </Typography>
          <Typography textAlign={"start"} gutterBottom>
            {subTitle}
          </Typography>
          <Box py={2}>
            <UpdateForm data={data} state={state} setState={setState} />
          </Box>
        </Container>
      </ModelOpen>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};

export default Update;
