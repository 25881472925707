import { Stack } from "@mui/material";
import H1 from "../../core/H1";
import { ShopItemList } from "./core";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SupplierServices } from "../../services/SupplierServices";
const ShopItem = () => {
  const location = useLocation();
  console.log(location);

  const { shopNo } = useParams();
  const [data, setData] = useState({
    _id: "",
    shopName: "",
    shopNumber: "",
  });

  const getSupplierDetail = async (id: string) => {
    try {
      const response = await SupplierServices.getAllSupplierSmallApi(id);
      if (response.data) {
        setData(response.data.data);
      }
    } catch (error: any) {
      console.log("error in line no 32");
    }
  };

  useEffect(() => {
    if (shopNo) {
      getSupplierDetail(shopNo);
    }
  }, [shopNo]);

  return (
    <>
      <Stack pb={5}>
        <H1 title="Item List from Supplier" />
        <br />
        <H1 title={data.shopName} />
      </Stack>
      <ShopItemList />
    </>
  );
};
export default ShopItem;
