import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ProductServices } from "../../../../services/ProductServices";
import { IProduct } from "../../../../models/IProduct";

interface IProps {
  inputHandler: (value: IProduct | null) => void;
  isFullWidth?: boolean;
  isValid?: boolean; // This prop determines if the selected product is valid
}

const AutoInput = ({ inputHandler, isFullWidth, isValid }: IProps) => {
  const [list, setList] = useState<IProduct[]>([]);
  const [loader, setLoader] = useState(false);
  const getAllProductList = async () => {
    try {
      setLoader(true);
      const response = await ProductServices.getAllProductApi(1, 200);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.products
      ) {
        setList(response.data.data.products);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllProductList();
  }, []);

  return (
    <>
      <Autocomplete
        freeSolo
        sx={{
          width: isFullWidth ? "100%" : 300,
          "& .MuiAutocomplete-root .MuiAutocomplete-input": {
            fontWeight: 700,
          },
        }}
        disableClearable
        options={list.map((option) => option.name)}
        onChange={(event, newValue) => {
          const matchingProduct = list.find(
            (product) => product.name === newValue
          );
          inputHandler(matchingProduct ? matchingProduct : null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search a Product"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            sx={{ fontWeight: 900 }}
            // Conditionally display error state and message
            error={isValid}
            helperText={isValid ? "Select a valid product" : ""}
          />
        )}
      />
    </>
  );
};

export default AutoInput;
