import { Stack } from "@mui/material";
import H1 from "../../../../core/H1";
import { List } from "./core";

const PMSupplierShopReport = () => {
  return (
    <>
      <Stack pb={5}>
        <H1 title="Shop Report" />
      </Stack>
      <List />
    </>
  );
};
export default PMSupplierShopReport;
