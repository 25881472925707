import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { ISupplierList } from "../../../../models/Suppliers/ISupplierList";
import { IDriver } from "../../../../models/Driver/IDriver";
// ----------------------------------------------------------------------
interface IProps {
  data: IDriver;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.nameOfDriver} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.nameOfDriver}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="right"></TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
