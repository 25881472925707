import { List } from "./core";
import { Stack } from "@mui/material";
import H1 from "../../../core/H1";
import SelectDate from "../../../core/SelectDate";
import { getTodayDate } from "../../../hooks/getTodayDate";
import { useEffect, useState } from "react";

function PlaceOrder() {
  const [date, setDate] = useState<string>(getTodayDate());
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };
  return (
    <>
      <Stack mb={5}>
        <H1 title="Place Order" />
      </Stack>
      <SelectDate
        date={date}
        title="Select Date"
        subTitle="Select Date is required"
        onDateChange={onDateChange}
        open={open}
        onModelHandler={onModelHandler}
        activeHandler={activeHandler}
        handleClose={handleClose}
      />
      <List
        date={date}
        onDateChange={onDateChange}
        open={open}
        onModelHandler={onModelHandler}
        activeHandler={activeHandler}
        DateClose={handleClose}
      />
    </>
  );
}

export default PlaceOrder;
