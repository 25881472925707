import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Checkbox,
  IconButton,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { useNavigate } from "react-router-dom";
import QuantityEnter from "../../../../core/QuantityEnter";
import OptionList from "./OptionList";
import { useState } from "react";
// ----------------------------------------------------------------------
interface IData {
  id: number;
  shops: string;
  Total: number;
  return: number;
}
interface IProps {
  data: IData;
  index: number;
}

type SubmitData = {
  name: string;
  quantity: number;
};
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const navigate = useNavigate();

  const [rate, setRate] = useState("");
  const [quantity, setQuantity] = useState(data.return);

  const [quantity1, setQuantity1] = useState(0);

  const onChangeRateHandler = (event: SelectChangeEvent) => {
    setRate(event.target.value as string);
  };
  const increaseClickHandler = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler = () => {
    setQuantity((prevQuantity) =>
      quantity === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler = (value: string) => {
    if (+value > -1) {
      setQuantity((prevQuantity) => (prevQuantity = +value));
    }
  };

  const increaseClickHandler1 = () => {
    setQuantity1((prevQuantity) => prevQuantity + 1);
  };
  const decreaseClickHandler1 = () => {
    setQuantity1((prevQuantity) =>
      quantity1 === 0 ? quantity : prevQuantity - 1
    );
  };
  const InputHandler1 = (value: string) => {
    if (+value > -1) {
      setQuantity1((prevQuantity) => (prevQuantity = +value));
    }
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="center">
          <Checkbox />
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.shops} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.shops}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{data.Total}</TableCell>
        <TableCell align="center">
          <QuantityEnter
            quantity={quantity}
            increaseClickHandler={increaseClickHandler}
            decreaseClickHandler={decreaseClickHandler}
            InputHandler={InputHandler}
          />
        </TableCell>
        <TableCell align="center">{data.Total - quantity}</TableCell>
        <TableCell align="center">
          <Button variant="outlined">Update</Button>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
