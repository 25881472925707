import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddSupplier } from "../models/Suppliers/IAddSupplier";

export class SupplierServices {
  public static getAllSupplierApi(
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/supplierDetails/getSupplierDetails", {
      params: {
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }

  public static getAllSupplierSmallApi(
    id?: string,
    page?: number,
    pageSize?: number,
    search?: string,
    shopNumber?: string,
    shopName?: string
  ) {
    return InstanceAxios().get("/supplierDetails/getAllSuppliers", {
      params: {
        id: id,
        page: page,
        pagesize: pageSize,
        search: search,
        shopName: shopName,
        shopNumber: shopNumber,
      },
    });
  }

  public static addNewSupplierApi(data: IAddSupplier) {
    return InstanceAxios().post("/supplierDetails/addSupplierDetails", data);
  }
}
