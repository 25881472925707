import create from "zustand";
import { BranchService } from "../services/Branch";
import { IBranch } from "../models/Branch/IBranch";

type BranchState = {
  list: IBranch[];
  loader: boolean;
  getAllBranchList: () => Promise<void>;
};

export const useBranchListStore = create<BranchState>((set) => ({
  list: [] as IBranch[],
  loader: false,
  getAllBranchList: async () => {
    try {
      set({ loader: true });
      const response = await BranchService.getAllBranchApi();
      if (response.status === 200 && response.data && response.data.data) {
        set({ list: response.data.data });
        set({ loader: false });
      } else {
        set({ loader: false });
      }
    } catch (error) {
      console.error("Error", error);
    } finally {
      set({ loader: false });
    }
  },
}));
