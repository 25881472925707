import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Body from "../../pages/MainBody";
import useTokenStore from "../../store/token";
import { TokenService } from "../../services/authServices/TokenService";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../store/userData";
import CircularLoader from "../../core/CircularLoader";
import useIsLoaderStore from "../../store/isLoader";
import useAlertListStore from "../../store/alertList";
import { io, Socket } from "socket.io-client";
import { AlertType } from "../../assets/data/alertType";
import { useSupplierStore } from "../../store/useSupplierStore";
import { useContainerStore } from "../../store/useContainerStore";
import { useBranchListStore } from "../../store/useBranchListStore";

const ProtectedRoute: React.FC = () => {
  const { token } = useTokenStore((state) => ({
    token: state.token,
  }));

  const { data, setData } = useUserStore((state) => ({
    data: state.data,
    setData: state.setData,
  }));
  const navigate = useNavigate();

  const { loader, setLoader } = useIsLoaderStore((state) => ({
    loader: state.loader,
    setLoader: state.setLoader,
  }));
  const { list, getAllProductList } = useSupplierStore();

  const { getAllBranchList } = useBranchListStore();

  const { containerList, getAllContainerList } = useContainerStore((state) => ({
    containerList: state.list,
    getAllContainerList: state.getAllContainerList,
  }));

  const getTokenDetail = async () => {
    setLoader(true);
    try {
      const response = await TokenService.getTokenDetail();
      if (response.status === 200) {
        console.log("User Detail", response.data.data);
        setData(response.data.data);
        getAllProductList();
        getAllContainerList();
        setLoader(false);
      } else {
        navigate("/login");
        setLoader(false);
      }
    } catch (error) {
      console.error("Failed to fetch token details", error);
      navigate("/login");
      setLoader(false);
    }
  };

  const { setMessage } = useAlertListStore((state) => ({
    setMessage: state.setMessage,
  }));

  useEffect(() => {
    let socket: Socket | null = null;
    if (token) {
      socket = io(`https://www.worlderpuserapi.teksyndikate.com/`, {
        auth: { token },
        reconnectionAttempts: 5, // Try to reconnect 5 times
        reconnectionDelay: 3000, // Wait 3 seconds before reconnection attempts
      });

      socket.on(AlertType.Connected, () => {
        console.log("Connected to socket server");
      });

      socket.on(
        AlertType.RequestUpdated ||
          AlertType.RequestRaised ||
          AlertType.OrderUpdated ||
          AlertType.OrderPlaced ||
          AlertType.OrderDeleted ||
          AlertType.OrderReceived ||
          AlertType.RequestItemDeleted,
        (message) => {
          console.log("Message", message);
          setMessage(message);
        }
      );

      socket.on(AlertType.conn_Error, (err) => {
        console.error("Connection Error", err.message);
      });
    }

    return () => {
      if (socket) {
        console.log("Disconnecting socket");
        socket.disconnect();
      }
    };
  }, [token, setMessage]);

  // Additional useEffect to react to token changes
  useEffect(() => {
    if (token) {
      getTokenDetail();
      getAllProductList();
      getAllBranchList();
    }
  }, [token]);

  useEffect(() => {
    getTokenDetail();
  }, []);

  if (loader) {
    return <CircularLoader />; // Show loading state while checking user role
  }

  return data?._id !== "" ? (
    <Body>
      <Outlet />
    </Body>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default ProtectedRoute;
