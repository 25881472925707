import { sample } from "lodash";
import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Shop Name",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "Address",
    isMove: false,
    align: "center",
  },

  {
    id: 4,
    name: "Telephone",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Mobile No",
    isMove: false,
    align: "center",
  },
  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const mobileList: ITHead[] = [
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },

  {
    id: 4,
    name: "Quantity",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "UOM",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];
