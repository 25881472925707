import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import { TableCellProps } from "@mui/material";

interface ITableHeading {
  id: number;
  name: string;
  isMove: boolean;
  align: TableCellProps["align"];
}
export const THeading: ITableHeading[] = [
  {
    id: 1,
    name: "shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "No of Items",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const TList: ITableHeading[] = [
  {
    id: 1,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Amount",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "Type",
    isMove: true,
    align: "center",
  },
  {
    id: 4,
    name: "Status",
    isMove: true,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const isMobileHeading: ITableHeading[] = [
  {
    id: 1,
    name: "shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "No of Items",
    isMove: false,
    align: "center",
  },

  {
    id: 3,
    name: "",
    isMove: true,
    align: "right",
  },
];
