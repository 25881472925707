import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Button,
} from "@mui/material";

import Iconify from "../../../core/Iconify";
import { Link, useNavigate } from "react-router-dom";
import { IShopOrder } from "../../../models/IShopOrder";
import { useState } from "react";
import { IBuyList } from "../../../models/IOrderList";
import Update from "./Update";
import { IPROrder } from "../../../models/IPROrder";

// ----------------------------------------------------------------------
interface IAllocation {
  item: string[];
  shopNo: number;
  weight: string;
}
interface IProps {
  data: IPROrder;
  index: number;
}

// ----------------------------------------------------------------------
const TItem = ({ data, index }: IProps) => {
  const navigate = useNavigate();
  const navigateHanlder = () => {
    // navigate(`/procurement/supplier-list/${data.shopNo}`);
  };

  const [oModel, setOModel] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOModel(!oModel);
  };
  const hCloseModel = () => {
    setOModel(false);
  };
  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  const datas: IBuyList = {
    _id: "65c90bd38e5090cb14b2d9f3",
    totalRequiredQuantity: 142,
    UOM: "KG",
    totalOrderedQuantity: 0,
    totalOrderedPCS: 0,
    totalPSCToPurchase: 0,
    productDetails: {
      _id: "65c90bd38e5090cb14b2d9f3",
      name: "Adrak Banglore आद्रक बॅंगलॉर",
      category: "65c76941e992a8a7b0f9374b",
    },
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{ cursor: "pointer", zIndex: 100 }}
        onClick={navigateHanlder}
      >
        <TableCell component="th" scope="row" padding="none">
          <Typography variant="subtitle2" pl={1} noWrap>
            {data.product.name}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {data.totalPCSPurchased}/{data.totalPSCToPurchase}
        </TableCell>
        <TableCell align="center">
          {data.totalQuantityPurchased}/{data.totalRequiredQuantity}
        </TableCell>
        <TableCell align="right">
          <Button
            variant="outlined"
            sx={{ height: "40px" }}
            onClick={onModelHandler}
          >
            {" "}
            {`Add Qty`}{" "}
          </Button>
        </TableCell>
      </TableRow>
      <Update
        open={oModel}
        handleClose={hCloseModel}
        activeHandler={activeHandler}
        onModelHandler={onModelHandler}
        title="Update An Order"
        subTitle="Please fill the fields "
        data={data}
      />
    </>
  );
};
export default TItem;
