import { List } from "./core";
import H1 from "../../../core/H1";
import { Stack } from "@mui/material";

function PurchaseReport() {
  return (
    <>
      <Stack pt={5} pb={5}>
        <H1 title="Purchase Report" />
      </Stack>

      <List />
    </>
  );
}

export default PurchaseReport;
