import { useEffect, useState } from "react";
import {
  Paper,
  SelectChangeEvent,
  TableBody,
  TablePagination,
} from "@mui/material";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import THeader from "./THeader";
import TRow from "./TRow";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import { AllList, MobileList } from "../data";
import { BranchService } from "../../../../services/Branch";
import { IReceivedOrder } from "../../../../models/Branch/IReceivedOrder";
import THeaderView from "../../../../core/THeaderView";
import MTable from "../../../../core/MTable";
import NoData from "../../../../core/NoData";

const List = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [date, setDate] = useState<string>(getTodayDate());

  // Event handler for date change
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const [list, setList] = useState<IReceivedOrder[]>([] as IReceivedOrder[]);

  const getOrderList = async () => {
    setList([]);
    try {
      setState({ ...state, loader: true });
      const response = await BranchService.getReceivedOrderDetailsByDateApi(
        date
      );
      if (response.data && response.data.data) {
        setList(response.data.data);
        setState({ ...state, loader: false });
      } else {
        setList([]);
        setState({
          loader: false,
          tosted: true,
          message: "Somthing Went Wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
            date={date}
            onDateChange={onDateChange}
          />

          <MTable>
            <THeaderView AllList={AllList} MobileList={MobileList} />

            <TableBody>
              {list?.map((data, index) => {
                return <TRow data={data} index={index} />;
              })}
            </TableBody>
          </MTable>

          {!loader && list && list.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+list.length}
            page={1}
            onPageChange={() => console.log("call")}
            rowsPerPage={+list.length}
            // onRowsPerPageChange={() => console.log("call")}
            // rowsPerPageOptions={() => console.log("call")}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default List;
