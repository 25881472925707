import { Container, Typography, Paper, Box, Button } from "@mui/material";
import ModelOpen from "../../../core/Model copy";
import AllBranch from "../../../core/AllBranch";
import { IBranch } from "../../../models/Branch/IBranch";

interface IModel {
  open: boolean;
  onModelHandler(): void;
  handleClose: () => void;
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  selectBranch: IBranch[];
  setSelectBranch: (data: IBranch[]) => void;
}

const AddBranch = ({
  open,
  handleClose,
  onModelHandler,
  title,
  subTitle,
  selectBranch,
  setSelectBranch,
}: IModel) => {
  // const [selectBranch, setSelectBranch] = useState<IBranch[]>([] as IBranch[]);
  return (
    <ModelOpen
      open={open}
      handleClose={handleClose}
      onModelHandler={onModelHandler}
      title={title}
      subTitle={subTitle}
    >
      <Container
        maxWidth="lg"
        component={Paper}
        elevation={2}
        sx={{ width: "100%" }}
      >
        <Typography variant="h4" textAlign={"start"} gutterBottom>
          {title}
        </Typography>
        <Typography textAlign={"start"} gutterBottom>
          {subTitle}
        </Typography>
        <Box py={2}>
          <AllBranch
            selectBranch={selectBranch}
            setSelectBranch={setSelectBranch}
          />
          <Box py={2}>
            <Button variant="contained" fullWidth>
              Update Now
            </Button>
          </Box>
        </Box>
      </Container>
    </ModelOpen>
  );
};

export default AddBranch;
