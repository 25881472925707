import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddAllocate } from "../models/Allocate/IAddAllocate";

export class AllocateService {
  public static getOrdersByDateAndProductName(
    date?: string,
    branches?: string
  ) {
    return InstanceAxios().get("/allocation/getOrdersByDateAndProductName", {
      params: {
        processDate: date,
        branches: branches,
      },
    });
  }

  public static addorderallocation(data: IAddAllocate) {
    return InstanceAxios().post("/allocation/addorderallocation", data);
  }
}
