import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Input,
} from "@mui/material";
import ModelOpen from "../../../../../core/Model copy"; // Adjust import based on your project structure
import { IAmountList } from "../../../../../models/IAmountList"; // Adjust import based on your project structure
import DateFormatter from "../../../../../core/DateFormatter"; // Adjust import based on your project structure
import Tosted from "../../../../../core/Tosted"; // Adjust import based on your project structure
import { LedgerService } from "../../../../../services/supplierLedger/Ledger";
import { Label } from "../../../../../core/Label";
import { IAddPayment, DataEntity } from "../../../../../models/IAddPayment";
import { IState } from "../../../../../models/IState";
import { NumberDisplay } from "../../../../../hooks/numberDisplay";
import FileUpload from "../../../../../core/FileUpload";
// Placeholder for LedgerService.getLedgerReportByDate - replace with actual import
// Placeholder for API service call - replace with actual import

interface IPaymentModel {
  open: boolean;
  handleClose: () => void;
  onModelHandler: () => void;
  title?: string;
  subTitle?: string;
  id: string;
}

interface ISelectedPayment {
  processDate: string;
  paymentAmount: number;
}

const PaymentModel: React.FC<IPaymentModel> = ({
  open,
  handleClose,
  onModelHandler,
  title,
  subTitle,
  id,
}) => {
  const [oList, setOList] = useState<IAmountList[]>([]);
  // const [selected, setSelected] = useState<string[]>([]);
  const handleFileChange = (file: File) => {
    // Handle the uploaded file here (e.g., send to server, display preview)
    console.log("File uploaded:", file);
  };
  const [selected, setSelected] = useState<ISelectedPayment[]>([]);
  const [payment, setPayment] = useState(0);

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [netPending, setNetPending] = useState(0);
  const [netAdvance, setNetAdvance] = useState(0);

  const [pType, setPType] = useState("Bank Transfer");
  const list = ["Bank Transfer", "Cash"];
  const [valid, setValid] = useState({
    isValid: false,
    message: "",
  });

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setPType(newValue); // Assuming 'newValue' is compatible with 'pType' state's type

    // Optionally, validate the selection and update 'valid' state as necessary
    if (!newValue) {
      setValid({
        isValid: false,
        message: "Select a payment type",
      });
    } else {
      setValid({
        isValid: true,
        message: "",
      });
    }
  };

  const getOutStandingApi = async () => {
    setIsLoading(true);
    try {
      const response = await LedgerService.getLedgerReportByDate(id, true);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data.ledgerEntries
      ) {
        setOList(response.data.data.ledgerEntries);
        setNetAdvance(response.data.data.netAdvance);
        setNetPending(response.data.data.netOutstanding);
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getOutStandingApi();
    }
  }, [id]);

  const handleSelect = (rowData: {
    processDate: string;
    paymentAmount: number;
  }) => {
    const selectedIndex = selected.findIndex(
      (item) => item.processDate === rowData.processDate
    );
    let newSelected: ISelectedPayment[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, rowData];
    } else {
      newSelected = selected.filter(
        (item) => item.processDate !== rowData.processDate
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = () => {
    if (selected.length === oList.length) {
      setSelected([]);
    } else {
      setSelected(
        oList.map((item) => ({
          processDate: item.processDate,
          paymentAmount: item.billAmount,
        }))
      );
    }
  };

  useEffect(() => {
    console.log(selected);
    const totalPaymentAmount = selected.reduce(
      (acc, payment) => acc + payment.paymentAmount,
      0
    );
    console.log("paymentAmount", totalPaymentAmount);
    setPayment(totalPaymentAmount);
  }, [selected]);

  const handleMakePayment = async () => {
    console.log("Data", selected);
    setState({ ...state, loader: true });
    console.log(pType);

    const paymentList: DataEntity[] = selected.map((payment) => ({
      ...payment,
      paymentChannel: pType,
    }));

    const data: IAddPayment = {
      supplierId: id,
      data: paymentList,
    };

    console.log("Line No 172", data);

    try {
      const response = await LedgerService.createpaymentApi(data);
      if (response.status === 200 && response.data) {
        console.log(response.data);
        setState({
          message: "Payment Update",
          severity: "success",
          loader: true,
          tosted: true,
        });
      } else {
        setState({
          message: "Something went wrong",
          severity: "error",
          loader: true,
          tosted: true,
        });
      }
    } catch (error) {
      setState({
        message: "Error fetching data:",
        severity: "error",
        loader: true,
        tosted: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { loader, message, severity, tosted } = state;
  const TClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  };

  useEffect(() => {
    TClose();
  }, [tosted]);

  return (
    <>
      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title={title}
        subTitle={subTitle}
        width="600px"
      >
        <Container maxWidth="lg" component={Paper} elevation={2}>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h4" textAlign="start" gutterBottom>
                {title}
              </Typography>
              <Typography textAlign="start" gutterBottom>
                {subTitle}
              </Typography>
              <TableContainer sx={{ py: 1 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < oList.length
                          }
                          checked={
                            oList.length > 0 && selected.length === oList.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Total (₹)</TableCell>
                      <TableCell>Outstanding (₹)</TableCell>
                      <TableCell>Advance (₹)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {oList.map((data, index) => (
                      <TableRow key={index} hover>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selected.some(
                              (item) => item.processDate === data.processDate
                            )}
                            onChange={() =>
                              handleSelect({
                                processDate: data.processDate,
                                paymentAmount: data.billAmount,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <DateFormatter dateString={data.processDate} />
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.billAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.outstandingAmount)}
                        </TableCell>
                        <TableCell align="center">
                          {NumberDisplay(data.advanceAmount)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow hover>
                      <TableCell align="center" colSpan={3}>
                        Amount
                      </TableCell>
                      <TableCell align="center">
                        <Label color={"error"}>
                          {NumberDisplay(netPending)}
                        </Label>{" "}
                      </TableCell>
                      <TableCell align="center">
                        <Label color={"success"}>
                          {NumberDisplay(netAdvance)}
                        </Label>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <Box>
                  <Typography gutterBottom>
                    Payment Amount : <b>{payment}</b>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      value={pType}
                      onChange={handleChange} // Updated to use the corrected handleChange function
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {list.map((item, index) => (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"12px"}
                        p={0}
                        m={0}
                        color="error"
                      >
                        {valid.message}
                      </Typography>
                    </FormHelperText>
                  </FormControl>

                  {/* <FormControl fullWidth>
                    <Input type="file" />
                  </FormControl> */}
                  <br />

                  <FileUpload
                    onChange={handleFileChange}
                    accept=".jpg,.png"
                    multiple
                  />

                  <Box textAlign={"center"} pt={2}>
                    <Button
                      fullWidth
                      sx={{ height: "40px" }}
                      variant="contained"
                      onClick={handleMakePayment}
                    >
                      {state.loader ? <CircularProgress /> : "Make Payment"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Container>
      </ModelOpen>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={TClose}
      />
    </>
  );
};

export default PaymentModel;
