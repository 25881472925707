import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Iconify from "../../../../core/Iconify";
import { IContainer } from "../../../../models/IContainer";

// ----------------------------------------------------------------------
interface IProps {
  data: IContainer;
  index: number;
}
// ----------------------------------------------------------------------

const TRow = ({ data, index }: IProps) => {
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left" sx={{ pl: 2 }}>
          {data.containerName}
        </TableCell>

        <TableCell align="right">{data.containerWeight}</TableCell>
        <TableCell align="right">
          <IconButton color="error">
            <Iconify icon="fluent:delete-12-regular" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
