import { InstanceAxios } from "../hooks/axiosConfig";
import { IMajDuriAdd } from "../models/IMajDuriAdd";

export class MajDuriService {
  public static getMajduriPayments(
    date: string,
    page?: number,
    pageSize?: number,
    category?: string,
    search?: string
  ) {
    return InstanceAxios().get("/majduriLedger/fetchMajduriPayments", {
      params: {
        page: page,
        pagesize: pageSize,
        category: category,
        search: search,
        processDate: date,
      },
    });
  }

  public static addMajdurPayment(data: IMajDuriAdd) {
    return InstanceAxios().post("/majduriLedger/addMajduriPaymentEntry", data);
  }
}
