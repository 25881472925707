import { Box, FormControlLabel, Typography, Divider } from "@mui/material";
import Links from "@mui/material/Link";
import ButtonUI from "../../core/Button";
import Tosted from "../../core/Tosted";
import { Fragment, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services/authServices/LoginService";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import Cookies from "universal-cookie";
import { OutlineInput } from "../../core/InputField";
import useTokenStore from "../../store/token";
import { IState } from "../../models/IState";
import useUserStore from "../../store/userData";

export default function UPasswordForm() {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  let navigate = useNavigate();
  let netWorkStatus = useNetworkStatus();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState({
    isValid: false,
    message: "fill the password",
  });
  const [nPasswordValid, setNPasswordValid] = useState({
    isValid: false,
    message: "fill the password",
  });
  const [togglePassword, setTogglePassword] = useState(false);

  const [toggleNPassword, setToggleNPassword] = useState(false);

  const cookies = new Cookies();
  const { setToken } = useTokenStore((state) => ({
    setToken: state.setToken,
  }));
  const { setData } = useUserStore((state) => ({
    setData: state.setData,
  }));

  const passwordHandlerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: boolean
  ) => {
    if (key) {
      setPassword(event.target.value);
      event.target.value.length < 8
        ? setPasswordValid({
            isValid: true,
            message: "Fill the password",
          })
        : setPasswordValid({
            isValid: false,
            message: "Fill the password",
          });
    } else {
      setNewPassword(event.target.value);
      event.target.value.length < 8
        ? setNPasswordValid({
            isValid: true,
            message: "Fill the password",
          })
        : setNPasswordValid({
            isValid: false,
            message: "Fill the password",
          });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const isPasswordValid = password.length >= 8;
    const isNPasswordValid = newPassword.length >= 8;

    setPasswordValid({
      isValid: !isPasswordValid,
      message: isPasswordValid
        ? ""
        : "Password must be at least 8 characters long",
    });

    setNPasswordValid({
      isValid: !isNPasswordValid,
      message: isNPasswordValid
        ? ""
        : "Password must be at least 8 characters long",
    });

    onSubmit({ password, newPassword });
  };

  const onSubmit = (data: { password: string; newPassword: string }) => {
    setState({
      ...state,
      loader: true,
    });
    if (!netWorkStatus) {
      setState({
        tosted: true,
        loader: false,
        severity: "error",
        message: "No internet connection",
      });
    } else {
      const response = LoginService.changePasswordApi(
        data.password,
        data.newPassword
      );
      response
        .then((res) => {
          setState({
            tosted: true,
            loader: false,
            severity: "success",
            message: res.data.message,
          });
        })
        .catch((error) => {
          if (error.code == "ERR_NETWORK") {
            setState({
              tosted: true,
              loader: false,
              severity: "error",
              message: "No internet connection",
            });
          } else {
            setState({
              tosted: true,
              loader: false,
              severity: "error",
              message: error.response.data.message,
            });
          }
        });
    }
  };

  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    handleClose();
  }, [tosted]);

  const handleTogglePassword = (isOld: boolean) => {
    if (isOld) {
      setTogglePassword(!togglePassword);
    } else {
      setToggleNPassword(!toggleNPassword);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <Fragment>
      <Box width={"100%"}>
        <form onSubmit={handleSubmit} className="">
          <OutlineInput
            togglePassword={togglePassword}
            setTogglePassword={setTogglePassword}
            value={password}
            type="password"
            label="Old Password "
            handleInputChange={(e) => passwordHandlerChange(e, true)}
            handleTogglePassword={() => handleTogglePassword(true)}
            error={passwordValid.isValid ? passwordValid.message : undefined}
            disabled={loader}
          />
          <OutlineInput
            togglePassword={toggleNPassword}
            setTogglePassword={setToggleNPassword}
            value={newPassword}
            type="password"
            label="New Password "
            handleInputChange={(e) => passwordHandlerChange(e, false)}
            handleTogglePassword={() => handleTogglePassword(false)}
            error={nPasswordValid.isValid ? nPasswordValid.message : undefined}
            disabled={loader}
          />

          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  inputProps={{ "aria-label": "remember me" }}
                />
              }
              label="Remember Me"
            />
            <Link to={"/auth/user/forget"}>
              <Typography component={Links}>Forgot Password</Typography>
            </Link>
          </Box>
          <Box textAlign={"center"} pt={1}>
            <ButtonUI
              type="submit"
              variant="contained"
              color="primary"
              disabled={loader}
              loader={loader}
            >
              Continue
            </ButtonUI>
          </Box>
        </form>
      </Box>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </Fragment>
  );
}
