import React from "react";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import Password from "../Password";

interface IProps {
  value: string | number;
  togglePassword?: boolean;
  setTogglePassword?: (togglePassword: boolean) => void;
  type: "number" | "password" | "text";
  label: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  multiline?: boolean;
  disabled?: boolean;
  handleTogglePassword?: () => void;
  placeholder?: string;
}

const BasicInput: React.FC<IProps> = ({
  value,
  togglePassword,
  setTogglePassword,
  type,
  label,
  handleInputChange,
  error,
  multiline,
  disabled,
  handleTogglePassword,
  placeholder,
}) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={"5px"} height={"100%"}>
      <Typography fontSize={"14px"} fontWeight={"600"}>
        {placeholder}
      </Typography>
      <TextField
        fullWidth
        disabled={disabled}
        sx={{
          width: "60px",
          p: 0,
          borderRadius: "8px",
          "&.MuiOutlinedInput-root": {
            borderRadius: 0,
          },
        }}
        size="small"
        name={label}
        type={
          type === "password" ? (togglePassword ? "text" : "password") : type
        }
        variant="outlined"
        placeholder={""}
        value={type === "number" && value === 0 ? "" : value}
        onChange={handleInputChange}
        margin="dense"
        label={""}
        inputProps={{ style: { fontSize: 14 } }} // font size of input text
        InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
        color="warning"
        rows={multiline ? "2" : undefined}
        multiline={multiline}
        helperText={
          <Typography
            variant="h6"
            fontWeight={600}
            fontSize={"12px"}
            p={0}
            m={0}
            color="error"
          >
            {error}
          </Typography>
        }
      />
    </Stack>
  );
};

export default BasicInput;
