import React from "react";
import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Input from "../../../allocationOrder/core/QNInput";
import {
  IGetReceivedOrder,
  ProductsEntity,
} from "../../../../models/IGetReceivedOrder";

interface IProps {
  data: IGetReceivedOrder;
}

const TRow: React.FC<IProps> = ({ data }) => {
  console.log("Row Data", data);

  // Define a type for the renderProductRow function parameters
  const renderProductRow = (
    product: ProductsEntity,
    index: string,
    isAlternate: boolean
  ) => (
    <TableRow
      hover
      role="checkbox"
      key={index}
      sx={{
        cursor: "pointer",
        background: (theme) =>
          isAlternate
            ? theme.palette.background.default
            : theme.palette.background.paper,
      }}
    >
      <TableCell align="left">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar alt={product.productId.name} src={``} />
          <Typography variant="subtitle2" noWrap>
            {product.productId.name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="center">
        <Box
          width={"100%"}
          justifyContent={"center"}
          display={"flex"}
          gap={"20px"}
        >
          <Input
            quantity={product.totalPcsToWarehouse || 0}
            placeholder="Nug"
          />
          <Input
            quantity={product.totalWeightToWarehouse || 0}
            placeholder="(Kg)"
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box
          width={"100%"}
          justifyContent={"left"}
          display={"flex"}
          gap={"20px"}
        >
          <Input quantity={product.totalPcsToRacks || 0} placeholder="Nug" />
          <Input
            quantity={product.totalWeightToRacks || 0}
            placeholder="(Kg)"
          />
        </Box>
      </TableCell>
      <TableCell align="right">
        <Button variant="contained">Update</Button>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {data.arrivals?.map(
        (arrival, arrivalIndex) =>
          arrival.products?.map((product, productIndex) =>
            renderProductRow(
              product,
              `${arrivalIndex}-${productIndex}`,
              (arrivalIndex + productIndex) % 2 === 1
            )
          ) || []
      ) || []}
    </>
  );
};

export default TRow;
