import { useEffect, useState } from "react";
import {
  Button,
  Paper,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";
import THeader from "./THeader";
import MTable from "../../../../core/MTable";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import { NumberDisplay } from "../../../../hooks/numberDisplay";
import TItemRow from "./TItemRow";
import { IShopOrder } from "../../../../models/IShopOrder";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import { Link, useParams } from "react-router-dom";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import NoData from "../../../../core/NoData";
import PaymentModel from "./PaymentModel";
import THeaderView from "../../../../core/THeaderView";
import { AllItemList } from "../data";
import { useSupplierStore } from "../../../../store/useSupplierStore";
import useQuery from "../../../../hooks/useQuery";

const ItemList = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const query = useQuery();
  const selectDate = query.get("selectdate");

  const { supplierList } = useSupplierStore((state) => ({
    supplierList: state.list,
  }));

  const [date, setDate] = useState<string>(
    selectDate ? selectDate : getTodayDate()
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [category, setCategory] = useState("");
  let { id } = useParams();
  const [sellerTotalAmount, setSellerTotalAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleCloseModel = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [list, setList] = useState<IShopOrder[] | undefined>(undefined);

  const getAllList = async () => {
    const shopNumber = id ? id : "1";

    console.log("Line No 69", supplierList);

    const foundShop = supplierList?.find((shop) => shop._id === shopNumber);

    console.log(foundShop);

    try {
      const response = await PurchaseServie.getDetailsByShopNumber(
        shopNumber,
        date
      );
      if (
        response.data.status === 200 &&
        response.data.data &&
        response.data.data.details &&
        response.data.data.details.length > 0
      ) {
        setList(response.data.data.details);
        setActive(false);
        setSellerTotalAmount(response.data.data.sellerTotalAmount);
        setState({ ...state, loader: false });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
        setList([]);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
      setList([]);
    }
  };

  const categoryHandler = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const searchInputHandler = (value: string) => {
    setSearchInput(value);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getAllList();
  }, []);

  useEffect(() => {
    if (active) {
      getAllList();
    }
  }, [active]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const activeHandlerApi = (active: boolean) => {
    setActive(active);
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    console.log("Date Handler", event.target.value);
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%" }} elevation={3}>
          <THeader
            searchInputHandler={searchInputHandler}
            searchInput={searchInput}
            category={category}
            categoryHandler={categoryHandler}
            date={date}
            onDateChange={onDateChange}
          />
          <MTable>
            <THeaderView MobileList={AllItemList} AllList={AllItemList} />

            <TableBody>
              {list?.map((data, index) => {
                return (
                  <TItemRow
                    data={data}
                    index={index}
                    key={index}
                    activeHandler={activeHandlerApi}
                    date={date}
                  />
                );
              })}
              <TableRow>
                <TableCell colSpan={10}></TableCell>
                <TableCell colSpan={1} align="center">
                  <b> Net Total</b>
                </TableCell>
                <TableCell colSpan={1} align="center">
                  <b> {list && NumberDisplay(sellerTotalAmount)} </b>
                </TableCell>
                <TableCell colSpan={1} align="right">
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={onModelHandler}
                  >
                    Payment
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </MTable>

          {!loader && list && list.length === 0 && <NoData />}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />

      {open && (
        <PaymentModel
          open={open}
          // activeHandler={activeHandler}
          handleClose={handleCloseModel}
          onModelHandler={onModelHandler}
          title="Update Payment"
          subTitle="Here you can update an payment"
          id={id ? id : ""}
        />
      )}
    </>
  );
};
export default ItemList;
