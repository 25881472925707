import { Grid, Stack } from "@mui/material";
import ShopTable from "./ShopTable";
import { useEffect, useState } from "react";
import { SellerDetailEntity } from "../../models/IItemList";
import { PurchaseServie } from "../../services/PurchaseServices";
import { IState } from "../../models/IState";
import { useParams } from "react-router-dom";

const UpdateOrder = () => {
  const [orderList, setOrderList] = useState<SellerDetailEntity[]>(
    [] as SellerDetailEntity[]
  );
  const { id } = useParams();

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const getProductSellerDetail = async (id: string) => {
    try {
      const response = await PurchaseServie.viewSellerDetails(id);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data.sellerDetails
      ) {
        setState({ ...state, loader: false });
        console.log(response.data);
        setOrderList(response.data.data.sellerDetails);
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getProductSellerDetail(id);
    }
  }, [id]);

  return (
    <Stack height={"100vh"}>
      <Grid container>
        <Grid item md={6} sm={12}>
          {/* <UpdateForm data={} /> */}
        </Grid>
        <Grid item md={6} sm={12}>
          {orderList && orderList.length > 0 && <ShopTable data={orderList} />}
        </Grid>
      </Grid>
    </Stack>
  );
};
export default UpdateOrder;
