import { Stack } from "@mui/material";
import H1 from "../../../core/H1";
import { ItemList } from "./core";

const PamymentList = () => {
  return (
    <>
      <Stack pb={5}>
        <H1 title="All Payment" />
      </Stack>
      <ItemList />
    </>
  );
};
export default PamymentList;
