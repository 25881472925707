import { List } from "./core";
import { Stack } from "@mui/material";
import H1 from "../../../core/H1";

function Driver() {
  return (
    <>
      <Stack py={3}>
        <H1 title="Driver List" />
      </Stack>
      <List />
    </>
  );
}

export default Driver;
