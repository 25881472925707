import {
  TableHead,
  TableRow,
  TableCell,
  SelectChangeEvent,
} from "@mui/material";
import useMobile from "../../../hooks/useMobile";
import NWeight from "./NWeight";
import { IBranch } from "../../../models/Branch/IBranch";
import { ITHead } from "../../../models/Common/ITHead";
import AllDriver from "../../../core/AllDriver";
// ----------------------------------------------------------------------
interface IDriver {
  branchId: string;
  driverId: string;
  branchIdValid: {
    message: string;
    isValid: boolean;
  };
  driverIdValid: {
    message: string;
    isValid: boolean;
  };
}
interface IProps {
  branchList: IBranch[];
  driverId: IDriver[];
  driverChangeHandler: (branchId: string, event: SelectChangeEvent) => void;
}
// ----------------------------------------------------------------------

const THead = ({ branchList, driverId, driverChangeHandler }: IProps) => {
  const isMobile = useMobile();

  const AllList: ITHead[] = [
    {
      id: 2,
      name: "",
      isMove: false,
      align: "left",
    },
    {
      id: 1,
      name: "",
      isMove: false,
      align: "left",
    },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {/* Correctly handle conditional rendering for mobile/desktop views */}
          {isMobile ? (
            <>
              {AllList.map((data, index) => (
                <TableCell key={index} align={data.align}>
                  {data.name}
                </TableCell>
              ))}
              {branchList.map((data, index) => (
                <TableCell key={`branch-${index}`} align="center">
                  {data.area}
                </TableCell>
              ))}
              <TableCell align="right"></TableCell>
            </>
          ) : (
            <>
              {AllList.map((data, index) => (
                <TableCell key={index} align={data.align}>
                  {data.name}
                </TableCell>
              ))}
              {branchList.map((data, index) => (
                <TableCell key={`branch-${index}`} align="center">
                  {data.area}
                </TableCell>
              ))}
              <TableCell align="right"></TableCell>
            </>
          )}
        </TableRow>
        <TableRow>
          <TableCell align={"left"}>Select Driver</TableCell>
          <TableCell align={"left"}></TableCell>

          {driverId.map((data, index) => {
            return (
              <TableCell align={"left"}>
                <AllDriver
                  value={data.driverId}
                  handleChange={(event) =>
                    driverChangeHandler(data.branchId, event)
                  }
                  branchIdValid={data.driverIdValid}
                />
              </TableCell>
            );
          })}
          <TableCell align={"center"}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell align={"left"}>Item Name</TableCell>
          <TableCell align={"left"}>Shop No</TableCell>

          {branchList.map((data, index) => {
            return <NWeight />;
          })}
          <TableCell align={"center"}></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};
export default THead;
