import create from "zustand";
import { IAlertUpdate } from "../models/IAlertUpdate";

type Store = {
  message: IAlertUpdate[];
  setMessage: (message?: IAlertUpdate) => void; // Optional parameter to allow clearing the data
};

const useAlertListStore = create<Store>((set) => ({
  message: [],
  setMessage(message?: IAlertUpdate) {
    set((state) => ({
      message: message ? [...state.message, message] : [], // Add data if provided, otherwise reset to an empty array
    }));
  },
}));

export default useAlertListStore;
