import { TableRow, TableCell, Button } from "@mui/material";
import { IMajdur } from "../../../../models/IMajdur";
import { useState } from "react";
import Add from "./Add";
import { da } from "@faker-js/faker";
// ----------------------------------------------------------------------
interface IProps {
  data: IMajdur;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">{data.supplierInfo.shopNumber}</TableCell>
        <TableCell align="left">{data.supplierInfo.shopName}</TableCell>
        <TableCell align="center">{data.totalPCSPurchased}</TableCell>
        <TableCell align="center">{data.totalMajduriAmount}</TableCell>
        <TableCell align="center">{data.outstandingAmount}</TableCell>
        <TableCell align="center">{data.advanceAmount}</TableCell>
        <TableCell align="center">{data.paymentAmount}</TableCell>

        <TableCell align="center">
          <Button color={"primary"} onClick={onModelHandler} variant="outlined">
            Pay Now
          </Button>
        </TableCell>
      </TableRow>
      <Add
        model={{
          open: open,
          activeHandler: activeHandler,
          handleClose: handleClose,
          onModelHandler: onModelHandler,
          title: "Pay Majduri",
        }}
        data={data}
      />
    </>
  );
};
export default TRow;
