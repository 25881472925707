import { TableRow, TableCell, Stack, Avatar, Typography } from "@mui/material";
import { IPurchaseReport } from "../../../../models/Branch/IPurchaseReport";
import { useSupplierStore } from "../../../../store/useSupplierStore";
// ----------------------------------------------------------------------
interface IProps {
  data: IPurchaseReport;
  index: number;
}
// ----------------------------------------------------------------------
const TRow = ({ data, index }: IProps) => {
  const { list } = useSupplierStore();

  const getShopDetail = () => {
    return list?.find((shop) => shop._id === data.supplierId);
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        sx={{
          cursor: "pointer",
          background: (theme) =>
            index % 2 === 1
              ? theme.palette.background.default
              : theme.palette.background.paper,
        }}
      >
        <TableCell align="left">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={data.productName} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.productName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">{getShopDetail()?.shopNumber}</TableCell>
        <TableCell align="center">{data.totalPcs}</TableCell>
        <TableCell align="center">{data.totalWeight}</TableCell>
        <TableCell align="center">{data.rate}</TableCell>
        <TableCell align="center">{data.sellingPrice}</TableCell>
        <TableCell align="center">{data.percentage}%</TableCell>
        <TableCell align="center">{data.finalRate}</TableCell>
        <TableCell align="center">{data.totalAmount}</TableCell>
      </TableRow>
    </>
  );
};
export default TRow;
