import React from "react";
import { format, parseISO } from "date-fns";

interface DateFormatterProps {
  dateString: string;
}

const DateFormatter: React.FC<DateFormatterProps> = ({ dateString }) => {
  const date = parseISO(dateString);
  const formattedDate = format(date, "MMMM dd, yyyy p");

  return <span>{formattedDate}</span>;
};

export default DateFormatter;
