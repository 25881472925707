import { TableRow, TableCell, Stack, Skeleton } from "@mui/material";
import CenterBox from "../../../../core/CenterBox";
// ----------------------------------------------------------------------

const Loader = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((data, index) => {
        return (
          <TableRow
            hover
            role="checkbox"
            key={index}
            sx={{ cursor: "pointer" }}
          >
            <TableCell component="th" scope="row" padding="none">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", width: "130px" }}
                />
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "100px" }}
              />
            </TableCell>
            <TableCell align="center">
              <CenterBox height="100%">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.5rem", width: "30px" }}
                />
              </CenterBox>
            </TableCell>

            <TableCell align="right">
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.5rem", width: "80px", float: "right" }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
export default Loader;
