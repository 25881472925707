import React from "react";
import { IProductBanner } from "../../../../../models/IProductBanner";

const ProductBanner: React.FC<{ data: IProductBanner }> = ({ data }) => {
  const { type, price, name, hindiName } = data;

  const styles = `
    .price-container {
      width: 29.7cm;
      height: 20.7cm;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      position: relative;
      margin:0,
      padding:0,
              box-sizing: border-box;
    }

    .wq-logo {
      position: absolute;
      height: 3cm;
      width: 3cm;
      top: 0.5cm;
      left: 0.5cm;
    }

    .price-card {
      text-align: center;
    }

    .price-value {
      font-size: 11cm;
      font-weight: bold;
      margin-top: -3cm;
    }

    .hr {
      margin-top: -2cm;
      color: #000;
    }

    .our-price {
      font-size: 2cm;
      font-weight: bold;
      padding-bottom:20px
    }

    .currency-symbol {
      font-size: 2cm;
      vertical-align: super;
    }
    .currency-symbol-sup {
      font-size: 2cm;
      margin-top: -2cm;
    }

    .price-description {
      font-size: 2cm;
      font-weight: 700;
    }

    .item-name {
      margin-bottom: 0.2cm;
    }

    .item-name-hindi {
      font-size: 1.5cm;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="price-container">
        <div className="price-card">
          <div className="our-price">Our Price</div>
          <div className="price-value">
            <span className="currency-symbol">₹</span>
            {price}
            <span className="currency-symbol-sup">
              /{type === "KG" ? "Kg" : type}
            </span>
          </div>

          <hr className="hr" />

          <div className="price-description">
            <div className="item-name">{name}</div>
            <div className="item-name-hindi">{hindiName}</div>
          </div>
        </div>

        <div className="wq-logo">
          <img src="/logo.png" height="100%" width="100%" alt="Logo" />
        </div>
      </div>
    </>
  );
};

export default ProductBanner;
