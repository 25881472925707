import { List } from "./core";
import H1 from "../../../core/H1";
import { Stack } from "@mui/material";

function CheckOrder() {
  return (
    <>
      <Stack pb={3} pt={2}>
        <H1 title="Check Order" />
      </Stack>
      <List />
    </>
  );
}

export default CheckOrder;
