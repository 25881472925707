import { List } from "./core";
import { MainHead } from "../../../core/bodyHead";
// import { Add } from "../Add";
import { useState } from "react";
import Add from "./core/Add";

function POSUser() {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <MainHead
        title="POS User"
        iconTitle="Add New User "
        onClickHandler={onModelHandler}
      />

      <List />

      <Add
        open={open}
        activeHandler={activeHandler}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title="Add User"
        subTitle="Create An Account of POS "
      />
    </>
  );
}

export default POSUser;
