import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Typography,
  Button,
  SelectChangeEvent,
} from "@mui/material";

import { IShopOrder } from "../../../../models/IShopOrder";
import OptionList from "../../../../components/OptionList";
import { useEffect, useState } from "react";
import QuantityEnter from "../../../../core/QuantityEnter";
import PaidType from "../../../../components/PaidType";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import { useParams } from "react-router-dom";
import { IState } from "../../../../models/IState";
import CircularLoader from "../../../../core/CircularLoader";
import Tosted from "../../../../core/Tosted";
import { IPayment } from "../../../../models/IPayment";
import { Label } from "../../../../core/Label";
import { NumberDisplay } from "../../../../hooks/numberDisplay";

// ----------------------------------------------------------------------
interface IProps {
  data: IPayment;
  index: number;
}
// ----------------------------------------------------------------------
const TItemRow = ({ data, index }: IProps) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const handleClose = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    handleClose();
  }, [tosted]);

  return (
    <>
      <TableRow hover role="checkbox" key={index} sx={{ cursor: "pointer" }}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar alt={"#"} src={``} />
            <Typography variant="subtitle2" noWrap>
              {data.shopNumber}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center">
          {NumberDisplay(data.totalSellerAmount)}
        </TableCell>
        <TableCell align="center">{data.paymentChannel}</TableCell>
        <TableCell align="center">
          <Label color={(data.paymentDone === false && "error") || "success"}>
            {data.paymentDone ? "Done" : "Pending"}
          </Label>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};
export default TItemRow;
