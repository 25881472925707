import { Box, Stack, Typography } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import Tosted from "../../../../../core/Tosted";
import { IAddDriverDetail } from "../../../../../models/Driver/IAddDriver";
import { DriverServices } from "../../../../../services/Driver";
import useIsDriverAddStore from "../../../../../store/isDriverAdd";

interface IProps {
  handleClose: () => void;
}

type IDriverField =
  | "nameOfDriver"
  | "vehicleNumber"
  | "vehicleName"
  | "loadingCapacity";

const Form = ({ handleClose }: IProps) => {
  const [nameOfDriver, setNameOfDriver] = useState("");
  const [nameOfDriverValid, setNameOfDriverValid] = useState({
    isValid: false,
    message: "Driver Name  is  required",
  });

  const [vehicaleNo, setvehicaleNo] = useState("");
  const [vehicaleNoValid, setVehicaleNoValid] = useState({
    isValid: false,
    message: "Driver Name  is  required",
  });

  const [vehicaleName, setvehicaleName] = useState("");
  const [vehicaleNameValid, setVehicaleNameValid] = useState({
    isValid: false,
    message: "Driver Name  is  required",
  });

  const [capacity, setCapacity] = useState("");
  const [capacityValid, setCapacityValid] = useState({
    isValid: false,
    message: "Capacity  is  required",
  });

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const { setActive } = useIsDriverAddStore((state) => ({
    setActive: state.setActive,
  }));

  const inputHandler = (field: IDriverField, value: string) => {
    if (field == "nameOfDriver") {
      setNameOfDriver(value);
      setNameOfDriverValid({
        isValid: value.length > 0 ? false : true,
        message: "Driver Name  is  required",
      });
    }

    if (field === "vehicleNumber") {
      setvehicaleNo(value);
      setVehicaleNoValid({
        isValid: value.length > 0 ? false : true,
        message: "Vehicale No  is  required",
      });
    }

    if (field === "vehicleName") {
      setvehicaleName(value);
      setVehicaleNameValid({
        isValid: value.length > 0 ? false : true,
        message: "Vehicale Name is  required",
      });
    }

    if (field === "loadingCapacity") {
      setCapacity(value);
      setCapacityValid({
        isValid: value.length > 0 ? false : true,
        message: "Capacity is  required",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isDriverValid = nameOfDriver.trim() !== "";
    if (!isDriverValid) {
      setNameOfDriverValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    const isVehicaleNo = vehicaleNo.trim() !== "";
    if (!isVehicaleNo) {
      setVehicaleNoValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    const isCapacity = capacity.trim() !== "";
    if (!isCapacity) {
      setCapacityValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    const isVehicaleName = vehicaleName.trim() !== "";
    if (!isVehicaleName) {
      setVehicaleNameValid({
        isValid: true,
        message: "This Field Is Required.",
      });
    }

    if (isDriverValid && isCapacity && isVehicaleNo && isVehicaleName) {
      addNewSupplierHandler();
    }
  };

  const addNewSupplierHandler = async () => {
    setState({ ...state, loader: true });

    const driverData: IAddDriverDetail = {
      nameOfDriver: nameOfDriver,
      vehicleDetails: {
        vehicleNumber: vehicaleNo,
        vehicleName: vehicaleName,
        loadingCapacity: capacity,
      },
    };

    try {
      const response = await DriverServices.addDriverDetails(driverData);
      if (response.status === 201 && response.data && response.data.data) {
        setState({
          loader: false,
          tosted: true,
          message: "Sucessfully Added",
          severity: "success",
        });
        setActive(true);
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
        handleClose();
      }, 5000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction={"row"} alignItems={"center"} pb={2} spacing={1.5}>
          <OutlineInput
            value={nameOfDriver}
            type="text"
            label="Driver Name"
            handleInputChange={(e) =>
              inputHandler("nameOfDriver", e.target.value)
            }
            error={
              nameOfDriverValid.isValid ? nameOfDriverValid.message : undefined
            }
            disabled={state.loader}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={"500"} gutterBottom fontSize={"14px"}>
            Vehicale Detail
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
          gap={2}
        >
          <OutlineInput
            value={vehicaleNo}
            type="text"
            label="vehicle No"
            handleInputChange={(e) =>
              inputHandler("vehicleNumber", e.target.value)
            }
            error={
              vehicaleNoValid.isValid ? vehicaleNoValid.message : undefined
            }
            disabled={state.loader}
          />

          <OutlineInput
            value={capacity}
            type="text"
            label="Capacity"
            handleInputChange={(e) =>
              inputHandler("loadingCapacity", e.target.value)
            }
            error={capacityValid.isValid ? capacityValid.message : undefined}
            disabled={state.loader}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          p={0}
          justifyContent={"space-between"}
          gap={2}
        >
          <OutlineInput
            value={vehicaleName}
            type="text"
            label="vehicle Name"
            handleInputChange={(e) =>
              inputHandler("vehicleName", e.target.value)
            }
            error={
              vehicaleNameValid.isValid ? vehicaleNameValid.message : undefined
            }
            disabled={state.loader}
          />
        </Stack>

        <Box textAlign={"center"} pt={1}>
          <Contained
            type="submit"
            variant="text"
            disabled={loader}
            loader={loader}
          >
            Add Now
          </Contained>
        </Box>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};
export default Form;
