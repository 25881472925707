import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Toolbar,
  MenuItem,
  Menu,
  Stack,
  Typography,
} from "@mui/material";

import { useState } from "react";

import Logo from "../../core/Logo";
import Iconify from "../../core/Iconify";
import useUserStore from "../../store/userData";
import useMobile from "../../hooks/useMobile";
import useIsDrawerStore from "../../store/isDrawer";
import useTokenStore from "../../store/token";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import useAlertListStore from "../../store/alertList";
import { Label } from "../../core/Label";

const TopNav = () => {
  const isMobile = useMobile();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { data } = useUserStore((state) => ({
    data: state.data,
  }));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [aElNotification, setAElNotification] = useState<null | HTMLElement>(
    null
  );

  const openNotification = Boolean(aElNotification);

  const openMenu = Boolean(anchorEl);

  const openNotificationHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAElNotification(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAElNotification(null);
  };

  const { open, setOpen } = useIsDrawerStore((state) => ({
    open: state.open,
    setOpen: state.setOpen,
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { token, setToken } = useTokenStore((state) => ({
    token: state.token,
    setToken: state.setToken,
  }));

  const { message, setMessage } = useAlertListStore((state) => ({
    message: state.message,
    setMessage: state.setMessage,
  }));

  const logOutHandler = () => {
    // Specifying the path and domain, adjust accordingly if they were set
    // If you're not sure about the path or domain, you can check the cookies in your browser's developer tools
    cookies.remove("management-token", {
      path: "/",
      domain: window.location.hostname,
    });
    setToken(null);
    navigate("/login");
    // Optional: Redirect to login page or update UI
    // window.location.href = '/login'; // Redirect to login, adjust the path as needed
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(249, 250, 251, 0.8)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            {isMobile ? (
              <Stack direction={"row"} gap={"10px"}>
                <IconButton onClick={handleDrawerToggle}>
                  <Iconify icon="heroicons-outline:menu-alt-1" />
                </IconButton>
                <Logo />
              </Stack>
            ) : (
              <Logo />
            )}

            <Box display={"flex"} gap={"12px"}>
              {!isMobile && data?.shop && (
                <Stack
                  sx={{
                    background: (theme) => theme.palette.primary.light,
                    px: 1,
                    borderRadius: "10px",
                  }}
                  direction={"row"}
                  gap={"5px"}
                  alignItems={"center"}
                >
                  <Iconify icon="akar-icons:location" width={23} />
                  <Typography
                    component={"span"}
                    fontSize={"11px"}
                    fontWeight={"500"}
                  >
                    {data?.shop?.name.toUpperCase()}
                    <Typography fontWeight={"700"} fontSize={"13px"}>
                      {data?.shop?.area}
                    </Typography>
                  </Typography>
                </Stack>
              )}

              <IconButton
                size="small"
                onClick={openNotificationHandler}
                aria-controls={open ? "notification-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Badge
                  badgeContent={message.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      background: "#FF6600",
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#FFf",
                    },
                  }}
                >
                  <Iconify icon="solar:bell-bing-bold" width={23} />
                </Badge>
              </IconButton>
              <IconButton
                size="small"
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  src={"/assets/images/avatars/avatar_25.png"}
                  alt="profile"
                  sizes="small"
                />
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => alert("")}>
          <Iconify icon="eva:person-fill" sx={{ mr: 2 }} />
          Profile
        </MenuItem>

        <Divider />
        <MenuItem onClick={logOutHandler}>
          <Iconify icon="eva:log-out-outline" sx={{ mr: 2 }} />
          Logout
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={aElNotification}
        id="notification-menu"
        open={openNotification}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box width={"350px"}>
          {message.length > 0 ? (
            message.map((data, index) => {
              return (
                <MenuItem>
                  <Stack
                    direction={"row"}
                    px={1}
                    py={0.5}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Typography
                      variant="h5"
                      fontSize={"15px"}
                      component={"span"}
                    >
                      {data.product} <br />
                      <Typography variant="h6" fontSize={"13px"}>
                        {data.quantity}
                      </Typography>
                    </Typography>
                    <Label color={"success"}>{data.message}</Label>
                  </Stack>
                </MenuItem>
              );
            })
          ) : (
            <>No Data Found</>
          )}
        </Box>
      </Menu>
    </>
  );
};
export default TopNav;
