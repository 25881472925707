import { Box, Stack, Typography } from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import SRole from "../SRole";
import { SelectChangeEvent } from "@mui/material/Select";
import Tosted from "../../../../../core/Tosted";
import WType from "../../../../../core/WType";
import { PlaceOrderServices } from "../../../../../services/PlaceOrder";
import useIsPlaceOrderStore from "../../../../../store/isPlaceOrder";
import CType from "../../../../../core/CType";
import AllDriver from "../../../../../core/AllDriver";
import { useNavigate } from "react-router-dom";

type SubmitData = {
  name: string;
  quantity: string;
};

interface IProps {
  handleClose: () => void;
}

const Form = ({ handleClose }: IProps) => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const [nameValid, setNameValid] = useState({
    isValid: false,
    message: "Fill the Name",
  });

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value == null) {
      setNameValid({
        isValid: true,
        message: "Select an role",
      });
    } else {
      setName(event.target.value);
      setNameValid({
        isValid: false,
        message: "select an Role",
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (name === "") {
      setNameValid({
        isValid: true,
        message: "Driver is Required",
      });
    } else {
      navigate(`/branch-manager/receive-order/${name}`);
    }
  };

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <AllDriver value={name} handleChange={handleChange} />
        {nameValid.isValid && (
          <Typography
            fontWeight={"600"}
            pl={2}
            pt={1}
            fontSize={"12px"}
            color={"error"}
          >
            {nameValid.isValid ? nameValid.message : ""}
          </Typography>
        )}

        <br />
        <Box textAlign={"center"} pt={1}>
          <Contained type="submit" variant="text">
            Add Order
          </Contained>
        </Box>
      </form>
    </>
  );
};
export default Form;
