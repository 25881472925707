import {
  Avatar,
  Box,
  Button,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { OutlineInput } from "../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../models/IState";
import Contained from "../../../../core/Button/Contained";
import { IBuyList } from "../../../../models/IOrderList";
import Iconify from "../../../../core/Iconify";
import {
  DistributionDetailEntity,
  IAddNewOrder,
  WeightDetailEntity,
} from "../../../../models/IAddOrder";
import { PurchaseServie } from "../../../../services/PurchaseServices";
import {
  SellerDetailEntity,
  SellerDetailsEntity,
} from "../../../../models/IItemList";
import ShopTable from "./ShopTable";
import AllSupplier from "../../../../core/AllSupplier";
import BranchOrder from "./BranchOrder";
import { IBranch } from "../../../../models/Branch/IBranch";
import { BranchService } from "../../../../services/Branch";
import { IBrackDownItem } from "../../../../models/IBrackdown";
import useIsBuyOrderStore from "../../../../store/useBuyOrder";
import { QRating } from "../../../../core/QRate";
import { ISupplier } from "../../../../models/Suppliers/ISupplier";
import AllContainer from "../../../../core/AllContainer";
import { IContainer } from "../../../../models/IContainer";
import { IPROrder } from "../../../../models/IPROrder";
import { useParams } from "react-router-dom";
import { SupplierServices } from "../../../../services/SupplierServices";
import DirectDistriute from "./DirectDistriute";

interface IProps {
  data: IPROrder;
  state: IState;
  setState: (state: IState) => void;
}

interface IWeight {
  quantity: number;
  PCS: number;

  quantityValid: {
    message: string;
    isValid: boolean;
  };
  pcsValid: {
    message: string;
    isValid: boolean;
  };
}

interface IQty {
  branchId: string;
  quantity: number;
  ratio: number | string;
}

interface IDistribution {
  branchId: string;
  quantity: number;
  ratio: number | string;
  PCS: number;
}

interface IQty {
  branchId: string;
  quantity: number;
  ratio: number | string;
}

const UpdateForm = ({ data, state, setState }: IProps) => {
  const [orderList, setOrderList] = useState<SellerDetailEntity[]>(
    [] as SellerDetailEntity[]
  );

  const { shopNo } = useParams();
  const [shopDetails, setShopDetails] = useState({
    _id: "",
    shopName: "",
    shopNumber: "",
  });

  const getSupplierDetail = async (id: string) => {
    try {
      const response = await SupplierServices.getAllSupplierSmallApi(id);
      if (response.data) {
        setShopDetails(response.data.data);
      }
    } catch (error: any) {
      console.log("error in line no 32");
    }
  };

  useEffect(() => {
    if (shopNo) {
      getSupplierDetail(shopNo);
    }
  }, [shopNo]);

  const [distribution, setDistribution] = useState<IDistribution[]>([]);

  const { setActive } = useIsBuyOrderStore((state) => ({
    setActive: state.setActive,
  }));

  const [shopData, setShopData] = useState<ISupplier | null>(null);
  const [container, setContainer] = useState<IContainer | null>(null);
  const [shopInput, setShopInput] = useState("");

  const [containerInput, setContainerInput] = useState("");

  const containerChangeHandler = (event: any, newValue: IContainer | null) => {
    setContainer(newValue);
    // inputHandler(newValue); // Assuming inputHandler is meant to be called with the new value
  };

  const containerInputChange = (event: any, newInputValue: string) => {
    setContainerInput(newInputValue);
  };

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPCS, setTotalPCS] = useState(0);
  const [remainQty, setRemainOty] = useState(0);
  const [remainPCS, setRemainPCS] = useState(0);

  const [branchlist, setBranchList] = useState<IBranch[]>([] as IBranch[]);
  const [pState, setPState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const getSymbolForArea = (area: IBranch["area"]): string => {
    switch (area) {
      case "MALI COLONY":
        return "M";
      case "SYPHON":
        return "SY";
      case "UNIVERSITY":
        return "UN";
      default:
        return "";
    }
  };

  const [brackdownList, setBrackdownList] = useState<IBrackDownItem[]>(
    [] as IBrackDownItem[]
  );

  const [weightPairs, setWeightPairs] = useState<IWeight[]>([
    {
      quantity: 0,
      PCS: 0,
      quantityValid: {
        message: "",
        isValid: false,
      },
      pcsValid: {
        message: "",
        isValid: false,
      },
    },
  ]);

  const addWeightPair = () => {
    setWeightPairs([
      ...weightPairs,
      {
        quantity: 0,
        PCS: 0,
        quantityValid: {
          message: "",
          isValid: false,
        },
        pcsValid: {
          message: "",
          isValid: false,
        },
      },
    ]);
  };

  const removeWeightPair = (index: number) => {
    const newPairs = [...weightPairs];
    newPairs.splice(index, 1);
    setWeightPairs(newPairs);
  };

  const inputHandler = (index: number, field: keyof IWeight, value: string) => {
    const newPairs = [...weightPairs];
    const regex = /^\d*\.?\d?$/;
    // Convert string to number for numerical fields
    const numericalValue =
      field === "quantity" || field === "PCS" ? Number(value) : value;
    newPairs[index] = {
      ...newPairs[index],
      [field]: numericalValue,
    };

    if (regex.test(value)) {
      const numericalValue =
        field === "quantity" || field === "PCS" ? Number(value) : value;
      newPairs[index] = {
        ...newPairs[index],
        [field]: numericalValue,
      };
    } else {
      // If the input value has more than one digit after the decimal, format it
      const formattedValue = value.match(/^\d+\.\d{2,}/);
      if (formattedValue) {
        const numericalValue =
          field === "quantity" || field === "PCS"
            ? Number(parseFloat(formattedValue[0]).toFixed(2))
            : parseFloat(formattedValue[0]).toFixed(2);
        newPairs[index] = {
          ...newPairs[index],
          [field]: numericalValue,
        };
      }
    }

    setWeightPairs(newPairs);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let allPairsValid = true;

    const updatedPairs = weightPairs.map((pair) => {
      // Assuming quantity should be greater than 0
      const quantityValid = pair.quantity > 0;
      // Assuming PCS should be greater than 0
      const pcsValid = pair.PCS > 0;

      // Validate that at least one of quantity or PCS is valid
      const atLeastOneValid = quantityValid || pcsValid;

      if (!atLeastOneValid) {
        allPairsValid = false; // Mark as invalid if neither field is valid
      }

      return {
        ...pair,
        quantityValid: {
          ...pair.quantityValid,
          isValid: quantityValid,
          message: quantityValid ? "" : "Invalid quantity",
        },
        pcsValid: {
          ...pair.pcsValid,
          isValid: pcsValid,
          message: pcsValid ? "" : "Invalid PCS",
        },
      };
    });

    if (!allPairsValid) {
      return; // Prevent form submission if any pair is invalid or if the supplier is invalid
    }

    // If everything is valid, proceed with updating the order
    updateOrderHandler();
  };

  const getProductSellerDetail = async (id: string) => {
    try {
      setPState({ ...pState, loader: true });
      const response = await PurchaseServie.viewSellerDetails(id);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data.sellerDetails
      ) {
        setOrderList(response.data.data.sellerDetails);
        setPState({ ...pState, loader: false });
      } else {
        setPState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setPState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const total = weightPairs.reduce((acc, item) => {
      // Check if item.quantity is not an empty string and is a valid number string
      // If not, default to 0
      const quantity =
        item.quantity && !isNaN(item.quantity) ? item.quantity.toString() : 0;
      return acc + +quantity;
    }, 0);

    const totalPCS = weightPairs.reduce((acc, item) => {
      const quantity = item.PCS && !isNaN(item.PCS) ? item.PCS.toString() : 0;
      return acc + +quantity;
    }, 0);
    setTotalQuantity(total);
    setTotalPCS(totalPCS);
  }, [weightPairs]);

  useEffect(() => {
    getProductSellerDetail(data.product._id);
    getBranchListServices();
    getBreakdownServices();
  }, []);

  const getBranchListServices = async () => {
    try {
      const response = await BranchService.getAllBranchApi();
      if (response.status === 200 && response.data && response.data.data) {
        const updatedBranches = response.data.data.map((shop: IBranch) => ({
          ...shop,
          symbol: getSymbolForArea(shop.area),
        }));

        // Update the state with the modified branches array
        setBranchList(updatedBranches);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const getBreakdownServices = async () => {
    try {
      const response = await PurchaseServie.detailedBreakdownApi(
        data.product._id
      );
      if (response.status === 200 && response.data && response.data.data) {
        setBrackdownList(response.data.data);
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const updateOrderHandler = async () => {
    const weightDetail: WeightDetailEntity[] = weightPairs.map((item) => ({
      quantityPurchased: +item.quantity,
      PCSPurchased: +item.PCS,
    }));

    const convertedArray: DistributionDetailEntity[] = distribution.map(
      (item) => ({
        branchId: item.branchId,
        distributionWeightDetail: {
          quantity: item.quantity,
          PCS: item.PCS,
        },
      })
    );
    const order: IAddNewOrder = {
      productId: data.product._id,
      supplierShopNumberId: shopDetails._id,
      containerId: container?._id,
      weightDetail: weightDetail,
      distributionDetail: convertedArray,
    };

    setState({ ...state, loader: true });
    try {
      const response = await PurchaseServie.addOrder(order);
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.sellerDetails.length > 0
      ) {
        setState({
          loader: false,
          tosted: true,
          severity: "success",
          message: response.data.message,
        });

        setTimeout(() => {
          setActive(true);
        }, 3000);
        getProductSellerDetail(data.product._id);
      } else {
        setState({
          loader: false,
          tosted: true,
          severity: "error",
          message: "Something went wrong",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const { loader, tosted, message, severity } = pState;

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack direction={"row"} gap={"20px"} width={"100%"}>
          <div style={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" gap={"10px"} spacing={1}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <Typography variant="subtitle2" noWrap>
                  {data.product.name} <br />
                  {data.totalRequiredQuantity} Kg <br />
                </Typography>
              </Box>
              <AllContainer
                isFullWidth={false}
                isValid={undefined}
                isLarge={true}
                value={container}
                inputValue={containerInput}
                handleChange={containerChangeHandler}
                handleInputChange={containerInputChange}
              />
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              pb={2}
              spacing={1.5}
            ></Stack>
            <Stack direction={"row"} alignItems={"center"} pb={2} spacing={1.5}>
              <Typography variant="h5">{shopDetails.shopName}</Typography>

              <IconButton color="primary" size="large" onClick={addWeightPair}>
                <Iconify icon="fluent:add-12-filled" />
              </IconButton>
            </Stack>

            {weightPairs.map((pair, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={1.5}
                pb={2}
              >
                <OutlineInput
                  value={pair.PCS}
                  type="number"
                  label="PCS"
                  handleInputChange={(e) =>
                    inputHandler(index, "PCS", e.target.value)
                  }
                  error={
                    pair.pcsValid.isValid ? pair.pcsValid.message : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />
                <OutlineInput
                  value={pair.quantity}
                  type="number"
                  label="kg"
                  handleInputChange={(e) =>
                    inputHandler(index, "quantity", e.target.value)
                  }
                  error={
                    pair.quantityValid.isValid
                      ? pair.quantityValid.message
                      : undefined
                  }
                  disabled={false} // Or use your logic to disable input
                />

                <IconButton
                  onClick={() => removeWeightPair(index)}
                  size="large"
                  color="error"
                >
                  <Iconify icon="fluent:delete-12-regular" />
                </IconButton>
              </Stack>
            ))}
            <Box textAlign={"center"} pt={1}>
              <Contained
                type="submit"
                variant="text"
                disabled={remainQty != 0 || remainPCS != 0 || loader}
                loader={loader}
              >
                Update Now
              </Contained>
            </Box>
          </div>
          <div style={{ width: "100%" }}>
            <BranchOrder
              branchList={branchlist}
              BrackdownList={brackdownList[0]}
              distribution={distribution}
              // onQuantitiesUpdate={handleQuantitiesUpdate}
              totalQuantity={totalQuantity}
              setDistribution={setDistribution}
              remainQty={remainQty}
              setRemainOty={setRemainOty}
              totalPCS={totalPCS}
              remainPCS={remainPCS}
              setRemainPCS={setRemainPCS}
            />
            <br />
            {orderList && orderList.length > 0 && (
              <ShopTable
                data={orderList}
                state={state}
                setState={setState}
                productId={data.product._id}
              />
            )}
          </div>
        </Stack>
      </form>
    </>
  );
};
export default UpdateForm;
