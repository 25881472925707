import { Container, Typography, Paper, Box } from "@mui/material";
import ModelOpen from "../../../../core/Model copy";
import { IModel } from "../../../../models/IModel";
import Form from "./Form";
import { IState } from "../../../../models/IState";
import { useState } from "react";
import Tosted from "../../../../core/Tosted";

const Add = ({
  open,
  handleClose,
  activeHandler,
  onModelHandler,
  title,
  subTitle,
}: IModel) => {
  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const { loader, tosted, message, severity } = state;

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
      }, 5000);
    }
  };

  return (
    <>
      <ModelOpen
        open={open}
        handleClose={handleClose}
        onModelHandler={onModelHandler}
        title={title}
        subTitle={subTitle}
        width="600px"
      >
        <Container
          maxWidth="lg"
          component={Paper}
          elevation={2}
          sx={{ width: "100%" }}
        >
          <Typography variant="h4" textAlign={"start"} gutterBottom>
            {title}
          </Typography>
          <Typography textAlign={"start"} gutterBottom>
            {subTitle}
          </Typography>
          <Box py={2}>
            <Form
              handleClose={handleClose}
              states={state}
              setStates={setState}
            />
          </Box>
        </Container>
      </ModelOpen>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};

export default Add;
