import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddMuliplePayment } from "../models/IAddMuliplePayment";
import { IAddNewOrder, IAddPCSOrder, IUpdateOrder } from "../models/IAddOrder";
import { IUmoType } from "../models/IUmoType";

export class PurchaseServie {
  public static addOrder(data: IAddNewOrder | IAddPCSOrder) {
    return InstanceAxios().post("/order/addOrUpdateOrder", data);
  }

  public static updateOrder(data: IUpdateOrder) {
    return InstanceAxios().post("/order/updateOrder", data);
  }

  public static viewSellerDetails(productId: string) {
    return InstanceAxios().get("/order/getSellerDetail", {
      params: {
        productId: productId,
      },
    });
  }

  public static getShopList(
    date: string,
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/order/getShopnumbers", {
      params: {
        date: date,
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }
  public static getDetailsByShopNumber(
    shopNumber: string,
    date: string,
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/order/getDetailsByShopNumber", {
      params: {
        supplierId: shopNumber,
        date: date,
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }

  public static createPaymentRecord(
    orderId: string,
    shopNumber: string,
    paymentChannel: string
  ) {
    const data = {
      orderId: orderId,
      shopNumber: shopNumber,
      paymentChannel: paymentChannel,
    };
    return InstanceAxios().post("/payment/createPaymentRecord", data);
  }

  public static getPaymentsByDate(
    date: string,
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/payment/getPaymentsByDate", {
      params: {
        paymentDate: date,
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }
  // public static async updateRate(
  //   rate: number,
  //   productId: string,
  //   shopNumber: string,
  //   percentage: number | string,
  //   processDate: string,
  //   wholesaleSellingPrice: number,
  //   sellingPercentage: number,
  //   sRate: number,
  //   rateOn: IUmoType,
  //   sellOn: IUmoType
  // ) {
  //   // Validate inputs before proceeding
  //   if (!productId || !shopNumber || !processDate || rate <= 0 || sRate <= 0) {
  //     console.error("Invalid data provided", {
  //       productId,
  //       shopNumber,
  //       processDate,
  //       rate,
  //       sRate,
  //     });
  //     return Promise.reject("Invalid data");
  //   }

  //   let data = {
  //     supplierId: shopNumber,
  //     productId: productId,
  //     rate: rate,
  //     percentage: +percentage,
  //     processDate: processDate,
  //     wholesaleSellingPrice: wholesaleSellingPrice,
  //     sellingPercentage: sellingPercentage,
  //     sellingPrice: sRate,
  //     rateOn: rateOn,
  //     sellOn: sellOn,
  //   };

  //   // Log the prepared data before sending
  //   console.log("Data being sent for updateRate:", data);

  //   // Proceed with the API request
  //   return InstanceAxios()
  //     .post("/rate/addRateToProductByShopNumber", data)
  //     .then((response) => {
  //       console.log("API response:", response.data);
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.error("Error in updateRate API call:", error);
  //       throw error;
  //     });
  // }
  public static updateRate(
    rate: number,
    productId: string,
    shopNumber: string,
    percentage: number | string,
    processDate: string,
    wholesaleSellingPrice: number,
    sellingPercentage: number,
    sRate: number,
    rateOn: IUmoType,
    sellOn: IUmoType
  ) {
    let data = {
      supplierId: shopNumber,
      productId: productId,
      rate: rate,
      percentage: +percentage,
      processDate: processDate,
      wholesaleSellingPrice: wholesaleSellingPrice, // Should match the wholesale price
      sellingPercentage: sellingPercentage, // Should match the percentage
      sellingPrice: sRate, // Should match the selling price
      rateOn: rateOn,
      sellOn: sellOn,
    };

    console.log("Data being sent:", data); // Final debug log to verify the payload
    return InstanceAxios().post("/rate/addRateToProductByShopNumber", data);
  }

  public static detailedBreakdownApi(productId: string) {
    return InstanceAxios().get("/order/requirement/detailedBreakdown", {
      params: {
        productId: productId,
      },
    });
  }

  public static addMultipleRatesToProductBySupplier(data: IAddMuliplePayment) {
    return InstanceAxios().post(
      "/rate/addMultipleRatesToProductBySupplier",
      data
    );
  }

  public static totalPurchaseApi(date: string) {
    return InstanceAxios().get("/reports/totalpurchase", {
      params: {
        processDate: date,
      },
    });
  }
}
