import { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  TableBody,
  TablePagination,
  Typography,
} from "@mui/material";
import MTable from "../../../../core/MTable";
import { IState } from "../../../../models/IState";
import Tosted from "../../../../core/Tosted";
import useIsPlaceOrderStore from "../../../../store/isPlaceOrder";
import { TLoader } from "../../../../core/Loader";
import TRow from "./TRow";
import { getTodayDate } from "../../../../hooks/getTodayDate";
import { AllList, mobileList, orderHistory } from "../data";
import AutoInput from "./AutoInput";
import { IProduct } from "../../../../models/IProduct";
import THeaderView from "../../../../core/THeaderView";
import TNRow from "./INRow";
import { IGetReceivedOrder } from "../../../../models/IGetReceivedOrder";
import { BranchService } from "../../../../services/Branch";

interface IProductData {
  id: string;
  name: string;
}

const List = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [active, setActive] = useState(false);

  const [searchProduct, setSearchProduct] = useState<IProduct | null>(null);

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [states, setStates] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [state1, setState1] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const [date, setDate] = useState<string>(getTodayDate());

  const [productData, setProductData] = useState<IProductData[]>(
    [] as IProductData[]
  );

  const [list, setList] = useState<IGetReceivedOrder[] | undefined>(
    [] as IGetReceivedOrder[]
  );

  const getOrderList = async () => {
    try {
      setState({ ...state, loader: true });
      const response = await BranchService.getAllReceivedProductsWithDetails(
        new Date().toISOString()
      );
      if (response.status === 200 && response.data && response.data.data) {
        setList(response.data.data);
        setState({ ...state, loader: false });
        setActive(false);
      } else {
        setList([]);
        setState({ ...state, loader: false });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      setState({
        loader: false,
        tosted: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (active) {
      getOrderList();
    }
  }, [active]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1); // Add 1 to newPage to make it 1-based index
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getOrderList();
  }, [rowsPerPage, page, category, date]);

  const getRowsPerPageOptions = () => {
    if (+totalCount <= 10) {
      return []; // returns an empty array if totalCount is 10 or less
    }
    return [10, 25, 50];
  };

  const productInputHandler = (data: IProduct | null) => {
    if (data?._id && data.name) {
      setSearchProduct(data);
      setProductData([
        {
          id: data._id,
          name: data.name,
        },
      ]);
    }
  };

  const { loader, message, severity, tosted } = state;
  const handleClose = () => {
    if (state.tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  const handleClose1 = () => {
    if (state1.tosted) {
      setTimeout(() => {
        setState1({
          ...state,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleClose();
  }, [tosted]);

  const checkUpdateHandler = () => {
    setProductData([]);
  };

  useEffect(() => {
    handleClose1();
  }, [state1.tosted]);

  const handleCloses = () => {
    if (states.tosted) {
      setTimeout(() => {
        setStates({
          ...states,
          tosted: false,
          message: "",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    handleCloses();
  }, [states.tosted]);

  return (
    <>
      <div>
        <Paper sx={{ width: "100%", py: 5 }} elevation={3}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <AutoInput inputHandler={productInputHandler} />
          </Stack>

          {productData.length > 0 ? (
            <Typography
              variant="h4"
              fontWeight={"600"}
              fontSize={"18px"}
              color={"primary"}
              gutterBottom
              pl={1.5}
              pb={1}
            >
              New Item :
            </Typography>
          ) : (
            <Typography
              variant="h4"
              fontWeight={"600"}
              fontSize={"18px"}
              color={"primary"}
              textAlign={"center"}
              gutterBottom
              pt={5}
            >
              Select An Product on DropDown
            </Typography>
          )}

          <MTable>
            {productData.length > 0 && (
              <THeaderView MobileList={mobileList} AllList={AllList} />
            )}

            <TableBody>
              {productData.length > 0 &&
                productData.map((data, index) => {
                  return (
                    <TNRow
                      data={data}
                      index={index}
                      checkUpdateHandler={checkUpdateHandler}
                      states={states}
                      setStates={setStates}
                      setActive={setActive}
                    />
                  );
                })}
            </TableBody>
          </MTable>

          <br />

          <Typography
            variant="h4"
            fontWeight={"600"}
            fontSize={"18px"}
            color={"primary"}
            gutterBottom
            pl={1.5}
            pb={1}
          >
            Added Items :
          </Typography>

          <MTable>
            <THeaderView MobileList={mobileList} AllList={AllList} />
            <TableBody>
              {list?.map((data) => {
                // Removed index from here
                return <TRow data={data} />; // Removed the index prop as it's no longer needed
              })}
            </TableBody>
          </MTable>

          {/* {loader && <TLoader />} */}

          <TablePagination
            component="div"
            count={+totalCount}
            page={+page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={+rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions()}
          />
        </Paper>
      </div>

      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={handleClose}
      />

      <Tosted
        label={state1.message}
        open={state1.tosted}
        severity={state1.severity}
        handleClose={handleClose1}
      />

      <Tosted
        label={states.message}
        open={states.tosted}
        severity={states.severity}
        handleClose={handleClose}
      />
    </>
  );
};

export default List;
