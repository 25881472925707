import { useEffect, useState } from "react";
import { List } from "./core";
import H1 from "../../../core/H1";
import { Stack } from "@mui/material";
import { getTodayDate } from "../../../hooks/getTodayDate";
import SelectDate from "../../../core/SelectDate";

function View() {
  const [date, setDate] = useState<string>(getTodayDate());
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const onModelHandler = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);

  const activeHandler = (data: boolean) => {
    setTimeout(() => {
      setActive(data);
    }, 1000);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <H1 title="All Order List" />
      </Stack>

      <SelectDate
        date={date}
        title="Select Date"
        subTitle="Select Date is required"
        onDateChange={onDateChange}
        open={open}
        onModelHandler={onModelHandler}
        activeHandler={activeHandler}
        handleClose={handleClose}
      />
      <List
        date={date}
        onDateChange={onDateChange}
        open={open}
        onModelHandler={onModelHandler}
        activeHandler={activeHandler}
        DateClose={handleClose}
      />
    </>
  );
}

export default View;
