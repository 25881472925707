import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { OutlineInput } from "../../../../../core/InputField";
import { useEffect, useState } from "react";
import { IState } from "../../../../../models/IState";
import Contained from "../../../../../core/Button/Contained";
import Tosted from "../../../../../core/Tosted";
import { IAddDriverDetail } from "../../../../../models/Driver/IAddDriver";
import { DriverServices } from "../../../../../services/Driver";
import useIsDriverAddStore from "../../../../../store/isDriverAdd";
import { IMajdur } from "../../../../../models/IMajdur";
import { getTodayDate } from "../../../../../hooks/getTodayDate";
import { IPType } from "../../../../../models/IPType";
import { MajDuriService } from "../../../../../services/MajDuriServices";
import { IMajDuriAdd } from "../../../../../models/IMajDuriAdd";

interface IProps {
  handleClose: () => void;
  data: IMajdur;
}

const Form = ({ handleClose, data }: IProps) => {
  const [amount, setAmount] = useState(data.totalMajduriAmount);
  const [amountValid, setAmountValid] = useState({
    isValid: false,
    message: "",
  });
  const [pType, setPType] = useState<IPType>("NEFT");
  const list: IPType[] = ["NEFT", "Cash"];
  const [valid, setValid] = useState({
    isValid: false,
    message: "",
  });

  const [state, setState] = useState<IState>({
    loader: false,
    tosted: false,
    severity: undefined,
    message: "",
  });

  const { setActive } = useIsDriverAddStore((state) => ({
    setActive: state.setActive,
  }));

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === "NEFT") {
      setPType(event.target.value);
    } else if (event.target.value === "Cash") {
      setPType(event.target.value);
    }
  };

  const InputHandler = (value: string) => {
    if (+value > -1) {
      setAmount((prevAmount) => (prevAmount = +value));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const [date, setDate] = useState<string>(getTodayDate());
  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const paymentValidHandler = () => {
    let isAmountValid = amount > 0;
    if (isAmountValid) {
      setAmountValid({
        isValid: true,
        message: "Amount is Valid",
      });
    } else {
      createPaymentHandler();
    }
  };

  const createPaymentHandler = async () => {
    const paymentAmount: IMajDuriAdd = {
      supplierId: data.supplierId,
      processDate: date,
      paymentAmount: amount,
      paymentChannel: pType,
    };

    try {
      const response = await MajDuriService.addMajdurPayment(paymentAmount);
      if (response.status === 200 && response.data && response.data.message) {
        setState({
          loader: false,
          tosted: true,
          message: response.data.message,
          severity: "success",
        });
      } else {
        setState({
          loader: false,
          tosted: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    } catch (error: any) {
      setState({
        loader: false,
        tosted: true,
        message: error.response.data.message,
        severity: "error",
      });
    }
  };

  const closeAlert = () => {
    if (tosted) {
      setTimeout(() => {
        setState({
          ...state,
          tosted: false,
        });
        handleClose();
      }, 5000);
    }
  };
  const { loader, tosted, message, severity } = state;
  useEffect(() => {
    closeAlert();
  }, [tosted]);

  return (
    <>
      <form className="column" onSubmit={handleSubmit}>
        <Stack
          direction={"row"}
          gap={"10px"}
          pb={2}
          justifyContent={"space-between"}
          spacing={1.5}
        >
          <div>
            <Typography fontWeight={"500"}>
              Shop No : {data.supplierInfo.shopNumber}
            </Typography>{" "}
            <Typography fontWeight={"700"}>
              Shop Name : {data.supplierInfo.shopName}
            </Typography>
          </div>
          <div>
            <InputBase
              type="date"
              value={date}
              onChange={onDateChange}
              sx={{
                "& .MuiInputBase-input": {
                  position: "relative",
                  backgroundColor: "background.paper",
                  border: (theme) => `1px solid #ccc`,
                  px: 1,
                  py: 1,
                  borderRadius: 3,
                },
              }}
            />
          </div>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} py={2} spacing={1.5}>
          <OutlineInput
            value={amount}
            type="number"
            label="Amount"
            handleInputChange={(e: any) => InputHandler(e.target.value)}
            error={amountValid.isValid ? amountValid.message : undefined}
            disabled={state.loader}
          />
          <FormControl fullWidth>
            <Select value={pType} onChange={handleChange}>
              {list.map((data, index) => {
                return (
                  <MenuItem value={data} key={index}>
                    {data}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize={"12px"}
                p={0}
                m={0}
                color="error"
              >
                {valid.message}
              </Typography>
            </FormHelperText>
          </FormControl>
        </Stack>

        <Button variant="contained" onClick={createPaymentHandler} fullWidth>
          Payment Now
        </Button>
      </form>
      <Tosted
        label={message}
        open={tosted}
        severity={severity}
        handleClose={closeAlert}
      />
    </>
  );
};
export default Form;
