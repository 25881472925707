import { sample } from "lodash";
import { ITHead } from "../../models/Common/ITHead";

export const THeading: ITHead[] = [
  {
    id: 2,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 1,
    name: "No. of items",
    isMove: false,
    align: "center",
  },
  {
    id: 3,
    name: "",
    isMove: false,
    align: "right",
  },
];

export const THeadingItem: ITHead[] = [
  {
    id: 2,
    name: "Item Name",
    isMove: false,
    align: "left",
  },

  {
    id: 3,
    name: "PCS (Purchase / Purchased)",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Qty (Purchase / Purchased)",
    isMove: false,
    align: "center",
  },

  {
    id: 5,
    name: "",
    isMove: false,
    align: "right",
  },
];

export const isMobileHeading: ITHead[] = [
  {
    id: 1,
    name: "Name",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Total Order",
    isMove: false,
    align: "left",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const procurementList = [...Array(1)].map((__, index) => ({
  item: sample(["Mango", "Apple", "Banana", "Orange", "Grapefruit", "Kiwi"]),
  shopNo: 2,
  nug: 2,
  weight: 0,
}));

export const itemList = [...Array(2)].map((__, index) => ({
  item: ["Mango", "Apple"],
  shopNo: 2,
  weight: sample(["5", "7"]),
}));

export const shoplist = [
  {
    shopname: 1,
    quantity: "10kg",
  },
  {
    shopname: 5,
    quantity: "30kg",
  },
  {
    shopname: 16,
    quantity: "30kg",
  },
];
