import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Shop Name",
    isMove: false,
    align: "left",
  },
  {
    id: 3,
    name: "PCS",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Amount",
    isMove: false,
    align: "center",
  },
  {
    id: 5,
    name: "OutStanding",
    isMove: false,
    align: "center",
  },
  {
    id: 6,
    name: "Advance",
    isMove: false,
    align: "center",
  },
  {
    id: 7,
    name: "Net Amount",
    isMove: false,
    align: "center",
  },

  {
    id: 8,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Shop No",
    isMove: false,
    align: "left",
  },
  {
    id: 2,
    name: "Shop Name",
    isMove: false,
    align: "left",
  },
  {
    id: 3,
    name: "PCS",
    isMove: false,
    align: "center",
  },
  {
    id: 4,
    name: "Amount",
    isMove: false,
    align: "center",
  },
  {
    id: 5,
    name: "OutStanding",
    isMove: false,
    align: "center",
  },
  {
    id: 6,
    name: "Advance",
    isMove: false,
    align: "center",
  },
  {
    id: 7,
    name: "Net Amount",
    isMove: false,
    align: "center",
  },

  {
    id: 8,
    name: "",
    isMove: true,
    align: "right",
  },
];
