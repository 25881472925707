import { TableHead, TableRow, TableCell } from "@mui/material";
import useMobile from "../hooks/useMobile";
import { ITHead } from "../models/Common/ITHead";

interface IProps {
  MobileList: ITHead[];
  AllList: ITHead[];
}

// ----------------------------------------------------------------------
const THeaderView = ({ MobileList, AllList }: IProps) => {
  const isMobile = useMobile();
  return (
    <TableHead>
      <TableRow>
        {isMobile
          ? MobileList.map((data, index) => (
              <TableCell
                size={"large" as any}
                key={index}
                align={data.align}
                padding={"normal"}
                colSpan={data.colspan}
              >
                {data.name}
              </TableCell>
            ))
          : AllList.map((data, index) => (
              <TableCell
                size={"large" as any}
                key={index}
                align={data.align}
                padding={"normal"}
                colSpan={data.colspan}
              >
                {data.name}
              </TableCell>
            ))}
      </TableRow>
    </TableHead>
  );
};
export default THeaderView;
