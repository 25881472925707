import { InstanceAxios } from "../hooks/axiosConfig";
import { IAddDriverDetail } from "../models/Driver/IAddDriver";

export class DriverServices {
  public static getDriverDetails(
    page?: number,
    pageSize?: number,
    search?: string
  ) {
    return InstanceAxios().get("/driverDetails/getDriverDetails", {
      params: {
        page: page,
        pagesize: pageSize,
        search: search,
      },
    });
  }

  public static addDriverDetails(data: IAddDriverDetail) {
    return InstanceAxios().post("/driverDetails/addDriverDetails", data);
  }
}
