import { InstanceAxios } from "../../hooks/axiosConfig";
import { IAddPayment } from "../../models/IAddPayment";

export class LedgerService {
  public static getLedgerReport() {
    return InstanceAxios().get("/ledgers/getSupplierLedgerByShopNumber");
  }
  public static getLedgerReportByDate(
    supplierId: string,
    showNonZeroBalancesOnly?: boolean
  ) {
    return InstanceAxios().get("/ledgers/getSupplierLedgerDatewise", {
      params: {
        supplierId: supplierId,
        showNonZeroBalancesOnly: showNonZeroBalancesOnly,
      },
    });
  }

  public static getSupplierLedger(supplierId?: string) {
    return InstanceAxios().get("/ledgers/getSupplierLedger", {
      params: {
        supplierId: supplierId,
      },
    });
  }

  public static createpaymentApi(data: IAddPayment) {
    return InstanceAxios().post("/ledgers/addSupplierLedgerPaymentEntry", data);
  }

  public static getFetchLedgerReport(
    page?: number,
    pageSize?: number,
    category?: string,
    search?: string,
    date?: string
  ) {
    return InstanceAxios().get("/ledgers/fetchSupplierLedger");
  }
}
