import { ITHead } from "../../../models/Common/ITHead";

export const AllList: ITHead[] = [
  {
    id: 1,
    name: "Driver Name",
    isMove: false,
    align: "left",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];

export const MobileList: ITHead[] = [
  {
    id: 1,
    name: "Driver Name",
    isMove: false,
    align: "left",
  },

  {
    id: 5,
    name: "",
    isMove: true,
    align: "right",
  },
];
